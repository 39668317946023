import {
  Box,
  Button,
  DropdownItem,
  Icons,
  Modal,
  SearchBar,
  SettingButton,
  Table,
  ToolTip,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import TitlePath from "../../../component/TitlePath";
import useManagerStore from "../../../redux/dispatcher/useManagerStore";
import { useEffect, useLayoutEffect, useState } from "react";
import { ManagerBean } from "../../../types/Manage";
import "./index.scss";
import useCustomerStore from "../../../redux/dispatcher/useCustomerStore";
import { CustomerInfoBean } from "../../../types/Info";
import RegistUpdateUserModal from "./RegistUpdateUserModal";
import CommonModal from "../../../component/CommonModal";
import { nanoid } from "@reduxjs/toolkit";
import NoInfo from "../../../component/NoInfo";
import { useTranslation } from "react-i18next";
import { getUserInfoFromLocalStorage } from "../../../shared/utils/auth";

export const accessMenuOptions = [
  "",
  "", // index 1 (menu)
  "menu.dashboard", // index 2 (대시보드)
  "menu.trainingManagement", // index 3 (훈련 관리)
  "menu.quizTitle", // index 4 (학습 관리 - 퀴즈)
  "menu.reportManagement", // index 5 (신고 센터 관리)
  "menu.userManagement", // index 6 (사용자 관리)
  "menu.managerSetting", // index 7 (관리자 설정)
  "menu.systemManagement", // index 8 (시스템 관리)
  "menu.training", // index 9 (훈련)
  "menu.template", // index 10 (훈련 시나리오)
  "menu.templateContentsSetting", // index 11 (훈련 콘텐츠)
  "menu.templateLink", // index 12 (훈련용 링크)
  "menu.templateImage", // index 13 (열람 확인용 이미지)
  "menu.templateAttachFile", // index 14 (훈련용 첨부파일)
  "menu.trainingSendAndResult", // index 15 (메일 발송 내역 및 결과)
  "menu.trainingGraph", // index 16 (훈련 통계)
  "menu.quizCourse", // index 17 (퀴즈 코스)
  "menu.quizContent", // index 18 (퀴즈 문제)
  "menu.quizTemplate", // index 19 (퀴즈 메일 템플릿)
  "menu.quizResult", // index 20 (메일 발송 내역 및 결과)
  "menu.quizStat", // index 21 (학습 통계)
  "menu.reportOperation", // index 22 (신고 메일)
  "menu.reportSetting", // index 23 (신고 센터 설정)
  "menu.reportGraph", // index 24 (신고 통계)
  "menu.rightManagement", // index 25 (권한 관리)
  "menu.customerManagement", // index 26 (고객사 관리)
  "menu.logManagement", // index 27 (로그 관리)
];

export const menuData: [string, number, number][] = [
  ["", 0, 0],
  ["menu", 0, 0],
  ["menu.dashboard", 0, 2],
  ["menu.trainingManagement", 0, 2],
  ["menu.quizTitle", 0, 2],
  ["menu.reportManagement", 0, 2],
  ["menu.userManagement", 0, 2],
  ["menu.managerSetting", 0, 2],
  ["menu.systemManagement", 0, 2],
  ["menu.training", 3, 2],
  ["menu.template", 3, 2],
  ["menu.templateContentsSetting", 3, 2],
  ["menu.templateLink", 11, 2],
  ["menu.templateImage", 11, 2],
  ["menu.templateAttachFile", 11, 2],
  ["menu.trainingSendAndResult", 3, 2],
  ["menu.trainingGraph", 3, 2],
  ["menu.quizCourse", 4, 2],
  ["menu.quizContent", 4, 2],
  ["menu.quizTemplate", 4, 2],
  ["menu.quizResult", 4, 2],
  ["menu.quizStat", 4, 2],
  ["menu.reportOperation", 5, 2],
  ["menu.reportSetting", 5, 2],
  ["menu.reportGraph", 5, 2],
  ["menu.customerManagement", 0, 2],
  ["menu.logManagement", 0, 2],
];

export const parentMenus = menuData
  .map((menu, index) => ({ label: menu[0], index, parentIndex: menu[1] }))
  .filter(
    (menu) =>
      (menu.parentIndex === 0 && menu.index === 3) ||
      menu.index === 4 ||
      menu.index === 5 ||
      menu.index === 6 ||
      menu.index === 7
  ); // parentIndex가 0이며 index 2 이상인 항목만

export const groupedMenus: Record<number, { label: string; index: number }[]> =
  {};
menuData.forEach((menu, index) => {
  const parentIndex = menu[1]; // 상위 메뉴 인덱스
  if (parentIndex !== index) {
    if (!groupedMenus[parentIndex]) {
      groupedMenus[parentIndex] = [];
    }
    groupedMenus[parentIndex].push({ label: menu[0], index });
  }
});

const ManageAccess: React.FC = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { managerState, getManagerList, deleteManager } = useManagerStore();
  const { customerState, getAccessCompanyList } = useCustomerStore();
  const [searchManagerName, changeManagerName] = useState("");
  const [managerList, changeManagerList] = useState<ManagerBean[]>([]);
  const [companyList, chageCompanyList] = useState<CustomerInfoBean[]>([]);
  const [registUpdateUserModalInfo, changeRegistUpdateUserModalInfo] = useState(
    { isOpen: false, updateUserCode: "", modalType: "CLOSE" }
  );
  const [buttonClick, setButtonClick] = useState(0);
  const [deleteManagerInfo, changeDeleteManagerInfo] = useState({
    isOpen: false,
    managerCode: "",
  });
  useEffect(() => {
    getAccessCompanyList();
    getManagerList();
  }, []);
  useEffect(() => {
    if (searchManagerName === "") {
      changeManagerList(
        managerState.managerList?.filter(
          (item: ManagerBean) => item.managerCode !== "admin"
        ) ?? []
      );
    } else {
      changeManagerList(() =>
        managerState.managerList.filter(
          (item: any) =>
            item.managerName.includes(searchManagerName) &&
            item.managerCode !== "admin"
        )
      );
    }
  }, [searchManagerName]);
  useEffect(() => {
    changeManagerList(
      managerState.managerList?.filter(
        (item: ManagerBean) => item.managerCode !== "admin"
      ) ?? []
    );
  }, [managerState.managerList]);

  useEffect(() => {
    chageCompanyList(customerState.companyList);
  }, [customerState.companyList]);

  const renderMenuAccessList = (menuList: string) => {
    let menuInts = menuList
      .substring(1, menuList.length - 1)
      .split(",")
      .map((item1) => parseInt(item1))
      .sort((a, b) => a - b);
    return menuInts.map((item) => t(accessMenuOptions[item])).join("/");
  };
  const handleDeleteManager = async () => {
    let resp: any = await deleteManager([deleteManagerInfo.managerCode]);
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("manager.msg.managerDeleteSuccessMsg"),
        "success"
      );
      getManagerList();
    } else {
      toast.toastMsg(nanoid(), t("manager.msg.adminDeleteFailMsg"), "error");
    }
    changeDeleteManagerInfo({ isOpen: false, managerCode: "" });
  };

  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useLayoutEffect(() => {
    const tableContainer = document.querySelector(
      ".table-scroll"
    ) as HTMLElement;

    if (tableContainer) {
      const scrollbarWidth =
        tableContainer.offsetWidth - tableContainer.clientWidth;
      setScrollbarWidth(scrollbarWidth);
    }
  }, [managerList]);

  return (
    <Box classes="main manageAccess">
      {registUpdateUserModalInfo.isOpen && (
        <RegistUpdateUserModal
          isOpen={registUpdateUserModalInfo.isOpen}
          onClose={() =>
            changeRegistUpdateUserModalInfo({
              isOpen: false,
              modalType: "CLOSE",
              updateUserCode: "",
            })
          }
          modalType={registUpdateUserModalInfo.modalType}
          updateUserCode={registUpdateUserModalInfo.updateUserCode}
        />
      )}
      {deleteManagerInfo.isOpen && (
        <CommonModal
          title={t("common.button.delete")}
          open={deleteManagerInfo.isOpen}
          onClose={() =>
            changeDeleteManagerInfo({ isOpen: false, managerCode: "" })
          }
          type={"delete"}
          // body={
          //   <Box width={"100%"} justifyContent="flex-start">
          //     <Typography>{t("common.msg.deleteConfirmMsg")}</Typography>
          //   </Box>
          // }
          subMessage={t("common.msg.deleteConfirmMsg")}
          afterSubmitButton={() => handleDeleteManager()}
        />
      )}
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath path={[t("menu.managerSetting")]} />
          <Typography
            classes="mg mt-8"
            fontWeight={700}
            size={24}
            lineHeight="32px"
          >
            {t("menu.managerSetting")}
          </Typography>
        </Box>
        <Button
          startIcon={<Icons variant="common" label="plus" stroke="#FFFFFF" />}
          onClick={() =>
            changeRegistUpdateUserModalInfo({
              isOpen: true,
              modalType: "CREATE",
              updateUserCode: "",
            })
          }
        >
          {t("common.button.add")}
        </Button>
      </Box>
      <Box classes="mg mt-8" direction="column" height={"calc(100% - 84px)"}>
        <Box justifyContent="space-between">
          <Box alignItems="center">
            <Typography size={16} isBold>
              {t("manager.managerSimple")}
            </Typography>
            <Typography size={16} isBold color="green" classes="mg ml-4">
              {managerList && managerList.length > 0 ? managerList.length : 0}
            </Typography>
          </Box>
          <Box width={200} classes="mindsatSearch">
            <SearchBar
              fullWidth={false}
              placeholder={t("manager.nameSeacrh")}
              type="root"
              onChange={(e) => changeManagerName(e.target.value)}
            />
          </Box>
        </Box>
        {managerList?.length ? (
          <Box direction="column" height="calc(100% - 40px)">
            <Table classes="default-table mg mt-10">
              <colgroup>
                {/* */}
                <col style={{ width: "55px" }}></col>
                {/* 관리자 이름 */}
                <col style={{ minWidth: "50px", width: "200px" }}></col>
                {/* 이메일 */}
                <col style={{ minWidth: "50px", width: "220px" }}></col>
                {/* 권한 */}
                <col style={{ minWidth: "50px", width: "120px" }}></col>
                {/* 소속 고객사 */}
                <col style={{ minWidth: "150px", width: "220px" }}></col>
                {/* 접근 가능 정보 메뉴 */}
                <col style={{ width: "*" }}></col>
                {/* 더보기 */}
                <col
                  style={{ width: `calc(50px + ${scrollbarWidth}px)` }}
                ></col>
              </colgroup>
              <thead>
                <tr>
                  <th />
                  <th>{t("manager.name")}</th>
                  <th>{t("user.userEmail")}</th>
                  <th>{t("manager.type")}</th>
                  <th>{t("manager.customer")}</th>
                  <th>{t("manager.accessMenu")}</th>
                  <th />
                </tr>
              </thead>
            </Table>
            <Box classes="table-scroll">
              <Table>
                <colgroup>
                  {/* */}
                  <col style={{ width: "55px" }}></col>
                  {/* 관리자 이름 */}
                  <col style={{ minWidth: "50px", width: "200px" }}></col>
                  {/* 이메일 */}
                  <col style={{ minWidth: "50px", width: "220px" }}></col>
                  {/* 권한 */}
                  <col style={{ minWidth: "50px", width: "120px" }}></col>
                  {/* 소속 고객사 */}
                  <col style={{ minWidth: "150px", width: "220px" }}></col>
                  {/* 접근 가능 정보 메뉴 */}
                  <col style={{ width: "*" }}></col>
                  {/* 더보기 */}
                  <col style={{ width: "50px" }}></col>
                </colgroup>
                <tbody>
                  {managerList?.map((item) => (
                    <tr
                      // onClick={() => changeTargetMailId(item.mailReportId)}
                      className="cursor-pointer"
                    >
                      <td></td>
                      <td>{item.managerName}</td>
                      <td>{item.managerEmail}</td>
                      <td>{item.managerType === 1 ? "Super" : "일반"}</td>
                      <td>
                        {item.customerCode
                          ? companyList?.filter(
                              (item1) =>
                                item1.customerCode === item.customerCode
                            )[0]?.companyName ?? "ALL"
                          : "ALL"}
                      </td>
                      <td>
                        <ToolTip
                          message={renderMenuAccessList(item.menuAccess)}
                        >
                          {renderMenuAccessList(item.menuAccess)}
                        </ToolTip>
                      </td>
                      <td>
                        <SettingButton>
                          {(item.managerEmail ===
                            getUserInfoFromLocalStorage()?.email ||
                            item.managerType === 2) && (
                            <DropdownItem
                              onClick={() => {
                                setButtonClick(
                                  buttonClick > 99999 ? 1 : buttonClick + 1
                                );
                                changeRegistUpdateUserModalInfo({
                                  isOpen: true,
                                  modalType: "UPDATE",
                                  updateUserCode: item.managerCode,
                                });
                              }}
                            >
                              <Icons variant="file" label="rename" />
                              <Typography>
                                {t("common.button.update")}
                              </Typography>
                            </DropdownItem>
                          )}
                          <DropdownItem
                            isRed
                            onClick={() => {
                              setButtonClick(
                                buttonClick > 99999 ? 1 : buttonClick + 1
                              );
                              changeDeleteManagerInfo({
                                isOpen: true,
                                managerCode: item.managerCode,
                              });
                            }}
                          >
                            <Icons
                              variant="common"
                              label="trash"
                              stroke="red"
                            />
                            <Typography color={"red"}>
                              {t("common.button.delete")}
                            </Typography>
                          </DropdownItem>
                        </SettingButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>
          </Box>
        ) : (
          <NoInfo />
        )}
      </Box>
    </Box>
  );
};

export default ManageAccess;
