import { useMemo } from "react";
import { useAppDispatch, useRootState } from ".";
import { bindActionCreators } from "redux";
import {
  trunkCompleteQuiz,
  trunkCreateAiQuizContent,
  trunkDeleteQuizContent,
  trunkDeleteQuizCourse,
  trunkExportQuizSendHistory,
  trunkGetQuizCategory,
  trunkGetQuizContentDetail,
  trunkGetQuizContentList,
  trunkGetQuizCourseDetail,
  trunkGetQuizCourses,
  trunkGetQuizSendHistory,
  trunkGetQuizStat,
  trunkGetQuizTemplate,
  trunkGetSelectedOrgan,
  trunkInsertQuizContent,
  trunkInsertQuizCourse,
  trunkInsertQuizCourseByTraining,
  trunkInsertQuizTemplate,
  trunkIsValidQuizAccess,
  trunkResendQuizMail,
  trunkSyncQuizContent,
  trunkUpdateQuizContent,
  trunkUpdateQuizCourse,
  trunkUpdateQuizTemplate,
} from "../store/quiz";

const useQuizStore = () => {
  const quizState = useRootState((state) => state.quizStore);

  const dispatch = useAppDispatch();

  const boundGetQuizContentList = useMemo(
    () => bindActionCreators(trunkGetQuizContentList, dispatch),
    [dispatch]
  );

  const boundInsertQuizContent = useMemo(
    () => bindActionCreators(trunkInsertQuizContent, dispatch),
    [dispatch]
  );

  const boundGetQuizContentDetail = useMemo(
    () => bindActionCreators(trunkGetQuizContentDetail, dispatch),
    [dispatch]
  );

  const boundUpdateQuizContent = useMemo(
    () => bindActionCreators(trunkUpdateQuizContent, dispatch),
    [dispatch]
  );

  const boundDeleteQuizContent = useMemo(
    () => bindActionCreators(trunkDeleteQuizContent, dispatch),
    [dispatch]
  );

  const boundGetQuizCategory = useMemo(
    () => bindActionCreators(trunkGetQuizCategory, dispatch),
    [dispatch]
  );

  const boundInsertQuizTemplate = useMemo(
    () => bindActionCreators(trunkInsertQuizTemplate, dispatch),
    [dispatch]
  );

  const boundUpdateQuizTemplate = useMemo(
    () => bindActionCreators(trunkUpdateQuizTemplate, dispatch),
    [dispatch]
  );

  const boundGetQuizTemplate = useMemo(
    () => bindActionCreators(trunkGetQuizTemplate, dispatch),
    [dispatch]
  );

  const boundInsertQuizCourse = useMemo(
    () => bindActionCreators(trunkInsertQuizCourse, dispatch),
    [dispatch]
  );

  const boundUpdateQuizCourse = useMemo(
    () => bindActionCreators(trunkUpdateQuizCourse, dispatch),
    [dispatch]
  );

  const boundGetQuizCourses = useMemo(
    () => bindActionCreators(trunkGetQuizCourses, dispatch),
    [dispatch]
  );

  const boundGetQuizCourseDetail = useMemo(
    () => bindActionCreators(trunkGetQuizCourseDetail, dispatch),
    [dispatch]
  );

  const boundDeleteQuizCourse = useMemo(
    () => bindActionCreators(trunkDeleteQuizCourse, dispatch),
    [dispatch]
  );

  const boundGetQuizSendHistory = useMemo(
    () => bindActionCreators(trunkGetQuizSendHistory, dispatch),
    [dispatch]
  );

  const boundResendQuizMail = useMemo(
    () => bindActionCreators(trunkResendQuizMail, dispatch),
    [dispatch]
  );

  const boundExportQuizSendHistory = useMemo(
    () => bindActionCreators(trunkExportQuizSendHistory, dispatch),
    [dispatch]
  );

  const boundIsValidQuizAccess = useMemo(
    () => bindActionCreators(trunkIsValidQuizAccess, dispatch),
    [dispatch]
  );

  const boundCompleteQuiz = useMemo(
    () => bindActionCreators(trunkCompleteQuiz, dispatch),
    [dispatch]
  );

  const boundGetSelectedOrgan = useMemo(
    () => bindActionCreators(trunkGetSelectedOrgan, dispatch),
    [dispatch]
  );

  const boundGetQuizStat = useMemo(
    () => bindActionCreators(trunkGetQuizStat, dispatch),
    [dispatch]
  );

  const boundSyncQuizContent = useMemo(
    () => bindActionCreators(trunkSyncQuizContent, dispatch),
    [dispatch]
  );

  const boundCreateAiQuizContent = useMemo(
    () => bindActionCreators(trunkCreateAiQuizContent, dispatch),
    [dispatch]
  );

  const boundInsertQuizCourseByTraining = useMemo(
    () => bindActionCreators(trunkInsertQuizCourseByTraining, dispatch),
    [dispatch]
  );

  return {
    quizState,
    getQuizContentList: boundGetQuizContentList,
    insertQuizContent: boundInsertQuizContent,
    getQuizContentDetail: boundGetQuizContentDetail,
    updateQuizContent: boundUpdateQuizContent,
    deleteQuizContent: boundDeleteQuizContent,
    getQuizCategory: boundGetQuizCategory,

    insertQuizTemplate: boundInsertQuizTemplate,
    updateQuizTemplate: boundUpdateQuizTemplate,
    getQuizTemplate: boundGetQuizTemplate,
    insertQuizCourse: boundInsertQuizCourse,
    updateQuizCourse: boundUpdateQuizCourse,
    getQuizCourseDetail: boundGetQuizCourseDetail,
    deleteQuizCourse: boundDeleteQuizCourse,
    getQuizSendHistory: boundGetQuizSendHistory,
    resendQuizSendHistory: boundResendQuizMail,
    exportQuizSendHistory: boundExportQuizSendHistory,
    isValidQuizAccess: boundIsValidQuizAccess,
    getQuizCourses: boundGetQuizCourses,
    completeQuiz: boundCompleteQuiz,
    getSelectedOrgan: boundGetSelectedOrgan,
    getQuizStat: boundGetQuizStat,
    syncQuizContent: boundSyncQuizContent,
    createAiQuizContent: boundCreateAiQuizContent,

    insertQuizCourseByTraining: boundInsertQuizCourseByTraining,
  };
};
export default useQuizStore;
