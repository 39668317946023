import {
  Box,
  Button,
  Checkbox,
  DropdownItem,
  Header,
  IconButton,
  Icons,
  ScrollBox,
  SettingButton,
  Table,
  Tag,
  TextField,
  ToolTip,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import { useEffect, useLayoutEffect, useState } from "react";
import { QuizContentDTO, QuizContentReqDTO } from "../../../types/Quiz";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
import TitlePath from "../../../component/TitlePath";
import { getLocalCustomerCode } from "../../../shared/utils/auth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NoSearchResultImage from "../../../shared/image/NoSearchResultImage";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import CategoryTagList from "../../../component/CategoryTag/CategoryTagList";
import NoDashboardImage from "../../../shared/image/NoDashboardImage";
import SettingChildren from "../../../component/SettingChildren";
import CommonModal from "../../../component/CommonModal";
import { nanoid } from "@reduxjs/toolkit";
import CategoryTag from "../../../component/CategoryTag";

const Quiz: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [quizList, setQuizList] = useState<QuizContentDTO[]>([]);
  const [totalQuizCount, setTotalQuizCount] = useState<number>(0);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const localCustomerCode = getLocalCustomerCode();
  const navigate = useNavigate();
  const toast = useToast();
  const [buttonClick, setButtonClick] = useState(0);

  const {
    getQuizContentList,
    getQuizCategory,
    deleteQuizContent,
    quizState,
    syncQuizContent,
  } = useQuizStore();

  const [isSyncModalOpen, setIsSyncModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<
    "USED" | "NOT_USED" | null
  >(null);
  const [isUpdateModalopen, setIsUpdateModalOpen] = useState<number | null>(
    null
  );
  const [selectedQuizes, setSelectedQuizes] = useState<number[]>();
  const checkboxGroup = useCheckboxGroup<number | undefined>();

  const [tempQuizName, setTempQuizName] = useState("");
  const [tempQuizNames, setTempQuizNames] = useState<string[]>([]);
  const [tempCategoryName, setTempCategoryName] = useState("");
  const [tempCategorys, setTempCategorys] = useState<string[]>([]);

  const [filterData, setFilterData] = useState<QuizContentReqDTO>(
    {} as QuizContentReqDTO
  );

  useEffect(() => {
    if (filterData) {
      getQuizContentList(filterData);
    }
  }, [filterData]);

  useEffect(() => {
    getQuizContentList({});
  }, []);

  useEffect(() => {
    if (quizState.quizContentList) {
      setQuizList(quizState.quizContentList.list);
      setTotalQuizCount(quizState.quizContentList.total);
    }
  }, [quizState.quizContentList]);

  const resetFilter = () => {
    setFilterData({} as QuizContentReqDTO);
    setTempCategorys([]);
    setTempQuizNames([]);
  };

  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useLayoutEffect(() => {
    const tableContainer = document.querySelector(
      ".table-scroll"
    ) as HTMLElement;

    if (tableContainer) {
      const scrollbarWidth =
        tableContainer.offsetWidth - tableContainer.clientWidth;
      setScrollbarWidth(scrollbarWidth);
    }
  }, [quizList]);

  const onDelete = async () => {
    if (selectedQuizes) {
      const resp: any = await deleteQuizContent({
        quizIds: selectedQuizes,
      });
      if (resp.payload && resp.payload.code === "SUCCESS") {
        toast.toastMsg(
          nanoid(),
          t("quiz.msg.deleteQuizContentSuccess"),
          "success"
        );
        // navigate("/quiz/content");
        // setSelectedQuizes([]);
        checkboxGroup.handleAllClick(
          checkboxGroup.selected.filter(
            (num) => num && !selectedQuizes.includes(num)
          )
        );
        setIsDeleteModalOpen(null);
      } else {
        toast.toastMsg(
          nanoid(),
          t("template.msg.templateDuplicatedTemplateMsg"),
          "error"
        );
      }
    }
  };

  const onSync = async () => {
    const resp: any = await syncQuizContent();

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(nanoid(), t("quiz.msg.syncSuccess"), "success");
      getQuizContentList({});
    } else {
      toast.toastMsg(nanoid(), t("sync.msg.failMsg"), "error");
    }

    setIsSyncModalOpen(false);
  };

  const header = (
    <Box classes="mg mt-8" justifyContent="space-between" width={"100%"}>
      <Box alignItems="center">
        <Typography fontWeight={700} size={16}>
          {t("quiz.label.quiz")}
        </Typography>
        <Typography classes="mg ml-8" size={16} color="green" fontWeight={700}>
          {totalQuizCount}
        </Typography>
      </Box>
      <Box>
        {checkboxGroup.selected.length > 0 && (
          <Box>
            {checkboxGroup.selected.length === 1 && (
              <Button
                color={"text-noline"}
                height={37}
                startIcon={
                  <Icons
                    variant="mindsat"
                    label="icon_edit3"
                    classes="mg mr-4"
                  />
                }
                onClick={() => {
                  const item: QuizContentDTO = quizList.filter(
                    (q) => q.quizId === checkboxGroup.selected[0]
                  )[0];
                  if (item.commonId) {
                    toast.toastMsg(
                      nanoid(),
                      t("common.msg.cannotDeletePublicContentsMsg"),
                      "error"
                    );
                    return;
                  }
                  if (item.useCourse) {
                    setIsUpdateModalOpen(item.quizId ?? null);
                  } else {
                    navigate(
                      `${process.env.PUBLIC_URL}/quiz/content/write?quiz_id=${item.quizId}`
                    );
                  }
                }}
              >
                {t("common.button.update")}
              </Button>
            )}
            <Button
              color={"text-noline"}
              textColor="red"
              height={37}
              startIcon={
                <Icons variant="mindsat" label="icon_trash" classes="mg mr-4" />
              }
              onClick={() => {
                let commonFlag = false;
                let usedFlag = false;
                checkboxGroup.selected.map((c) => {
                  const item: QuizContentDTO[] = quizList.filter(
                    (q) => q.quizId === c
                  );
                  item.forEach((i) => {
                    if (i.commonId) {
                      commonFlag = true;
                    }
                    if (i.useCourse) {
                      usedFlag = true;
                    }
                  });
                });
                if (commonFlag) {
                  toast.toastMsg(
                    nanoid(),
                    t("template.msg.cannotDeletePublicScenarioMsg"),
                    "error"
                  );
                } else if (usedFlag) {
                  setSelectedQuizes(checkboxGroup.selected as number[]);
                  setIsDeleteModalOpen("USED");
                } else {
                  setSelectedQuizes(checkboxGroup.selected as number[]);
                  setIsDeleteModalOpen("NOT_USED");
                }
              }}
            >
              {t("common.button.delete")}
            </Button>
          </Box>
        )}
        <Button
          startIcon={<Icons variant="common" label="filter" stroke="#252d38" />}
          color="transparent"
          onClick={() => {
            setIsFilterOpen((prev) => !prev);
          }}
        >
          {t("common.list.filter")}
        </Button>
      </Box>
    </Box>
  );

  const filter = (
    <Box classes="mg mt-12" alignItems="center">
      {/* 구분  */}
      <SettingButton
        buttonClick={buttonClick}
        button={
          <Button
            color={
              filterData.isCommon === undefined ? "tertiary" : "tertiary-click"
            }
            classes="mg mr-8"
          >
            {t("quiz.table.type")}
            {filterData.isCommon === undefined
              ? " · " + t("quiz.label.all")
              : filterData.isCommon
              ? " · " + t("quiz.label.public")
              : " · " + t("quiz.label.private")}
          </Button>
        }
        listPosition="right"
      >
        <DropdownItem
          isChecked={filterData.isCommon === undefined}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, isCommon: undefined }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
        >
          {filterData.isCommon === undefined ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("common.auth.all")}
        </DropdownItem>
        <DropdownItem
          isChecked={filterData.isCommon === false}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, isCommon: false }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
        >
          {filterData.isCommon === false ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("template.private")}
        </DropdownItem>
        <DropdownItem
          isChecked={filterData.isCommon}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, isCommon: true }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
        >
          {filterData.isCommon ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("template.public")}
        </DropdownItem>
      </SettingButton>

      {/* quizName */}
      <SettingButton
        buttonClick={buttonClick}
        button={
          <Button
            color={
              filterData.quizNames === undefined ||
              filterData.quizNames.length === 0
                ? "tertiary"
                : "tertiary-click"
            }
            classes="mg mr-8"
          >
            {t("quiz.table.quizName")}
            {filterData.quizNames === undefined ||
            filterData.quizNames.length === 0
              ? " · " + t("quiz.label.all")
              : ` · ${filterData.quizNames.length}`}
          </Button>
        }
        listPosition="right"
      >
        <Box
          alignItems="center"
          classes="setting-button-list-search"
          height={40}
        >
          <TextField
            width={200}
            startLabel={
              <Box justifyContent="center" alignItems="center" height={30}>
                <Icons variant="mindsat" label="icon_search" width={20} />
              </Box>
            }
            isSearch
            borderNone
            placeholder={t("quiz.label.quizNameHolder")}
            text={tempQuizName}
            hasText
            onChange={(e) => setTempQuizName(e.target.value)}
            onKeyPress={(e: any) => {
              if (e.key === "Enter") {
                if (e.target.value === "") return;
                if (tempQuizNames && tempQuizNames.includes(e.target.value)) {
                  return;
                }
                setTempQuizNames((prev) => [...prev, tempQuizName]);
                setTempQuizName("");
              }
            }}
          />
        </Box>
        <Box>
          {tempQuizNames &&
            tempQuizNames.map((item) => (
              <CategoryTag
                key={item}
                text={item}
                endIcon={<Icons variant="common" label="delete" />}
                onClick={() =>
                  setTempQuizNames((prev) => prev.filter((q) => q !== item))
                }
              />
            ))}
        </Box>
        <Box
          alignItems="center"
          classes="setting-button-list-footer"
          justifyContent="flex-end"
        >
          <Box>
            <Button
              text={t("common.button.cancel")}
              color="secondary"
              onClick={() => {
                setTempQuizName("");
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
            />
            <Button
              text={t("common.button.apply")}
              classes={"mg ml-8"}
              color="primary"
              onClick={() => {
                if (tempQuizName !== "") {
                  setTempQuizNames((prev) => [...prev, tempQuizName]);
                  setFilterData((prev) => ({
                    ...prev,
                    quizNames: [...tempQuizNames, tempQuizName],
                  }));
                } else {
                  setFilterData((prev) => ({
                    ...prev,
                    quizNames: tempQuizNames,
                  }));
                }
                setTempQuizName("");
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
            />
          </Box>
        </Box>
      </SettingButton>
      {/* quizName end 
      
      */}

      {/* category  */}
      <SettingButton
        buttonClick={buttonClick}
        button={
          <Button
            color={
              filterData.categorys === undefined ||
              filterData.categorys.length === 0
                ? "tertiary"
                : "tertiary-click"
            }
            classes="mg mr-8"
          >
            {t("quiz.table.category")}
            {filterData.categorys === undefined ||
            filterData.categorys.length === 0
              ? " · " + t("quiz.label.all")
              : ` · ${filterData.categorys.length}`}
          </Button>
        }
        listPosition="right"
      >
        <Box
          alignItems="center"
          classes="setting-button-list-search"
          height={40}
        >
          <TextField
            width={200}
            startLabel={
              <Box justifyContent="center" alignItems="center" height={30}>
                <Icons variant="mindsat" label="icon_search" width={20} />
              </Box>
            }
            isSearch
            borderNone
            placeholder={t("template.msg.categoryPlaceholder")}
            text={tempCategoryName}
            hasText
            onChange={(e) => setTempCategoryName(e.target.value)}
            onKeyPress={(e: any) => {
              if (e.key === "Enter") {
                if (e.target.value === "") return;
                if (tempCategorys && tempCategorys.includes(e.target.value)) {
                  return;
                }
                setTempCategorys((prev) => [...prev, tempCategoryName]);
                setTempCategoryName("");
              }
            }}
          />
        </Box>
        <Box>
          {tempCategorys &&
            tempCategorys.map((item) => (
              <CategoryTag
                key={item}
                text={item}
                endIcon={<Icons variant="common" label="delete" />}
                onClick={() =>
                  setTempCategorys((prev) => prev.filter((q) => q !== item))
                }
              />
            ))}
        </Box>
        <Box
          alignItems="center"
          classes="setting-button-list-footer"
          justifyContent="flex-end"
        >
          <Box>
            <Button
              text={t("common.button.cancel")}
              color="secondary"
              onClick={() => {
                setTempCategoryName("");
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
            />
            <Button
              text={t("common.button.apply")}
              classes={"mg ml-8"}
              color="primary"
              onClick={() => {
                if (tempCategoryName !== "") {
                  setTempCategorys((prev) => [...prev, tempCategoryName]);
                  setFilterData((prev) => ({
                    ...prev,
                    categorys: [...tempCategorys, tempCategoryName],
                  }));
                } else {
                  setFilterData((prev) => ({
                    ...prev,
                    categorys: tempCategorys,
                  }));
                }
                setTempCategoryName("");
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
            />
          </Box>
        </Box>
      </SettingButton>

      {/* end category  */}

      <SettingButton
        buttonClick={buttonClick}
        button={
          <Button
            color={
              filterData.quizType === undefined ? "tertiary" : "tertiary-click"
            }
            classes="mg mr-8"
          >
            {t("quiz.table.formal")}
            {filterData.quizType === undefined
              ? " · " + t("quiz.label.all")
              : filterData.quizType === 1
              ? " · " + t("quiz.label.formatTF")
              : " · " + t("quiz.label.formatChoice")}
          </Button>
        }
        listPosition="right"
      >
        <DropdownItem
          isChecked={filterData.quizType === undefined}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, quizType: undefined }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
        >
          {filterData.quizType === undefined ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("common.auth.all")}
        </DropdownItem>
        <DropdownItem
          isChecked={filterData.quizType === 1}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, quizType: 1 }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
        >
          {filterData.quizType === 1 ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("quiz.label.formatTF")}
        </DropdownItem>
        <DropdownItem
          isChecked={filterData.quizType === 2}
          onClick={() => {
            setFilterData((prev) => ({ ...prev, quizType: 2 }));
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
        >
          {filterData.quizType === 2 ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("quiz.label.formatChoice")}
        </DropdownItem>
      </SettingButton>
      <IconButton transparent onClick={resetFilter} classes="mg ml-8">
        <Icons variant="common" label="refresh" stroke="#B5BBC2" />
      </IconButton>
    </Box>
  );

  return (
    <ScrollBox classes="main">
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath path={[t("menu.quizTitle"), t("menu.quizContent")]} />
          <Typography
            fontWeight={700}
            size={24}
            lineHeight="32px"
            classes="mg mt-8"
          >
            {[t("menu.quizContent")]}
          </Typography>
        </Box>
        <Box>
          {/* <ToolTip message={t("sync.buttonTooltip")}> */}
          {localCustomerCode && (
            <Button
              startIcon={<Icons variant="common" label="sync" />}
              color="secondary"
              onClick={() => setIsSyncModalOpen(true)}
              classes="mg mr-10"
            >
              {t("sync.updateSimple")}
            </Button>
          )}
          {/* </ToolTip> */}
          <Button
            startIcon={<Icons variant="common" label="plus" stroke="#252d38" />}
            color="secondary"
            onClick={() =>
              navigate(`${process.env.PUBLIC_URL}/quiz/content/write`)
            }
          >
            {t("common.button.add")}
          </Button>
        </Box>
      </Box>

      <Box width="100%" height={"calc(100% - 84px)"}>
        <Box direction="column" width="100%">
          {header}
          {isFilterOpen && filter}
          <Box
            direction="column"
            height={isFilterOpen ? "calc(100% - 85px)" : "calc(100% - 40px)"}
          >
            <Table classes="default-table mg mt-12">
              <colgroup>
                <col style={{ width: "52px" }}></col>
                <col style={{ width: "72px" }}></col>
                <col style={{ width: "360px" }}></col>
                <col style={{ width: "*" }}></col>
                <col style={{ width: "160px" }}></col>
                <col
                  style={{ width: `calc(46px + ${scrollbarWidth}px)` }}
                ></col>
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <Checkbox
                      checked={
                        quizList.length > 0
                          ? checkboxGroup.selected.length === quizList.length
                          : false
                      }
                      onChange={() =>
                        checkboxGroup.handleAllClick(
                          checkboxGroup.selected.length === quizList.length
                            ? []
                            : quizList.map((q) => q.quizId)
                        )
                      }
                    />
                  </th>
                  <th>{t("quiz.table.type")}</th>
                  <th>{t("quiz.table.quizName")}</th>
                  <th>{t("quiz.table.category")}</th>
                  <th>{t("quiz.table.formal")}</th>
                  <th />
                </tr>
              </thead>
            </Table>
            <Box classes="table-scroll">
              {quizList && quizList.length === 0 ? (
                JSON.stringify(filterData) !==
                JSON.stringify({} as QuizContentReqDTO) ? (
                  <Box
                    width={"100%"}
                    direction="column"
                    // justifyContent="center"
                    alignItems="center"
                    classes="mg mt-60"
                  >
                    <NoSearchResultImage width={200} />
                    <Typography classes="mg mt-36" size={18} fontWeight={700}>
                      {t("quiz.label.noSearch")}
                    </Typography>
                    <Typography classes="mg mt-8" size={16} color="secondary">
                      {t("quiz.msg.noFilterQuizContent")}
                    </Typography>
                    <Button
                      classes="mg mt-16"
                      startIcon={
                        <Icons
                          variant="common"
                          label="refresh"
                          stroke="white"
                        />
                      }
                      onClick={resetFilter}
                    >
                      {t("common.button.resetFilter")}
                    </Button>
                  </Box>
                ) : (
                  <Box
                    width={"100%"}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <NoDashboardImage />
                    <Typography
                      classes="mg mt-24 mb-8 "
                      size={18}
                      fontWeight={700}
                    >
                      {t("quiz.msg.noItems")}
                    </Typography>
                    <Typography color="secondary" classes="mg mb-4 " size={14}>
                      {t("quiz.msg.noQuizContent")}
                    </Typography>
                    <Typography color="secondary" classes="mg mb-24" size={14}>
                      {t("quiz.msg.addQuizContent")}
                    </Typography>
                    <Button
                      startIcon={
                        <Icons variant="common" label="plus" stroke="#FFFFFF" />
                      }
                      color="primary"
                      onClick={() =>
                        navigate(`${process.env.PUBLIC_URL}/quiz/content/write`)
                      }
                    >
                      {t("template.insert")}
                    </Button>
                  </Box>
                )
              ) : (
                <Table>
                  <colgroup>
                    <col style={{ width: "52px" }}></col>
                    <col style={{ width: "72px" }}></col>
                    <col style={{ width: "360px" }}></col>
                    <col style={{ width: "*" }}></col>
                    <col style={{ width: "160px" }}></col>
                    <col
                      style={{ width: `calc(46px + ${scrollbarWidth}px)` }}
                    ></col>
                  </colgroup>
                  <tbody>
                    {quizList &&
                      quizList.map((q) => (
                        <tr>
                          <td>
                            <Checkbox
                              onChange={() =>
                                checkboxGroup.handleClick(q.quizId)
                              }
                              checked={checkboxGroup.isSelected(q.quizId)}
                            />
                          </td>
                          <td>
                            {q.commonId ? (
                              <Tag
                                name={t("template.public")}
                                size={i18n.language === "ko" ? "ms" : "sm"}
                                color="blue"
                                radius={6}
                              />
                            ) : (
                              <Tag
                                name={t("template.private")}
                                size={i18n.language === "ko" ? "ms" : "sm"}
                                color="pink"
                                radius={6}
                              />
                            )}
                          </td>
                          <td>
                            <ToolTip
                              message={q.quizName}
                              maxWidth={"100%"}
                              width={"fit-content"}
                            >
                              <Typography ellipsis>{q.quizName}</Typography>
                            </ToolTip>
                          </td>
                          <td>
                            {q.categorys && (
                              <CategoryTagList categorys={q.categorys} />
                            )}
                          </td>

                          <td>
                            {q.quizType === 1
                              ? t("quiz.label.formatTF")
                              : t("quiz.label.formatChoice")}
                          </td>
                          <td>
                            {q.commonId ? (
                              <SettingButton buttonClick={buttonClick}>
                                <SettingChildren
                                  detailEvent={() =>
                                    navigate(
                                      `${process.env.PUBLIC_URL}/quiz/content/detail?quiz_id=${q.quizId}`
                                    )
                                  }
                                  editEvent={
                                    !localCustomerCode
                                      ? () =>
                                          q.useCourse
                                            ? setIsUpdateModalOpen(
                                                q.quizId ?? null
                                              )
                                            : navigate(
                                                `${process.env.PUBLIC_URL}/quiz/content/write?quiz_id=${q.quizId}`
                                              )
                                      : undefined
                                  }
                                  deleteEvent={
                                    localCustomerCode
                                      ? undefined
                                      : () => {
                                          if (q.quizId) {
                                            setSelectedQuizes([q.quizId]);
                                          }

                                          if (q.useCourse) {
                                            setIsDeleteModalOpen("USED");
                                          } else {
                                            setIsDeleteModalOpen("NOT_USED");
                                          }
                                        }
                                  }
                                />
                              </SettingButton>
                            ) : (
                              <SettingButton buttonClick={buttonClick}>
                                <SettingChildren
                                  detailEvent={() =>
                                    navigate(
                                      `${process.env.PUBLIC_URL}/quiz/content/detail?quiz_id=${q.quizId}`
                                    )
                                  }
                                  editEvent={() =>
                                    q.useCourse
                                      ? setIsUpdateModalOpen(q.quizId ?? null)
                                      : navigate(
                                          `${process.env.PUBLIC_URL}/quiz/content/write?quiz_id=${q.quizId}`
                                        )
                                  }
                                  deleteEvent={() => {
                                    q.quizId && setSelectedQuizes([q.quizId]);
                                    if (q.useCourse) {
                                      setIsDeleteModalOpen("USED");
                                    } else {
                                      setIsDeleteModalOpen("NOT_USED");
                                    }
                                  }}
                                />
                              </SettingButton>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {isDeleteModalOpen && (
        <CommonModal
          open={isDeleteModalOpen === null ? false : true}
          onClose={() => setIsDeleteModalOpen(null)}
          title={t("quiz.msg.deleteQuizContentTitle")}
          afterSubmitButton={onDelete}
          subMessage={
            isDeleteModalOpen === "USED"
              ? t("quiz.msg.deleteQuizContentAlert")
              : t("quiz.msg.deleteQuizContentMsg")
          }
          type="delete"
        />
      )}
      {!!isUpdateModalopen && (
        <CommonModal
          open={!!isUpdateModalopen}
          onClose={() => setIsUpdateModalOpen(null)}
          title={t("quiz.msg.updateQuizContentTitle")}
          afterSubmitButton={() => {
            navigate(
              `${process.env.PUBLIC_URL}/quiz/content/write?quiz_id=${isUpdateModalopen}`
            );
            setIsUpdateModalOpen(null);
          }}
          subMessage={t("quiz.msg.updateQuizContentAlert")}
        />
      )}
      {isSyncModalOpen && (
        <CommonModal
          open={isSyncModalOpen}
          onClose={() => setIsSyncModalOpen(false)}
          afterSubmitButton={onSync}
          title={t("quiz.label.sync")}
          // body={
          //   <Box direction="column">
          //     <Typography>{t("template.msg.syncScenarioMsg")}</Typography>
          //   </Box>
          // }
          subMessage={t("quiz.msg.syncContent")}
          footer={
            <Box justifyContent="flex-end">
              <Button
                text={t("common.button.cancel")}
                color="secondary"
                onClick={() => setIsSyncModalOpen(false)}
              />

              <Button
                text={t("common.button.update2")}
                classes={"mg ml-8"}
                color="primary"
                onClick={() => {
                  onSync();
                  setIsSyncModalOpen(false);
                }}
              />
            </Box>
          }
        />
      )}
    </ScrollBox>
  );
};

export default Quiz;
