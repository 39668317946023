const QuizStatCompleteUser = ({
  classes,
  width,
}: {
  classes?: string;
  width?: string | number;
}) => {
  return (
    <svg
      width={width || 40}
      height={41}
      className={classes}
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M35.5 3.63672H22.5C21.6716 3.63672 21 4.30829 21 5.13672V18.1367C21 18.9651 21.6716 19.6367 22.5 19.6367H35.5C36.3284 19.6367 37 18.9651 37 18.1367V5.13672C37 4.30829 36.3284 3.63672 35.5 3.63672Z"
        fill="#9BE0BC"
      />
      <path
        d="M23.5005 6.73633H34.5002V8.53633H23.5005V6.73633Z"
        fill="#1BA45D"
      />
      <path d="M23.5 10.7363H32.2998V12.5363H23.5V10.7363Z" fill="#1BA45D" />
      <path d="M23.5 14.7363H30.0998V16.5363H23.5V14.7363Z" fill="#1BA45D" />
      <path
        d="M17.5 21.6367H4.5C3.67157 21.6367 3 22.3083 3 23.1367V36.1367C3 36.9651 3.67157 37.6367 4.5 37.6367H17.5C18.3284 37.6367 19 36.9651 19 36.1367V23.1367C19 22.3083 18.3284 21.6367 17.5 21.6367Z"
        fill="#9BE0BC"
      />
      <path
        d="M5.50049 24.7363H16.5002V26.5363H5.50049V24.7363Z"
        fill="#1BA45D"
      />
      <path d="M5.5 28.7363H14.2998V30.5363H5.5V28.7363Z" fill="#1BA45D" />
      <path d="M5.5 32.7363H12.0998V34.5363H5.5V32.7363Z" fill="#1BA45D" />
      <path
        d="M17.5 3.63672H4.5C3.67157 3.63672 3 4.30829 3 5.13672V18.1367C3 18.9651 3.67157 19.6367 4.5 19.6367H17.5C18.3284 19.6367 19 18.9651 19 18.1367V5.13672C19 4.30829 18.3284 3.63672 17.5 3.63672Z"
        fill="#9BE0BC"
      />
      <path
        d="M10.9999 11.1375C12.4358 11.1375 13.5999 9.97344 13.5999 8.5375C13.5999 7.10156 12.4358 5.9375 10.9999 5.9375C9.56396 5.9375 8.3999 7.10156 8.3999 8.5375C8.3999 9.97344 9.56396 11.1375 10.9999 11.1375Z"
        fill="#1BA45D"
      />
      <path
        d="M14.1236 17.3379L7.87578 17.3379C7.32516 17.3379 6.79713 17.1161 6.40778 16.7213C6.01844 16.3265 5.79971 15.791 5.79971 15.2326V15.1603C5.79971 14.4117 6.093 13.6938 6.61496 13.1645C7.13693 12.6352 7.84484 12.3379 8.58301 12.3379H13.4164C14.1546 12.3379 14.8625 12.6352 15.3845 13.1645C15.9064 13.6938 16.1997 14.4117 16.1997 15.1603V15.2392C16.198 15.7964 15.9784 16.3302 15.5893 16.7236C15.2001 17.117 14.6731 17.3379 14.1236 17.3379Z"
        fill="#1BA45D"
      />
      <path
        d="M35.5 21.6367H22.5C21.6716 21.6367 21 22.3083 21 23.1367V36.1367C21 36.9651 21.6716 37.6367 22.5 37.6367H35.5C36.3284 37.6367 37 36.9651 37 36.1367V23.1367C37 22.3083 36.3284 21.6367 35.5 21.6367Z"
        fill="#9BE0BC"
      />
      <path
        d="M24.7998 30.3786V32.9965C24.7998 33.6923 25.3639 34.2565 26.0598 34.2565H32.3598C33.0557 34.2565 33.6198 33.6923 33.6198 32.9965V30.1816L29.7731 31.9857C29.2879 32.2133 28.7117 32.2133 28.2266 31.9857L24.7998 30.3786Z"
        fill="#1BA45D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.7002 27.8863C22.7002 27.7189 22.8111 27.5641 22.9915 27.4795L28.7025 24.801C28.8864 24.7148 29.114 24.7148 29.2979 24.801L35.0089 27.4795C35.1893 27.5641 35.3002 27.7189 35.3002 27.8863C35.3002 28.0537 35.1893 28.2086 35.0089 28.2932L29.2979 30.9717C29.114 31.0579 28.8864 31.0579 28.7025 30.9717L22.9915 28.2932C22.8111 28.2086 22.7002 28.0537 22.7002 27.8863Z"
        fill="#1BA45D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.7402 27.5363C35.0495 27.5363 35.3002 27.787 35.3002 28.0963V30.8963C35.3002 31.2056 35.0495 31.4563 34.7402 31.4563C34.4309 31.4563 34.1802 31.2056 34.1802 30.8963V28.0963C34.1802 27.787 34.4309 27.5363 34.7402 27.5363Z"
        fill="#1BA45D"
      />
    </svg>
  );
};

export default QuizStatCompleteUser;
