import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import { useEffect, useRef, useState } from "react";
import { ImagesInfo, PhishingDocument, SiteInfo } from "../../../types/Info";
import useContentStore from "../../../redux/dispatcher/useContentStore";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

interface RegitOrEditSiteModalProps {
  isOpen: boolean;
  onClose: () => void;
  documentId?: number;
}

const RegitOrEditDocumentModal: React.FC<RegitOrEditSiteModalProps> = ({
  isOpen,
  onClose,
  documentId,
}) => {
  const { t } = useTranslation();
  const hiddenFileInput = useRef<any>(null);
  const [file, setFile] = useState<File | null>();
  const [documentName, setDocumentName] = useState<string | null>();

  const { contentState, uploadAttach, updateAttach, getAttaches } =
    useContentStore();
  const toast = useToast();

  const ext = ["html"];

  useEffect(() => {
    setDocumentName(
      documentId
        ? contentState.attaches.filter(
            (item: PhishingDocument) => item.documentId === documentId
          )[0].documentName
        : null
    );
    setFile(null);
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = "";
    }
  }, [documentId]);

  const onSubmit = async () => {
    //유효성 검사

    if (documentName) {
      if (/^\s*$/.test(documentName)) {
        toast.toastMsg(nanoid(), t("common.msg.nameEmptyMsg"), "error");
        return;
      }

      if (documentName?.length > 128) {
        toast.toastMsg(nanoid(), t("common.msg.fieldLengthLimitMsg"), "error");
        return;
      }

      if (documentId) {
        const resp: any = await updateAttach({
          request: { documentName, documentId: documentId },
          file: file ?? null,
        });
        if (resp.payload && resp.payload.code === "SUCCESS") {
          toast.toastMsg(
            nanoid(),
            t("attach.msg.attachUpdateSuccessMsg"),
            "success"
          );
          getAttaches({});
        } else if (resp.payload.code === "DATA_DUPLICATE") {
          toast.toastMsg(
            nanoid(),
            t("attach.msg.attachDuplicatedNameMsg"),
            "error"
          );
          return;
        } else {
          toast.toastMsg(
            nanoid(),
            t("attach.msg.attachUpdateFailMsg"),
            "error"
          );
        }
      } else {
        if (!file) {
          toast.toastMsg(
            nanoid(),
            t("attach.msg.attachUndefinedFileMsg"),
            "error"
          );
          return;
        }

        const resp: any = await uploadAttach({
          file,
          request: { documentName },
        });

        if (resp.payload && resp.payload.code === "SUCCESS") {
          toast.toastMsg(
            nanoid(),
            t("attach.msg.attachAddSuccessMsg"),
            "success"
          );
          getAttaches({});
        } else {
          if (resp.payload.code === "DATA_DUPLICATE") {
            toast.toastMsg(
              nanoid(),
              t("attach.msg.attachDuplicatedNameMsg"),
              "error"
            );
            return;
          }

          toast.toastMsg(nanoid(), t("attach.msg.attachAddFailMsg"), "error");
        }
      }
      setFile(null);
      setDocumentName(null);
      onClose();
    } else {
      toast.toastMsg(nanoid(), t("common.msg.nameEmptyMsg"), "error");
    }
  };

  const handleFileClick = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  const handleFileChange = (e: any) => {
    let value = "";
    const name = e.target.name;

    if (name === "file") {
      const fileName = e.target.files[0] && e.target.files[0].name;
      const idx = fileName ? fileName.lastIndexOf(".") : -1;
      if (idx > 0) {
        const extension = fileName.substring(idx + 1);
        // 확장자 체크
        if (!ext.includes(extension.toLowerCase())) {
          toast.toastMsg(nanoid(), t("link.msg.fileTypeCheckMsg"), "error");
          e.target.value = "";
          return;
        }
      }

      // 사이즈 체크
      if (e.target.files[0] && e.target.files[0].size > 20971520) {
        toast.toastMsg(
          nanoid(),
          t("attach.msg.attachInvalidFileSizeMsg"),
          "error"
        );
        e.target.value = "";
        return;
      }

      setFile(e.target.files[0]);
    } else {
      value = e.target.value.trim();
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      width={480}
      title={documentId ? t("attach.update") : t("attach.add")}
      isDivider={false}
      footer={
        <Box justifyContent="flex-end" width="100%">
          <Button color="secondary" onClick={onClose}>
            {t("common.button.cancel")}
          </Button>
          <Button classes="mg ml-8" onClick={onSubmit}>
            {documentId ? t("common.button.save") : t("common.button.save")}
          </Button>
        </Box>
      }
    >
      <Box direction="column">
        <Box direction="column">
          <Typography fontWeight={700}>{t("attach.name")}</Typography>
          <TextField
            classes="mg mt-12"
            height={40}
            borderRadius={8}
            text={documentName ?? ""}
            width={424}
            onChange={(e: any) => setDocumentName(e.target.value)}
            hasText
            placeholder={t("template.msg.attachNamePlaceholder")}
            maxLength={128}
          />
        </Box>
        <Box direction="column" classes="mg mt-32">
          <Typography fontWeight={700}>{t("attach.upload")}</Typography>
          <Box alignItems="center" width={600} height={50} classes="mg mt-12">
            <input
              style={{ display: "none" }}
              name="file"
              type="file"
              onChange={handleFileChange}
              className="modalField hide"
              ref={hiddenFileInput}
            />

            <TextField
              readOnly
              name="fileName"
              hasText
              text={file ? file.name : ""}
              placeholder={t("attach.msg.attachUndefinedFileMsg")}
              height={40}
              width={329}
            />

            <Button
              color="secondary"
              onClick={handleFileClick}
              classes="mg mt-10 ml-8"
              width={87}
              padding={10}
            >
              {t("common.button.selectFile")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default RegitOrEditDocumentModal;
