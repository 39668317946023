import {
  Box,
  Button,
  Modal,
  ScrollBox,
  Typography,
} from "fasoo-ui-component-next";
import { useTranslation } from "react-i18next";
import { userListBean } from "../../../types/Info";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import SmallUserChip from "../../../component/SmallUserChip";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SimpleTreeNode } from "../../../types/User";
import useDeptStore from "../../../redux/dispatcher/useDeptStore";
import TreeSimple from "../../../component/TreeSimple";

interface UserAddModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedUserIds: string[];
  selectedDeptIds: string[];
  setSelectedUserIds: Dispatch<SetStateAction<string[]>>;
  setSelectedDeptIds: Dispatch<SetStateAction<string[]>>;
  userCheckboxGroup: {
    selected: string[];
    handleClick: (id: string) => string[];
    handleAllClick: (allIds: string[]) => void;
    isSelected: (id: string) => boolean;
    initSelected: (initIds: string[]) => void;
  };
  deptCheckboxGroup: {
    selected: string[];
    handleClick: (id: string) => string[];
    handleAllClick: (allIds: string[]) => void;
    isSelected: (id: string) => boolean;
    initSelected: (initIds: string[]) => void;
  };
  organTree: SimpleTreeNode[] | undefined;
  //   changeOrganTree: Dispatch<SetStateAction<SimpleTreeNode[]>>;
}

const UserModal: React.FC<UserAddModalProps> = ({
  isOpen,
  onClose,
  selectedUserIds,
  selectedDeptIds,
  setSelectedUserIds,
  setSelectedDeptIds,
  userCheckboxGroup,
  deptCheckboxGroup,
  organTree,
}) => {
  const { t } = useTranslation();

  const onSubmit = () => {
    setSelectedUserIds(userCheckboxGroup.selected);
    setSelectedDeptIds(deptCheckboxGroup.selected);
    onClose();
  };

  const footer = (
    <Box justifyContent="flex-end" height={80}>
      <Button color="secondary" onClick={onClose}>
        {t("common.button.cancel")}
      </Button>

      <Button
        classes="mg ml-8"
        onClick={onSubmit}
        //   disabled={
        //     !programInfo.configName ||
        //     !programInfo.sendMailCount ||
        //     programInfo?.sendMailCount < 1 ||
        //     programInfo?.sendMailCount > 100 ||
        //     userCheckboxGroup.selected.length === 0 ||
        //     (checkboxScenarioGroup.selected.length === 0 &&
        //       templates.length === 0)
        //   }
      >
        {t("common.button.apply")}
      </Button>
    </Box>
  );
  return (
    <Modal
      open={isOpen}
      title={t("quiz.label.selectOrgChart")}
      titleFontSize={20}
      width={816}
      height={720}
      isDivider={false}
      onClose={onClose}
      footer={footer}
    >
      <Box classes="mg mb-10" direction="column">
        {/* {selctedUserListInDetail?.length === 0 && (
          <Box
            classes="mg mt-24"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography fontWeight={700}>
                {t("program.selectedUser")}
              </Typography>
              <Typography fontWeight={700} color="green" classes="mg ml-8">
                {userCheckboxGroup.selected.length}
                {t("program.programPeopleUnit")}
              </Typography>
            </Box>
            <Box>
            </Box>
          </Box>
        )} */}
        {/* 조직도 */}
        <Box classes="mg mt-8">
          <ScrollBox height={500} width={770} classes="pd pb-4">
            {organTree && (
              <TreeSimple
                rootCode={"COMPANY"}
                treeNodes={organTree}
                depth={0}
                checkboxUserGroup={userCheckboxGroup}
                checkboxDeptGroup={deptCheckboxGroup}
                checkUserIds={userCheckboxGroup.selected}
                checkDeptIds={deptCheckboxGroup.selected}
                isUserCheck
                isDeptCheck
                // disableCheck={
                //   configId &&
                //   programState.configDetail?.trainingInfo &&
                //   programState?.configDetail?.trainingInfo.startedFlag
                // }
                // isShowUserCnt
                classes="mg mt-8"
              />
            )}
          </ScrollBox>
        </Box>
      </Box>
    </Modal>
  );
};

export default UserModal;
