import {
  Box,
  Button,
  Calendar,
  Checkbox,
  DropdownItem,
  Icons,
  Modal,
  SearchBar,
  SettingButton,
  Table,
  Tag,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import TitlePath from "../../../component/TitlePath";
import "./index.scss";
import { ReactNode, useEffect, useLayoutEffect, useState } from "react";
import useReportStore from "../../../redux/dispatcher/useReportStore";
import { ReportMail, ReportMailSearchType } from "../../../types/Report";
import utilsCommon from "../../../shared/utils/common";
import { useNavigate } from "react-router-dom";
import ReportViewDetail from "./deatil";
import NoInfo from "../../../component/NoInfo";
import i18n from "../../../locales";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { nanoid } from "@reduxjs/toolkit";
import {
  FixedSizeList,
  FixedSizeListProps,
  ListOnItemsRenderedProps,
} from "react-window";
import InfiniteLoader from "react-window-infinite-loader";

type OnItemsRendered = (props: ListOnItemsRenderedProps) => any;
interface InfiniteLoaderProps {
  isItemLoaded: (index: number) => boolean;
  loadMoreItems: (
    startIndex: number,
    stopIndex: number
  ) => Promise<void> | void;
  itemCount: number;
  children: (props: {
    onItemsRendered: OnItemsRendered;
    ref: (ref: any) => void;
  }) => ReactNode;
  threshold?: number | undefined;
  minimumBatchSize?: number | undefined;
}

const InfiniteLoaderComponent =
  InfiniteLoader as unknown as React.ComponentType<InfiniteLoaderProps>;

const FixedSizeListComponent = FixedSizeList as unknown as React.ComponentType<
  FixedSizeListProps<any>
>;

const ReportView: React.FC = () => {
  const PAGING_SIZE = 100;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { reportState, getReportMails } = useReportStore();
  const toast = useToast();

  const [sort, changeSort] = useState("");
  const [openFilterTab, toggleOpenFilterTab] = useState(false);
  const [reportList, changeReportList] = useState<ReportMail[]>([]);
  const [reportCnt, changeReportCnt] = useState<number>(0);
  const [targetMailId, changeTargetMailId] = useState(-1);
  const [filter, changeFilter] = useState<ReportMailSearchType>({
    searchWord: "",
    mailType: "null",
    forwardedStartDate: undefined,
    forwardedEndDate: undefined,
    receivedStartDate: undefined,
    receivedEndDate: undefined,
    forwaredDateType: 0,
    receivedDateType: 0,
  });
  const [tempDateFilters, changeTempDateFilter] = useState({
    forwardedStartDate: dayjs()
      .subtract(1, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    forwardedEndDate: dayjs()
      .set("hour", 23)
      .set("minute", 59)
      .set("second", 59)
      .valueOf(),
    // receivedStartDate: new Date().valueOf(),
    // receivedEndDate: new Date().valueOf(),
  });
  const [openModalType, changeOpenModalType] = useState("CLOSE");
  const [buttonClick, setButtonClick] = useState(0);

  const mailTypeTypes = [
    { key: "training", resource: t("report.reportTrainingMail") },
    { key: "normal", resource: t("report.reportNotTrainingMail") },
    {
      key: "all",
      resource: `${t("report.reportTrainingMail")} / ${t(
        "report.reportNotTrainingMail"
      )}`,
    },
  ];
  const dateStates = [
    { id: 0, text: t("common.auth.all") },
    { id: 1, text: t("program.filter.beforeOneMonth") },
    { id: 2, text: t("program.filter.beforeThreeMonth") },
    { id: 3, text: t("program.filter.beforeSixMonth") },
  ];

  useEffect(() => {
    getNewReportMails();
    changeTargetMailId(-1);
  }, [filter]);
  const getNewReportMails = async () => {
    const resp: any = await getReportMails({
      ...filter,
      size: PAGING_SIZE,
      forwardedStartDate: filter.forwardedStartDate
        ? filter.forwardedStartDate / 1000
        : undefined,
      forwardedEndDate: filter.forwardedEndDate
        ? filter.forwardedEndDate / 1000
        : undefined,
      receivedStartDate: filter.receivedStartDate
        ? filter.receivedStartDate / 1000
        : undefined,
      receivedEndDate: filter.receivedEndDate
        ? filter.receivedEndDate / 1000
        : undefined,
    });
    changeReportList(resp.payload.data.list);
  };
  useEffect(() => {
    // changeReportList(reportState.reportListWithCnt?.list);
    changeReportCnt(reportState.reportListWithCnt?.total);
  }, [reportState.reportListWithCnt]);

  const renderTableHeaderWithSort = (key: string, name: string) => {
    return (
      <Box
        onClick={() =>
          changeSort(
            !sort.includes(key)
              ? key + "-Desc"
              : sort === key + "-Desc"
              ? key
              : ""
          )
        }
      >
        <Typography classes="cursor-pointer" color="secondary">
          {name}
        </Typography>
        {sort.includes(key) && (
          <Icons
            variant="arrow"
            label={sort.includes("-Desc") ? "down" : "up"}
          />
        )}
      </Box>
    );
  };
  const handleChangeFilter = (
    type: "mailType" | "forwaredDate" | "receivedDate",
    value: string
  ) => {
    if (type === "mailType") {
      setButtonClick(buttonClick + 1);
      changeFilter({
        ...filter,
        mailType:
          filter.mailType === "null"
            ? value
            : filter.mailType === value
            ? "null"
            : filter.mailType === "all"
            ? mailTypeTypes.filter((item) => item.key !== value)[0].key
            : "all",
      });
    }
    if (type === "forwaredDate") {
      setButtonClick(buttonClick + 1);
      if (parseInt(value) < 5) {
        changeFilter({
          ...filter,
          forwaredDateType: parseInt(value),
          forwardedStartDate:
            value === "0"
              ? undefined
              : utilsCommon.getDayBefore(parseInt(value)),
          forwardedEndDate: value === "0" ? undefined : dayjs().valueOf(),
        });
      } else {
        changeFilter({
          ...filter,
          forwaredDateType: 5,
          forwardedStartDate: tempDateFilters.forwardedStartDate,
          forwardedEndDate: tempDateFilters.forwardedEndDate,
        });
      }
    }
  };
  const renderFilterTypes = (type: "mailType") => {
    if (type === "mailType") {
      return filter.mailType === "null"
        ? t("report.reportMailType")
        : mailTypeTypes.filter((item) => item.key === filter.mailType)[0]
            .resource;
    }
    return type;
  };
  const onFilterDate = (date: Date, type: string) => {
    const dateStr = date.toString();
    if (type === "start" && openModalType === "SELECT_SEND_DATE") {
      changeTempDateFilter({
        ...tempDateFilters,
        forwardedStartDate: dayjs(dateStr).valueOf(),
      });
    }
    if (type === "end" && openModalType === "SELECT_SEND_DATE") {
      changeTempDateFilter({
        ...tempDateFilters,
        forwardedEndDate: dayjs(dateStr).valueOf(),
      });
    }
    // if (type === "start" && openModalType === "SELECT_RECEIVE_DATE") {
    //   changeTempDateFilter({
    //     ...tempDateFilters,
    //     receivedStartDate: dayjs(dateStr).valueOf(),
    //   });
    // }
    // if (type === "end" && openModalType === "SELECT_RECEIVE_DATE") {
    //   changeTempDateFilter({
    //     ...tempDateFilters,
    //     receivedEndDate: dayjs(dateStr).valueOf(),
    //   });
    // }
  };

  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useLayoutEffect(() => {
    const tableContainer = document.querySelector(
      ".table-scroll"
    ) as HTMLElement;

    if (tableContainer) {
      const scrollbarWidth =
        tableContainer.offsetWidth - tableContainer.clientWidth;
      setScrollbarWidth(scrollbarWidth);
    }
  }, [reportList]);

  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);

  const isItemLoaded = (index: number): boolean => {
    return index < reportList.length && reportList[index] !== undefined;
  };

  const loadMoreItems = async (startIndex: number, stopIndex: number) => {
    if (isNextPageLoading) return;

    setIsNextPageLoading(true);

    try {
      const resp: any = await getReportMails({
        ...filter,
        size: PAGING_SIZE,
        cursor: reportList[reportList.length - 1].cursor,
        forwardedStartDate: filter.forwardedStartDate
          ? filter.forwardedStartDate / 1000
          : undefined,
        forwardedEndDate: filter.forwardedEndDate
          ? filter.forwardedEndDate / 1000
          : undefined,
        receivedStartDate: filter.receivedStartDate
          ? filter.receivedStartDate / 1000
          : undefined,
        receivedEndDate: filter.receivedEndDate
          ? filter.receivedEndDate / 1000
          : undefined,
      });

      if (resp && resp.payload && resp.payload.data && resp.payload.data.list) {
        changeReportList((prev) => {
          return [...prev, ...resp.payload.data.list];
        });

        if (resp.payload.data.list.length < PAGING_SIZE) {
          setHasNextPage(false);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsNextPageLoading(false);
    }
  };

  const [tableHeight, setTableHeight] = useState(660);

  useEffect(() => {
    const handleResize = () => {
      const tableElement = document.querySelector(
        ".table-scroll"
      ) as HTMLElement;
      const tempTableHeight = tableElement ? tableElement.offsetHeight : 0;
      tableElement && setTableHeight(tempTableHeight);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderReportMailList = () => {
    return (
      <Box classes="mg mt-8" direction="column" height={"calc(100% - 84px)"}>
        <Box justifyContent="space-between">
          <Box alignItems="center">
            <Typography size={16} isBold>
              {t("report.reportList")}
            </Typography>
            <Typography size={16} isBold color="green" classes="mg ml-4">
              {reportCnt ?? 0}
            </Typography>
          </Box>
          <Box>
            <Button
              color={"text-noline"}
              startIcon={<Icons variant="common" label="filter" />}
              onClick={() => toggleOpenFilterTab(!openFilterTab)}
            >
              {t("common.list.filter")}
            </Button>
            <SearchBar
              fullWidth={false}
              placeholder={t("report.reportSearch")}
              type="root"
              value={filter.searchWord}
              onChange={(e) =>
                changeFilter({ ...filter, searchWord: e.target.value })
              }
            />
          </Box>
        </Box>
        {openFilterTab && (
          <Box classes="mg mt-12" height={40}>
            <SettingButton
              classes={"mg ml-10"}
              listPosition={"right"}
              button={
                <Button
                  classes={"mg mb-20"}
                  color={
                    filter.mailType === "null" ? "tertiary" : "tertiary-click"
                  }
                  text={renderFilterTypes("mailType")}
                />
              }
              buttonClick={buttonClick}
            >
              {
                <Box direction="column" classes="checkbox-type--select">
                  <Box justifyContent="space-between" classes="mg mb-8">
                    <Typography
                      lineHeight="normal"
                      size={12}
                      color={"secondary"}
                    >
                      {t("report.reportMailType")}
                    </Typography>
                    <Button
                      color={"text-noline"}
                      textColor="green"
                      onClick={() =>
                        changeFilter({
                          ...filter,
                          mailType: filter.mailType === "all" ? "null" : "all",
                        })
                      }
                    >
                      {filter.mailType === "all"
                        ? t("common.button.clearAll")
                        : t("common.button.selectAll")}
                    </Button>
                  </Box>
                  <Checkbox
                    label={t("report.reportTrainingMail")}
                    checked={["training", "all"].includes(
                      filter?.mailType ?? ""
                    )}
                    onChange={() => handleChangeFilter("mailType", "training")}
                  />
                  <Checkbox
                    label={t("report.reportNotTrainingMail")}
                    checked={["normal", "all"].includes(filter?.mailType ?? "")}
                    onChange={() => handleChangeFilter("mailType", "normal")}
                  />
                </Box>
              }
            </SettingButton>
            <SettingButton
              button={
                <Button
                  color={
                    filter.forwaredDateType === 0
                      ? "tertiary"
                      : "tertiary-click"
                  }
                >
                  {t("report.reportReportingTimePeriod")} ·{" "}
                  {filter.forwaredDateType === 5
                    ? `${utilsCommon.getShortDate(
                        filter.forwardedStartDate
                      )} - ${utilsCommon.getShortDate(filter.forwardedEndDate)}`
                    : dateStates.filter(
                        (item) => item.id === filter.forwaredDateType
                      )[0].text}
                </Button>
              }
              buttonClick={buttonClick}
              classes="mg ml-10"
              listPosition="right"
            >
              {dateStates.map((item) => (
                <DropdownItem
                  isChecked={item.id === filter.forwaredDateType}
                  onClick={() =>
                    handleChangeFilter("forwaredDate", item.id + "")
                  }
                  key={item.id}
                >
                  {item.id === filter.forwaredDateType ? (
                    <Icons variant="common" label="check" stroke="#3182f6" />
                  ) : (
                    <Box width={28} />
                  )}
                  {item.text}
                </DropdownItem>
              ))}
              <DropdownItem
                isChecked={filter.forwaredDateType === 5}
                onClick={() => {
                  setButtonClick(buttonClick + 1);
                  changeOpenModalType("SELECT_SEND_DATE");
                }}
              >
                {filter.forwaredDateType === 5 ? (
                  <Icons variant="common" label="check" stroke="#3182f6" />
                ) : (
                  <Box width={28} />
                )}
                {t("graph.phishingCustomScope")}
              </DropdownItem>
            </SettingButton>
            {/* <SettingButton
              button={
                <Button
                  color={
                    filter.receivedDateType === 0
                      ? "tertiary"
                      : "tertiary-click"
                  }
                >
                  {t("report.reportReportingTimePeriod")} ·{" "}
                  {filter.receivedDateType === 5
                    ? `${utilsCommon.getShortDate(
                        filter.receivedStartDate
                      )} - ${utilsCommon.getShortDate(filter.receivedEndDate)}`
                    : dateStates.filter(
                        (item) => item.id === filter.receivedDateType
                      )[0].text}
                </Button>
              }
              buttonClick={buttonClick}
              classes="mg ml-10"
              listPosition="right"
            >
              {dateStates.map((item) => (
                <DropdownItem
                  isChecked={item.id === filter.receivedDateType}
                  onClick={() =>
                    handleChangeFilter("receivedDate", item.id + "")
                  }
                  key={item.id}
                >
                  {item.id === filter.receivedDateType ? (
                    <Icons variant="common" label="check" stroke="#3182f6" />
                  ) : (
                    <Box width={28} />
                  )}
                  {item.text}
                </DropdownItem>
              ))}
              <DropdownItem
                isChecked={filter.receivedDateType === 5}
                onClick={() => {
                  setButtonClick(buttonClick + 1);
                  changeOpenModalType("SELECT_RECEIVE_DATE");
                }}
              >
                {filter.receivedDateType === 5 ? (
                  <Icons variant="common" label="check" stroke="#3182f6" />
                ) : (
                  <Box width={28} />
                )}
                {t("graph.phishingCustomScope")}
              </DropdownItem>
            </SettingButton> */}
          </Box>
        )}
        {reportList?.length > 0 ? (
          <Box direction="column" height="calc(100% - 40px)">
            <Table classes="default-table mg mt-10">
              <colgroup>
                {/* */}
                <col style={{ width: "55px" }}></col>
                {/* 메일 제목 */}
                <col style={{ width: "*" }}></col>
                {/* 신고자 */}
                <col style={{ minWidth: "50px", width: "230px" }}></col>
                {/* 신고 기간 */}
                <col style={{ minWidth: "50px", width: "230px" }}></col>
                {/* 자동 회신 */}
                <col style={{ width: "100px" }}></col>
                {/* 수동 회신 */}
                <col style={{ width: "120px" }}></col>
                {/* 처리 완료 */}
                <col
                  style={{ width: `calc(150px + ${scrollbarWidth}px)` }}
                ></col>
              </colgroup>
              <thead>
                <tr>
                  <th />
                  <th>
                    {renderTableHeaderWithSort(
                      "exSubject",
                      t("report.reportMailTitle")
                    )}
                  </th>
                  <th>
                    {renderTableHeaderWithSort(
                      "userName",
                      t("report.reportCallUser")
                    )}
                  </th>
                  <th>
                    {renderTableHeaderWithSort(
                      "datetimeForwarded",
                      t("report.reportForwardedTime")
                    )}
                  </th>
                  <th>
                    {renderTableHeaderWithSort(
                      "sendAutoReply",
                      t("report.reportAutoReply")
                    )}
                  </th>
                  <th>
                    {renderTableHeaderWithSort(
                      "sendManualReply",
                      t("report.reportManualReply")
                    )}
                  </th>
                  <th>
                    {renderTableHeaderWithSort(
                      "processComplete",
                      t("report.reportComplete")
                    )}
                  </th>
                </tr>
              </thead>
            </Table>
            <Box classes="table-scroll">
              <Table>
                {/* <tbody>
                  {reportList?.map((item) => ( */}

                <InfiniteLoaderComponent
                  isItemLoaded={isItemLoaded}
                  itemCount={
                    hasNextPage ? reportList.length + 1 : reportList.length
                  }
                  loadMoreItems={loadMoreItems}
                >
                  {({ onItemsRendered, ref }) => (
                    <FixedSizeListComponent
                      itemCount={reportList.length}
                      itemSize={54}
                      width="100%"
                      height={openFilterTab ? tableHeight - 60 : tableHeight}
                      className="report__scroll__table"
                      style={{ width: "100%" }}
                      onItemsRendered={onItemsRendered}
                      // ref={ref}
                    >
                      {({ style, index }) => {
                        const item = reportList[index];

                        return isItemLoaded(index) ? (
                          <tr
                            onClick={() =>
                              changeTargetMailId(item.mailReportId)
                            }
                            className="cursor-pointer"
                            key={item.mailReportId}
                            style={style}
                          >
                            <td></td>
                            <td>
                              <Tag
                                name={
                                  item.trainingFlag
                                    ? t("report.reportTraining")
                                    : t("report.reportNotTraining")
                                }
                                isBgFilled={false}
                                size="ms"
                                color={item.trainingFlag ? "green" : "purple"}
                                radius={6}
                              />
                              <Typography classes="mg ml-10">
                                {item.exSubject}
                              </Typography>
                            </td>
                            <td>{item.userName}</td>
                            <td>
                              {utilsCommon.getFullDateStr(
                                item.datetimeForwarded + "+00:00"
                              )}
                            </td>
                            <td>
                              {item.sendAutoReply ? (
                                <Icons
                                  variant="common"
                                  label="check"
                                  stroke={"#05c072"}
                                />
                              ) : (
                                <Icons
                                  variant="file"
                                  label="new"
                                  stroke="red"
                                />
                              )}
                            </td>
                            <td>
                              {item.sendManualReply ? (
                                <Box>
                                  <Icons
                                    variant="common"
                                    label="check"
                                    stroke={"#05c072"}
                                  />
                                  <Typography>
                                    {item?.manualReplyCnt}
                                  </Typography>
                                </Box>
                              ) : item.trainingFlag ? (
                                "-"
                              ) : (
                                <Icons
                                  variant="file"
                                  label="new"
                                  stroke="red"
                                />
                              )}
                            </td>
                            <td>
                              {item.processComplete ? (
                                <Icons
                                  variant="common"
                                  label="check"
                                  stroke={"#05c072"}
                                />
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                        ) : (
                          <tr>loading...</tr>
                        );
                      }}
                    </FixedSizeListComponent>
                  )}
                </InfiniteLoaderComponent>
                {/* ))}
                </tbody> */}
              </Table>
            </Box>
          </Box>
        ) : (
          <NoInfo />
        )}
      </Box>
    );
  };

  const dateModal = () => {
    return (
      <Modal
        open={openModalType !== "CLOSE"}
        onClose={() => {
          changeOpenModalType("CLOSE");
          changeTempDateFilter({
            forwardedStartDate: dayjs()
              .subtract(1, "month")
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .valueOf(),
            forwardedEndDate: dayjs()
              .set("hour", 23)
              .set("minute", 59)
              .set("second", 59)
              .valueOf(),
          });
        }}
        title={t("graph.phishingCustomScope")}
        isDivider={false}
        width={480}
        footer={
          <Box justifyContent="flex-end">
            <Button
              color={"secondary"}
              onClick={() => {
                changeOpenModalType("CLOSE");
                changeTempDateFilter({
                  forwardedStartDate: dayjs()
                    .subtract(1, "month")
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  forwardedEndDate: dayjs()
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
            >
              {t("common.button.cancel")}
            </Button>
            <Button
              onClick={() => {
                if (
                  tempDateFilters.forwardedStartDate >
                  tempDateFilters.forwardedEndDate
                ) {
                  toast.toastMsg(
                    nanoid(),
                    t("program.msg.programEndBeforeStartMsg"),
                    "error"
                  );
                  return;
                }
                if (
                  tempDateFilters.forwardedEndDate / 1000 -
                    tempDateFilters.forwardedStartDate / 1000 >
                  31556926
                ) {
                  toast.toastMsg(
                    nanoid(),
                    "설정 날짜는 1년 이내여야만 합니다.",
                    "error"
                  );
                  return;
                }

                handleChangeFilter("forwaredDate", "5");
                changeOpenModalType("CLOSE");
              }}
              classes={"mg ml-8"}
            >
              {t("common.button.apply")}
            </Button>
          </Box>
        }
      >
        <Box alignItems="center" justifyContent="center">
          <Calendar
            onSubmit={(date) => onFilterDate(date, "start")}
            placeholder={t("common.info.startDate")}
            selected={new Date(tempDateFilters.forwardedStartDate)}
            dateFormat="yyyy-MM-dd"
            lang={i18n.language}
            maxDate={
              tempDateFilters && tempDateFilters.forwardedEndDate
                ? new Date(tempDateFilters.forwardedEndDate)
                : undefined
            }
          />
          <Box
            style={{ color: "#717985" }}
            alignItems="center"
            classes="mg ml-4 mr-4"
          >
            ~
          </Box>
          <Calendar
            onSubmit={(date) =>
              // changeTempPeriod({
              //   ...tempPeriod,
              //   end: dayjs(date)
              //     .set("hour", 23)
              //     .set("minute", 59)
              //     .set("second", 59)
              //     .valueOf(),
              // })
              onFilterDate(date, "end")
            }
            selected={new Date(tempDateFilters.forwardedEndDate)}
            placeholder={t("common.info.endDate")}
            dateFormat="yyyy-MM-dd"
            lang={i18n.language}
            minDate={
              tempDateFilters && tempDateFilters.forwardedStartDate
                ? new Date(tempDateFilters.forwardedStartDate)
                : undefined
            }
          />
        </Box>
      </Modal>
    );
    // return (
    //   <CommonModal
    //     open={openModalType !== "CLOSE"}
    //     onClose={() => changeOpenModalType("CLOSE")}
    //     title={t("graph.phishingCustomScope")}
    //     body={
    //       <Box alignItems="center">
    //         <Box classes="mg mr-4">
    //           <Calendar
    //             onSubmit={(date) => onFilterDate(date, "start")}
    //             placeholder={t("common.info.startDate")}
    //             selected={
    //               new Date(
    //                 openModalType === "SELECT_SEND_DATE"
    //                   ? tempDateFilters.forwardedStartDate
    //                   : tempDateFilters.receivedStartDate
    //               )
    //             }
    //             lang={i18n.language}
    //             dateFormat="yyyy-MM-dd"
    //           />
    //         </Box>
    //         <Box style={{ color: "#717985" }} alignItems="center">
    //           ~
    //         </Box>
    //         <Box classes="mg ml-4">
    //           <Calendar
    //             onSubmit={(date) => onFilterDate(date, "end")}
    //             selected={
    //               new Date(
    //                 openModalType === "SELECT_SEND_DATE"
    //                   ? tempDateFilters.receivedStartDate
    //                   : tempDateFilters.receivedEndDate
    //               )
    //             }
    //             lang={i18n.language}
    //             placeholder={t("common.info.endDate")}
    //             dateFormat="yyyy-MM-dd"
    //           />
    //         </Box>
    //       </Box>
    //     }
    //     footer={
    //       <Box justifyContent="flex-end">
    //         <Button
    //           text={t("common.button.cancel")}
    //           color="secondary"
    //           onClick={() => changeOpenModalType("CLOSE")}
    //         />

    //           <Button
    //             text={t("common.button.check")}
    //             classes={"mg ml-8"}
    //             color="primary"
    //             onClick={() => (afterSubmitButton(), onClose())}
    //           />

    //       </Box>
    //     }
    //     afterSubmitButton={() =>
    //       handleChangeFilter(
    //         openModalType === "SELECT_SEND_DATE"
    //           ? "forwaredDate"
    //           : "receivedDate",
    //         "5"
    //       )
    //     }
    //   />
    // );
  };
  return (
    <Box classes="main reportView">
      {openModalType !== "CLOSE" && dateModal()}
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath
            path={[t("menu.reportCenter"), t("menu.reportOperation")]}
          />
          <Typography
            classes="mg mt-8"
            fontWeight={700}
            size={24}
            lineHeight="32px"
          >
            {t("menu.reportOperation")}
          </Typography>
        </Box>
        {targetMailId !== -1 && (
          <Button color={"secondary"} onClick={() => changeTargetMailId(-1)}>
            {t("report.reportBackToList")}
          </Button>
        )}
      </Box>
      {targetMailId === -1 ? (
        renderReportMailList()
      ) : (
        <ReportViewDetail
          targetId={targetMailId}
          setTargetId={changeTargetMailId}
          getNewReportMails={getNewReportMails}
        />
      )}
    </Box>
  );
};

export default ReportView;
