import "./index.scss";
import {
  Box,
  Button,
  Icons,
  ScrollBox,
  Typography,
} from "fasoo-ui-component-next";
import TitlePath from "../../../component/TitlePath";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
import { useEffect, useState } from "react";
import { QuizTemplateDTO } from "../../../types/Quiz";
import MailBody from "../../../component/MailBody";

const QuizTemplate: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { quizState, getQuizTemplate } = useQuizStore();

  const [templateInfo, setTemplateInfo] = useState<QuizTemplateDTO>();

  useEffect(() => {
    getQuizTemplate();
  }, []);

  useEffect(() => {
    setTemplateInfo(quizState.quizTemplate);
  }, [quizState.quizTemplate]);

  return (
    <ScrollBox classes="main">
      <Box
        classes="mg mb-24"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath path={[t("menu.quizTitle"), t("menu.quizTemplate")]} />
          <Typography
            classes="mg mt-8"
            fontWeight={700}
            size={24}
            lineHeight="32px"
          >
            {t("menu.quizTemplate")}
          </Typography>
        </Box>
        <Box>
          <Button
            startIcon={
              <Icons
                variant="mindsat"
                label="icon_edit2"
                stroke="#252d38"
                width={20}
                classes="mg mr-6"
              />
            }
            color="secondary"
            onClick={() =>
              navigate(`${process.env.PUBLIC_URL}/quiz/template/write`)
            }
          >
            {t("common.button.update")}
          </Button>
        </Box>
      </Box>

      <Box classes="mg mt-20">
        {templateInfo && (
          <Box classes="quiz__template__container" direction="column">
            <Box classes="mg mb-20">
              <Typography size={20} fontWeight={700}>
                {templateInfo.subject}
              </Typography>
            </Box>
            <Box
              classes="pd pb-16 mg mb-32"
              style={{ borderBottom: "1px solid #B5BBC2" }}
            >
              <Typography>
                {`${t("quiz.label.sender")}: ${templateInfo.sendName} (${
                  templateInfo.sendEmail
                })`}
              </Typography>
            </Box>
            <Box>
              {templateInfo.body && (
                <MailBody
                  body={templateInfo.body}
                  background={false}
                ></MailBody>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </ScrollBox>
  );
};

export default QuizTemplate;
