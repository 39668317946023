import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import programApi from "../api/programApi";
import {
  MailTemplate,
  ProgramAddBean,
  ProgramDetail,
  ProgramUpdateBean,
  UserBean,
} from "../../types/Info";
import {
  ConfigListType,
  OrganTreeType,
  TrainingResultReqType,
} from "../../types/Send";
import { TrainingListReqDTO } from "../../types/Training";
import { TrainResultFilterProps } from "../../Pages/ManageTrain/Result";
import i18n from "../../locales";

interface ConfigState {
  configListWithCount: { total: number; list: ConfigListType[] };
  configDetail?: ProgramDetail;
  deptList: OrganTreeType[];
  templateList: MailTemplate[];
  userList: UserBean[];
  users: OrganTreeType[];
  isDownloadingFile: boolean;
}

// # initial state
const initialState: ConfigState = {
  configListWithCount: { total: 0, list: [] },
  configDetail: undefined,
  deptList: [],
  templateList: [],
  userList: [],
  users: [],
  isDownloadingFile: false,
};

export const trunkGetProgramList = createAsyncThunk(
  "program/list",
  async (filter: TrainingListReqDTO) => {
    const resp = await programApi.getProgramList(filter);
    return resp;
  }
);
export const trunkGetProgramDetail = createAsyncThunk(
  "program/detail",
  async (configId: number) => {
    const resp = await programApi.getProgramDetail(configId);
    return resp;
  }
);
export const trunkAddNewProgram = createAsyncThunk(
  "program/add",
  async (req: ProgramAddBean) => {
    const resp = await programApi.addNewProgram(req);
    return resp;
  }
);
export const trunkDeleteProgram = createAsyncThunk(
  "program/delete",
  async (configId: number) => {
    const resp = await programApi.deleteProgram(configId);
    return resp;
  }
);
export const trunkUpdateProgram = createAsyncThunk(
  "program/update",
  async (req: ProgramUpdateBean) => {
    const resp = await programApi.updateProgram(req);
    return resp;
  }
);
export const trunkActivateProgram = createAsyncThunk(
  "program/active",
  async (req: { configId: number; isActivated: boolean }) => {
    const resp = await programApi.activateProgram(req);
    return resp;
  }
);
export const trunkExportResultFile = createAsyncThunk(
  "program/export/result",
  async (req?: TrainingResultReqType) => {
    const resp = await programApi.downloadResultFile(req);
    return resp;
  }
);
export const trunkExportResultReport = createAsyncThunk(
  "program/export/report",
  async (req?: TrainingResultReqType) => {
    const resp = await programApi.exportResultReport(req);
    return resp;
  }
);

const programSlice = createSlice({
  name: "program",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      trunkGetProgramList.fulfilled,
      (state, { payload: data }) => {
        state.configListWithCount = data.data;
      }
    );
    builder.addCase(
      trunkAddNewProgram.fulfilled,
      (state, { payload: data }) => {
        if (data.code === "SUCCESS") {
          state.configListWithCount.list = [
            data.data,
            ...state.configListWithCount.list,
          ];
          state.configListWithCount.total = state.configListWithCount.total + 1;
        }
      }
    );
    builder.addCase(
      trunkExportResultFile.fulfilled,
      (state, { payload: data }) => {
        const href = URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          i18n.language === "ko"
            ? "메일 발송 내역 및 결과.xlsx"
            : "Sent Email Details And Result.xlsx"
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }
    );

    builder.addCase(
      trunkGetProgramDetail.fulfilled,
      (state, { payload: data }) => {
        state.configDetail = data.data;
      }
    );

    builder.addCase(
      trunkExportResultReport.fulfilled,
      (state, { payload: data }) => {
        const href = URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          i18n.language === "ko" ? "결과 보고서.pdf" : "Result Report.pdf"
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }
    );

    builder.addCase(trunkDeleteProgram.fulfilled, (state, resq) => {
      state.configListWithCount.list = state.configListWithCount.list.filter(
        (item) => item.configId !== resq.meta.arg
      );
      state.configListWithCount.total = state.configListWithCount.total - 1;
    });
    // builder.addCase(trunkUpdateProgram.fulfilled, (state, resq) => {
    //   state.configListWithCount.list = state.configListWithCount.list.map(
    //     (item) => {
    //       return item.configId === resq.meta.arg.configId
    //         ? {
    //             configId: resq.meta.arg.configId,
    //             configName:
    //               resq.payload.data.configName ??
    //               resq.meta.arg.configName ??
    //               item.configName,
    //             sendStartDate:
    //               resq.payload.data.sendStartDate ??
    //               resq.meta.arg.sendStartDate ??
    //               item.sendStartDate,
    //             sendEndDate:
    //               resq.payload.data.sendEndDate ??
    //               resq.meta.arg.sendEndDate ??
    //               item.sendEndDate,
    //             sendMailCount:
    //               resq.payload.data.sendMailCount ??
    //               resq.meta.arg.sendMailCount ??
    //               item.sendMailCount,
    //             userCount: item.userCount,
    //             templateCount:
    //               resq.payload.data.templateCount ?? item.templateCount,
    //             activateFlag:
    //               resq.payload.data.activateFlag ??
    //               resq.meta.arg.activateFlag ??
    //               item.activateFlag,
    //             list_entry_date:
    //               resq.payload.data.list_entry_date ?? item.list_entry_date,
    //             autoReplyFlag:
    //               resq.payload.data.autoReplyFlag ??
    //               resq.meta.arg.autoReplyFlag ??
    //               item.autoReplyFlag,
    //             startedFlag: resq.payload.data.startedFlag ?? item.startedFlag,
    //             sendStartTime:
    //               resq.payload.data.sendStartTime ??
    //               resq.meta.arg.sendStartTime ??
    //               item.sendStartTime,
    //             sendEndTime:
    //               resq.payload.data.sendEndTime ??
    //               resq.meta.arg.sendEndTime ??
    //               item.sendEndTime,
    //             realTrainingFlag:
    //               resq.payload.data.realTrainingFlag ??
    //               resq.meta.arg.realTrainingFlag ??
    //               item.realTrainingFlag,
    //             sendCount: resq.payload.data.sendCount ?? item.sendCount,
    //             sendCnt: resq.payload.data.sendCnt ?? item.sendCnt,
    //             totalCnt: resq.payload.data.totalCnt ?? item.totalCnt,
    //             userIds:
    //               resq.payload.data.userIds ??
    //               resq.meta.arg.userIds ??
    //               item.userIds,
    //             templateIds:
    //               resq.payload.data.templateIds ??
    //               resq.meta.arg.templateIds ??
    //               item.templateIds,
    //           }
    //         : item;
    //     }
    //   );
    // });
  },
});
export const programActions = programSlice.actions;

export default programSlice.reducer;
