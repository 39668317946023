import "./index.scss";
import {
  Box,
  Button,
  Divider,
  Icons,
  Modal,
  Radio,
  RadioGroup,
  ScrollBox,
  TextArea,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import { resolve } from "path";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QuizContentDTO } from "../../../types/Quiz";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
import { nanoid } from "@reduxjs/toolkit";
import { error } from "console";
interface AIModalProps {
  isOpen: boolean;
  onClose: () => void;
  setQuizContent: (quiz: QuizContentDTO) => void;
}

const AIPromptModal: React.FC<AIModalProps> = ({
  isOpen,
  setQuizContent,
  onClose,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const { createAiQuizContent } = useQuizStore();

  const [quizType, setQuizType] = useState<string>("1");
  const [newQuizInfo, changeNewQuizInfo] = useState({
    referText: "", // referText 참고 텍스트
    prompt: "", // 프롬프트
  });

  const [aiQuizExample, changeAIQuizExample] = useState<QuizContentDTO>({
    quizAnswer: 1, // 정답
    quizChoices: [] as string[], // 선택지
    quizBody: "", // 질문
    quizComments: "", // 해설
  });

  const onCreateAiQuiz = async () => {
    const resp: any = await createAiQuizContent({
      reference: newQuizInfo.referText,
      type: quizType === "1" ? 1 : 0,
      request: newQuizInfo.prompt,
      choices: quizType !== "1" ? 5 : null,
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      changeAIQuizExample(resp.payload.data);
    } else {
      toast.toastMsg(nanoid(), t("quiz.msg.aiCreateFail"), "error");
    }
  };

  const onSubmit = () => {
    setQuizContent({
      ...aiQuizExample,
      quizType:
        aiQuizExample.quizChoices && aiQuizExample.quizChoices.length > 0
          ? 2
          : 1,
    });

    onClose();
  };
  const footer = (
    <Box justifyContent="flex-end" alignItems="center">
      <Button color="secondary" onClick={onClose}>
        {t("common.button.cancel")}
      </Button>

      <Button
        classes="mg ml-8"
        onClick={onSubmit}
        disabled={!aiQuizExample.quizBody}
      >
        {t("common.button.apply")}
      </Button>
    </Box>
  );
  return (
    <Modal
      open={isOpen}
      title={t("quiz.label.aiCreate")}
      isDivider={false}
      footer={footer}
      width={540}
      // height={672}
      classes="aiPrompt"
    >
      <ScrollBox height={506} classes="aiPrompt-body">
        <Box direction="column" height={180}>
          <Box classes="mg mb-16">
            <Typography fontWeight={700}>{t("quiz.label.refText")}</Typography>
          </Box>
          <TextArea
            // width={484}
            height={154}
            rows={3}
            padding={8}
            minWidth={484}
            fullWidth={false}
            borderRadius={8}
            value={newQuizInfo.referText}
            onChange={(e) =>
              changeNewQuizInfo({ ...newQuizInfo, referText: e.target.value })
            }
            placeholder={t("quiz.msg.aiRefTextPlaceholder")}
            maxLength={100000}
          />
        </Box>

        <Box classes="mg mt-20" direction="column">
          <Box classes="mg mb-16">
            <Typography fontWeight={700}>{t("quiz.table.formal")}</Typography>
          </Box>
          <Box>
            <RadioGroup
              onChange={(e) => {
                setQuizType(e.target.value);
              }}
            >
              <Radio
                label={t("quiz.label.formatTF")}
                value="1"
                checked={quizType === "1"}
              />
              <Radio
                label={t("quiz.label.formatChoice")}
                value="2"
                checked={quizType === "2"}
              />
            </RadioGroup>
          </Box>
        </Box>
        <Box classes="prompt" direction="column">
          <Typography size={14} fontWeight={700} color={"secondary"}>
            {t("quiz.label.prompt")}
          </Typography>
          <TextArea
            height={120}
            rows={3}
            padding={8}
            minWidth={444}
            fullWidth={false}
            borderRadius={8}
            classes="mg mt-8"
            maxLength={2000}
            value={newQuizInfo.prompt}
            onChange={(e) =>
              changeNewQuizInfo({ ...newQuizInfo, prompt: e.target.value })
            }
            placeholder={t("quiz.msg.aiPromptPlaceholder")}
          />
        </Box>
        <Button
          fullWidth
          disabled={!newQuizInfo.prompt || !newQuizInfo.referText}
          startIcon={<Icons variant="common" label="plus" stroke="#FFFFFF" />}
          classes="mg mt-16"
          onClick={onCreateAiQuiz}
        >
          {t("quiz.label.aiGenerate")}
        </Button>
        {!!aiQuizExample.quizBody && (
          <Box direction="column" width={"100%"}>
            <Divider direction="row" classes="mg mt-20 mb-20" />
            <Typography size={14} fontWeight={700}>
              {t("quiz.label.aiPreview")}
            </Typography>
            <Box direction="column" classes="aiPrompt-quizAnswer mg mt-16">
              <Box alignItems="center">
                <Typography
                  classes="aiPrompt-question"
                  color={"secondary"}
                  size={14}
                  fontWeight={700}
                >
                  {t("quiz.label.question")}
                </Typography>
                <Typography classes="aiPrompt-answer" size={14}>
                  {aiQuizExample.quizBody}
                </Typography>
              </Box>
              {aiQuizExample.quizChoices &&
                aiQuizExample.quizChoices.length > 0 && (
                  <Box alignItems="flex-start">
                    <Typography
                      classes="aiPrompt-question"
                      color={"secondary"}
                      size={14}
                      fontWeight={700}
                    >
                      {t("quiz.label.choice")}
                    </Typography>
                    <Box direction="column" classes="aiPrompt-answer">
                      {aiQuizExample.quizChoices.map((choice, index) => (
                        <Box
                          alignItems="flex-start"
                          classes={
                            aiQuizExample.quizChoices &&
                            index !== aiQuizExample.quizChoices.length - 1
                              ? "mg mb-16"
                              : ""
                          }
                        >
                          <Typography
                            color={"secondary"}
                            fontWeight={700}
                            width={"48px"}
                            lineHeight="20px"
                          >
                            {index + 1}
                          </Typography>
                          <Typography size={14} width="calc(100% - 48px)">
                            {choice}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
              <Box alignItems="center">
                <Typography
                  classes="aiPrompt-question"
                  color={"secondary"}
                  size={14}
                  fontWeight={700}
                >
                  {t("quiz.label.answerSimple")}
                </Typography>
                <Typography classes="aiPrompt-answer" size={14}>
                  {aiQuizExample.quizChoices &&
                  aiQuizExample.quizAnswer !== undefined &&
                  aiQuizExample.quizChoices.length > 0
                    ? aiQuizExample.quizAnswer + 1
                    : aiQuizExample.quizAnswer === 1
                    ? t("quiz.label.choiceO")
                    : t("quiz.label.choiceX")}
                </Typography>
              </Box>
              <Box>
                <Typography
                  classes="aiPrompt-question"
                  color={"secondary"}
                  size={14}
                  fontWeight={700}
                >
                  {t("quiz.label.answerEx")}
                </Typography>
                <Typography classes="aiPrompt-answer" size={14}>
                  {aiQuizExample.quizComments}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </ScrollBox>
    </Modal>
  );
};

export default AIPromptModal;
