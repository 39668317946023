import { useMemo } from "react";
import { useAppDispatch, useRootState } from "./index";
import { bindActionCreators } from "redux";
import {
  trunkCreateAiScenario,
  trunkDeleteMailTemplate,
  trunkGetMailTemplateData,
  trunkGetMailTemplateInfo,
  trunkGetMailTemplateList,
  trunkGetUsedTemplate,
  trunkInsertMailTemplateList,
  trunkParseEMLFile,
  trunkUpdateMailTemplateList,
} from "../store/mailTemplate";
// # hooks
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useMailTemplateStore = () => {
  const mailTemplateState = useRootState((state) => state.mailTemplateStore);

  const dispatch = useAppDispatch();
  const boundGetMailTemplateList = useMemo(
    () => bindActionCreators(trunkGetMailTemplateList, dispatch),
    [dispatch]
  );
  const boundInsertMailTemplate = useMemo(
    () => bindActionCreators(trunkInsertMailTemplateList, dispatch),
    [dispatch]
  );
  const boundUpdateMailTemplate = useMemo(
    () => bindActionCreators(trunkUpdateMailTemplateList, dispatch),
    [dispatch]
  );
  const boundGetMailTemplateInfo = useMemo(
    () => bindActionCreators(trunkGetMailTemplateInfo, dispatch),
    [dispatch]
  );
  const boundGetUsedTemplate = useMemo(
    () => bindActionCreators(trunkGetUsedTemplate, dispatch),
    [dispatch]
  );
  const boundParseEMLFile = useMemo(
    () => bindActionCreators(trunkParseEMLFile, dispatch),
    [dispatch]
  );
  const boundGetMailTemplateData = useMemo(
    () => bindActionCreators(trunkGetMailTemplateData, dispatch),
    [dispatch]
  );
  const boundDeleteMailTemplate = useMemo(
    () => bindActionCreators(trunkDeleteMailTemplate, dispatch),
    [dispatch]
  );

  const boundCreateAiScenario = useMemo(
    () => bindActionCreators(trunkCreateAiScenario, dispatch),
    [dispatch]
  );

  // const boundSetUpdateTemplateId = useMemo(
  //   () => bindActionCreators(trunkSetUpdateTemplateId, dispatch),
  //   [dispatch]
  // );
  return {
    mailTemplateState,
    getMailTemplateList: boundGetMailTemplateList,
    getMailTemplateInfo: boundGetMailTemplateInfo,
    insertMailTemplate: boundInsertMailTemplate,
    updateMailTemplate: boundUpdateMailTemplate,
    getUsedTemplate: boundGetUsedTemplate,
    parseEMLFile: boundParseEMLFile,
    getMailTemplateData: boundGetMailTemplateData,
    deleteMailTemplate: boundDeleteMailTemplate,
    createAiScenario: boundCreateAiScenario,
    // setUpdateTemplateId: boundSetUpdateTemplateId,
  };
};

export default useMailTemplateStore;
