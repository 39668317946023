import config from "../../shared/config/config";
import axios from "../../shared/utils/axios";
import axiosBlob from "../../shared/utils/axiosBlob";
import {
  BaseResponseArrDTO,
  BaseResponseBean,
  BaseResponseDTO,
} from "../../types/Common";
import { CsvDeptType, DeptType } from "../../types/Dept";
import { OrganTreeType } from "../../types/Send";
import {
  DeleteUserResponse,
  SimpleDept,
  SimpleTreeNode,
  UserType,
} from "../../types/User";

const GATEWAY_URL = config.servers.gateway.url;

interface DeptApi {
  getDeptList(
    deptCodes?: string[]
  ): Promise<BaseResponseDTO<{ list: SimpleTreeNode[] }>>;
  getOrganUserList(deptCode?: string): Promise<BaseResponseArrDTO<UserType[]>>;
  getOrganUserCount(deptCode?: string): Promise<BaseResponseArrDTO<UserType[]>>;
  insertOrganUserList(user: UserType): Promise<BaseResponseDTO<SimpleDept>>;
  updateOrganUserList(user: UserType): Promise<BaseResponseDTO<UserType>>;
  deleteOrganUserList(
    userId: string[]
  ): Promise<BaseResponseDTO<DeleteUserResponse>>;
  getDeptListForUser(): Promise<OrganTreeType[]>;
  getDept(dept_code: string): Promise<DeptType>;
  updateDept(req: {
    deptCode: string;
    deptName: string;
    parentCode: string;
  }): Promise<BaseResponseBean<any>>;
  uploadCsv(formData: FormData): Promise<CsvDeptType>;
  checkBeforeUpdateUserCSV(formData: File): Promise<CsvDeptType>;
  checkBeforeUpdateDeptCSV(formData: File): Promise<CsvDeptType>;
  uploadUserCSV(formData: File): Promise<CsvDeptType>;
  uploadDeptCSV(formData: File): Promise<CsvDeptType>;
  insertCsv(req: {
    deptCodes: string;
    deptNames: string;
    parentCodes: string;
  }): Promise<BaseResponseBean<any>>;
  downloadDeptFile(): Promise<any>;
  addDept(req: {
    deptCode: string;
    deptName: string;
    parentCode: string;
  }): Promise<BaseResponseDTO<any>>;
  deleteDept(deptCode: string): Promise<BaseResponseDTO<any>>;
}

class DeptServerApi implements DeptApi {
  async getDeptList(
    deptCodes?: string[]
  ): Promise<BaseResponseDTO<{ list: SimpleTreeNode[] }>> {
    const res = await axios.post(`${GATEWAY_URL}/api/v2/organ/dept/list`, {
      deptCodes: deptCodes,
    });

    return Promise.resolve(res.data);
  }
  async getOrganUserList(
    deptCode?: string
  ): Promise<BaseResponseArrDTO<UserType[]>> {
    const res = await axios.post(`${GATEWAY_URL}/api/v2/organ/user/list`, {
      deptCode: deptCode,
    });

    return Promise.resolve(res.data);
  }
  async getOrganUserCount(
    deptCode?: string
  ): Promise<BaseResponseArrDTO<UserType[]>> {
    const res = await axios.post(`${GATEWAY_URL}/api/v2/organ/user/count`, {
      deptCode: deptCode,
    });

    return Promise.resolve(res.data);
  }
  async insertOrganUserList(
    user: UserType
  ): Promise<BaseResponseDTO<SimpleDept>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/organ/user/insert`,
      user
    );
    return Promise.resolve(res.data);
  }
  async updateOrganUserList(
    user: UserType
  ): Promise<BaseResponseDTO<UserType>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/organ/user/update`,
      user
    );
    return Promise.resolve(res.data);
  }
  async deleteOrganUserList(
    userId: string[]
  ): Promise<BaseResponseDTO<DeleteUserResponse>> {
    let api =
      `${GATEWAY_URL}/api/v2/organ/user/` +
      (userId.length === 1 ? "delete" : "deletes");
    let param =
      userId.length === 1 ? { userId: userId[0] } : { userIds: userId };
    const res = await axios.post(api, param);
    return Promise.resolve(res.data);
  }
  async getDeptListForUser(): Promise<OrganTreeType[]> {
    const res = await axios.post("/organ/getDeptListforUser");

    return Promise.resolve(res.data);
  }

  async getDept(dept_code: string): Promise<DeptType> {
    const res = await axios.get("/organ/getDept", {
      params: { dept_code },
    });

    return Promise.resolve(res.data);
  }

  async updateDept(req: {
    deptCode: string;
    deptName: string;
    parentCode: string;
  }): Promise<BaseResponseBean<any>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/organ/dept/update`,
      req
    );

    return Promise.resolve(res.data);
  }

  async uploadCsv(formData: FormData): Promise<CsvDeptType> {
    const res = await axios.post("/organ/uploadCsv", formData);

    return Promise.resolve(res.data);
  }
  async checkBeforeUpdateUserCSV(formData: File): Promise<CsvDeptType> {
    const returnData = new FormData();
    returnData.append("file", formData);

    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/organ/user/uploadCheck`,
      returnData,
      {
        headers: {
          "Content-Type": "charset=utf-8",
          Enctype: "enctype=multipart/form-data; charset=utf-8",
        },
      }
    );

    return Promise.resolve(res.data);
  }
  async checkBeforeUpdateDeptCSV(formData: File): Promise<CsvDeptType> {
    const returnData = new FormData();
    returnData.append("file", formData);

    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/organ/dept/uploadCheck`,
      returnData,
      {
        headers: {
          "Content-Type": "charset=utf-8",
          Enctype: "enctype=multipart/form-data; charset=utf-8",
        },
      }
    );

    return Promise.resolve(res.data);
  }
  async uploadUserCSV(formData: File): Promise<CsvDeptType> {
    const returnData = new FormData();
    returnData.append("file", formData);

    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/organ/user/uploadCsv`,
      returnData,
      {
        headers: {
          "Content-Type": "charset=utf-8",
          Enctype: "enctype=multipart/form-data; charset=utf-8",
        },
      }
    );

    return Promise.resolve(res.data);
  }
  async uploadDeptCSV(formData: File): Promise<CsvDeptType> {
    const returnData = new FormData();
    returnData.append("file", formData);

    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/organ/dept/uploadCsv`,
      returnData,
      {
        headers: {
          "Content-Type": "charset=utf-8",
          Enctype: "enctype=multipart/form-data; charset=utf-8",
        },
      }
    );

    return Promise.resolve(res.data);
  }
  async downloadDeptFile(): Promise<any> {
    const res = await axiosBlob.post(
      `${GATEWAY_URL}/api/v2/organ/deptuser/export`,
      null,
      {}
    );

    return Promise.resolve(res.data);
  }

  async insertCsv(req: {
    deptCodes: string;
    deptNames: string;
    parentCodes: string;
  }): Promise<BaseResponseBean<any>> {
    const res = await axios.post("/organ/insertCsv", null, { params: req });

    return Promise.resolve(res.data);
  }

  async addDept(req: {
    deptCode: string;
    deptName: string;
    parentCode: string;
  }): Promise<BaseResponseDTO<any>> {
    const res = await axios.post(
      `${GATEWAY_URL}/api/v2/organ/dept/insert`,
      req
    );

    return Promise.resolve(res.data);
  }

  async deleteDept(deptCode: string): Promise<BaseResponseDTO<any>> {
    const res = await axios.post(`${GATEWAY_URL}/api/v2/organ/dept/delete`, {
      deptCode: deptCode,
    });

    return Promise.resolve(res.data);
  }
}

const deptApi: DeptApi = new DeptServerApi();

export default deptApi;
