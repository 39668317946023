const QuizStatPer = ({
  classes,
  width,
}: {
  classes?: string;
  width?: string | number;
}) => {
  return (
    <svg
      width={width || 40}
      height={41}
      className={classes}
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M27.6094 3.63672H12.3906C7.20431 3.63672 3 7.84103 3 13.0273V28.2461C3 33.4324 7.20431 37.6367 12.3906 37.6367H27.6094C32.7957 37.6367 37 33.4324 37 28.2461V13.0273C37 7.84103 32.7957 3.63672 27.6094 3.63672Z"
        fill="#CBB9FF"
      />
      <path
        d="M27 20.6367H20V13.6367C20 13.0367 19.6 12.6367 19 12.6367C14 12.6367 10 16.6367 10 21.6367C10 26.6367 14 30.6367 19 30.6367C24 30.6367 28 26.6367 28 21.6367C28 21.0367 27.6 20.6367 27 20.6367ZM23 10.6367C22.4 10.6367 22 11.0367 22 11.6367V17.6367C22 18.2367 22.4 18.6367 23 18.6367H29C29.6 18.6367 30 18.2367 30 17.6367C30 13.7367 26.9 10.6367 23 10.6367ZM24 16.6367V12.7367C26 13.1367 27.5 14.6367 27.9 16.6367H24Z"
        fill="#725FEB"
      />
    </svg>
  );
};

export default QuizStatPer;
