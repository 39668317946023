import "./index.scss";
import {
  Box,
  Button,
  Checkbox,
  DropdownItem,
  IconButton,
  Icons,
  SettingButton,
  Table,
  TextField,
  ToolTip,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import TitlePath from "../../../component/TitlePath";
import {
  ConfigListType,
  TrainingResultReqType,
  TrainingResultType,
} from "../../../types/Send";
import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import useSendHistoryStore from "../../../redux/dispatcher/useSendHistoryStore";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import useProgramStore from "../../../redux/dispatcher/useProgramStore";
import { useTranslation } from "react-i18next";
import useMailTemplateStore from "../../../redux/dispatcher/useMailTemplateStore";
import { Scenario } from "../../../types/Scenario";
import MailBody from "../../../component/MailBody";
import utilsCommon from "../../../shared/utils/common";
import {
  FixedSizeList,
  FixedSizeListProps,
  ListOnItemsRenderedProps,
} from "react-window";
import { getLocalCustomerCode } from "../../../shared/utils/auth";
import useCustomerStore from "../../../redux/dispatcher/useCustomerStore";
import { CustomerInfoBean } from "../../../types/Info";
import CommonModal from "../../../component/CommonModal";
import { nanoid } from "@reduxjs/toolkit";
import CategoryTag from "../../../component/CategoryTag";

import InfiniteLoader from "react-window-infinite-loader";

type OnItemsRendered = (props: ListOnItemsRenderedProps) => any;
interface InfiniteLoaderProps {
  isItemLoaded: (index: number) => boolean;
  loadMoreItems: (
    startIndex: number,
    stopIndex: number
  ) => Promise<void> | void;
  itemCount: number;
  children: (props: {
    onItemsRendered: OnItemsRendered;
    ref: (ref: any) => void;
  }) => ReactNode;
  threshold?: number | undefined;
  minimumBatchSize?: number | undefined;
}

const InfiniteLoaderComponent =
  InfiniteLoader as unknown as React.ComponentType<InfiniteLoaderProps>;

const FixedSizeListComponent = FixedSizeList as unknown as React.ComponentType<
  FixedSizeListProps<any>
>;

interface filterType {
  id: number;
  text: string;
}
export interface TrainResultFilterProps {
  configName: string;
  clickTypes: number[];
  sendResult: filterType;
  callResult: filterType;
  completionType: filterType;
  deptName: string;
  userSearchParam: string;
  configId: number | null;
  configNames: string[];
  userSearchParams: string[];
  deptNames: string[];
}
const ManageTrainResult: React.FC = () => {
  const PAGING_SIZE = 100;
  const { t, i18n } = useTranslation();

  const {
    programState,
    getProgramList,
    exportResultFile,
    getProgramDetail,
    exportResultReport,
  } = useProgramStore();
  const { sendHistoryState, getTrainingResult, resendQuizSendHistory } =
    useSendHistoryStore();
  const { mailTemplateState, getMailTemplateData } = useMailTemplateStore();
  const [isResendModalOpen, setIsResendModalOpen] = useState<number>(-1);
  const [historyList, setHistoryList] = useState<TrainingResultType[]>([]);
  const [historyListTotal, setHistoryListTotal] = useState<number>(0);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [buttonClick, setButtonClick] = useState(0);

  const [filter, setFilter] = useState<TrainResultFilterProps>({
    configName: "",
    clickTypes: [],
    sendResult: { id: 0, text: t("common.auth.all") },
    callResult: { id: 0, text: t("common.auth.all") },
    completionType: { id: 0, text: t("common.auth.all") },
    deptName: "",
    userSearchParam: "",
    configId: null,
    configNames: [],
    userSearchParams: [],
    deptNames: [],
  });
  const [copyFilter, changeCopyFilter] = useState<
    TrainingResultReqType | undefined
  >();
  const clickTypes = [
    {
      id: 0,
      text: t("common.auth.all"),
    },

    {
      id: 1,
      text: t("template.mailOpen"),
    },
    {
      id: 2,
      text: t("template.linkOpen"),
    },
    {
      id: 3,
      text: t("template.personalInfo"),
    },
    {
      id: 4,
      text: t("dashboard.attachOpen"),
    },
    {
      id: 5,
      text: t("common.info.noData"),
    },
  ];

  const sendResults = [
    { id: 0, text: t("common.auth.all") },
    { id: 1, text: t("program.programSendWait") },
    { id: 2, text: t("program.programSendSuccess") },
    { id: 3, text: t("program.programSendFail") },
  ];
  const callResults = [
    { id: 0, text: t("common.auth.all") },
    { id: 1, text: t("dashboard.report") },
    { id: 2, text: t("dashboard.dashboardUnreport") },
  ];

  const completionTypes = [
    { id: 0, text: t("common.auth.all") },
    { id: 1, text: "-" },
    { id: 2, text: t("quiz.label.notRequired") },
    { id: 3, text: t("quiz.label.notStarted") },
    { id: 4, text: t("quiz.label.completed") },
  ];

  const [searchUserName, setSearchUserName] = useState<string>("");
  const [searchDeptName, setSearchDeptName] = useState<string>("");
  const [searchConfigName, setSearchConfigName] = useState<string>("");

  const [searchUserNames, setSearchUserNames] = useState<string[]>([]);
  const [searchDeptNames, setSearchDeptNames] = useState<string[]>([]);
  const [searchConfigNames, setSearchConfigNames] = useState<string[]>([]);

  const filterTypeCheckbox = useCheckboxGroup();
  const [templateHtml, setTemplateHtml] = useState<Scenario | null>();
  const templateRef = useRef<boolean>(false);

  const [hasData, setHasData] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);

  const toast = useToast();

  const onResendMail = async () => {
    if (isResendModalOpen !== -1) {
      const resp: any = await resendQuizSendHistory({
        size: 0,
        sendHistoryId: isResendModalOpen,
        cpCode: getLocalCustomerCode(),
      });

      if (resp.payload && resp.payload.code === "SUCCESS") {
        toast.toastMsg(nanoid(), t("quiz.msg.resendSuccess"), "success");
        let tempFilter: any = {
          // size: 200,
          size: PAGING_SIZE,
          deptName: filter.deptName ?? undefined,
          historyResult:
            filter.sendResult.id === 0 ? null : filter.sendResult.id,
          callResult:
            filter.callResult.id === 0
              ? null
              : filter.callResult.id === 1
              ? true
              : false,
          configName: filter.configName,
          userSearchParam: filter.userSearchParam,
          configId: filter.configId,
          click1: null,
          click2: null,
          click3: null,
          click4: null,
          intervalTime: utilsCommon.getIntervalTime(),
          configNames: filter.configNames,
          userSearchParams: filter.userSearchParams,
          deptNames: filter.deptNames,
        };

        if (
          filter?.clickTypes.length > 0 &&
          !(
            filter?.clickTypes?.includes(1) &&
            filter?.clickTypes?.includes(2) &&
            filter?.clickTypes?.includes(3) &&
            filter?.clickTypes?.includes(4) &&
            filter?.clickTypes?.includes(5)
          ) &&
          !filter?.clickTypes?.includes(0)
        ) {
          if (filter?.clickTypes?.includes(1)) {
            tempFilter = { ...tempFilter, click1: true };
          } else if (!filter?.clickTypes?.indexOf(1)) {
            tempFilter = { ...tempFilter, click1: false };
          }
          if (filter?.clickTypes?.includes(2)) {
            tempFilter = { ...tempFilter, click2: true };
          } else if (!filter?.clickTypes?.indexOf(2)) {
            tempFilter = { ...tempFilter, click2: false };
          }
          if (filter?.clickTypes?.includes(3)) {
            tempFilter = { ...tempFilter, click3: true };
          } else if (!filter?.clickTypes?.indexOf(3)) {
            tempFilter = { ...tempFilter, click3: false };
          }
          if (filter?.clickTypes?.includes(4)) {
            tempFilter = { ...tempFilter, click4: true };
          } else if (!filter?.clickTypes?.indexOf(4)) {
            tempFilter = { ...tempFilter, click4: false };
          }

          if (filter?.clickTypes?.includes(5)) {
            tempFilter = { ...tempFilter, notClicked: true };
          } else if (!filter?.clickTypes?.indexOf(4)) {
            tempFilter = { ...tempFilter, notClicked: false };
          }
        }

        if (
          buttonClick > 0 &&
          filter?.clickTypes.length === 1 &&
          filter?.clickTypes?.includes(5)
        ) {
          tempFilter = { ...tempFilter, click1: false };
          tempFilter = { ...tempFilter, click2: false };
          tempFilter = { ...tempFilter, click3: false };
          tempFilter = { ...tempFilter, click4: false };
        } else if (
          buttonClick > 0 &&
          filter?.clickTypes?.includes(1) &&
          filter?.clickTypes?.includes(2) &&
          filter?.clickTypes?.includes(3) &&
          filter?.clickTypes?.includes(4) &&
          !filter?.clickTypes?.includes(5)
        ) {
          tempFilter = { ...tempFilter, click1: true };
          tempFilter = { ...tempFilter, click2: true };
          tempFilter = { ...tempFilter, click3: true };
          tempFilter = { ...tempFilter, click4: true };
        }
        loadHistory(tempFilter);
        setIsResendModalOpen(-1);
      } else {
        toast.toastMsg(nanoid(), t("quiz.msg.resendFail"), "error");
        setIsResendModalOpen(-1);
      }
    }
  };

  const onHoverTemplate = async (templateId: number) => {
    if (!templateRef.current) {
      const resp: any = await getMailTemplateData(templateId);

      if (resp.payload && resp.payload.code === "SUCCESS") {
        setTemplateHtml(resp.payload.data);
      } else {
        setTemplateHtml(null);
      }
      templateRef.current = true;
    }
  };
  useEffect(() => {
    // setConfigList(DUMMY_TRAIN_LIST);
    getProgramList({
      orderId: "configId",
      orderType: "desc",
      startedFlag: true,
    });
  }, []);

  useEffect(() => {
    const urlFilter = window.sessionStorage.getItem("resultFilter");
    let name = undefined;
    let callResult = undefined;
    let configId = undefined;
    if (urlFilter) {
      const splitUrlFilter = urlFilter.split("&");
      name = splitUrlFilter[1]?.split("=")[1];
      configId =
        splitUrlFilter[0]?.split("=")[1] &&
        parseInt(splitUrlFilter[0]?.split("=")[1]) > 0
          ? splitUrlFilter[0]?.split("=")[1]
          : null;
      if (urlFilter.includes("callResult")) {
        callResult = false;
      } else {
        callResult = null;
      }
      setFilter({
        ...filter,
        userSearchParam: name,
        configId: configId ? parseInt(configId) : null,
        callResult:
          callResult === false
            ? { id: 2, text: t("dashboard.dashboardUnreport") }
            : {
                id: 0,
                text: t("common.auth.all"),
              },
      });
    }
    window.sessionStorage.removeItem("resultFilter");
    filterTypeCheckbox.handleAllClick([1, 2, 3, 4, 5]);
  }, [window.location.href]);

  useEffect(() => {
    if (!hasData && sendHistoryState.total > 0) {
      setHasData(true);
    }
    // setHistoryList(sendHistoryState.sendHistory);
    setHistoryListTotal(sendHistoryState.total);
  }, [sendHistoryState.sendHistory]);

  useEffect(() => {
    setHasNextPage(true);
    let tempFilter: any = {
      // size: 200,
      size: PAGING_SIZE,
      deptName: filter.deptName ?? undefined,
      historyResult: filter.sendResult.id === 0 ? null : filter.sendResult.id,
      completionType:
        filter.completionType.id === 0 ? null : filter.completionType.id,
      callResult:
        filter.callResult.id === 0
          ? null
          : filter.callResult.id === 1
          ? true
          : false,
      configName: filter.configName,
      userSearchParam: filter.userSearchParam,
      configId: filter.configId,
      click1: null,
      click2: null,
      click3: null,
      click4: null,
      intervalTime: utilsCommon.getIntervalTime(),
      configNames: filter.configNames,
      userSearchParams: filter.userSearchParams,
      deptNames: filter.deptNames,
    };

    if (
      filter?.clickTypes.length > 0 &&
      !(
        filter?.clickTypes?.includes(1) &&
        filter?.clickTypes?.includes(2) &&
        filter?.clickTypes?.includes(3) &&
        filter?.clickTypes?.includes(4) &&
        filter?.clickTypes?.includes(5)
      ) &&
      !filter?.clickTypes?.includes(0)
    ) {
      if (filter?.clickTypes?.includes(1)) {
        tempFilter = { ...tempFilter, click1: true };
      } else if (!filter?.clickTypes?.indexOf(1)) {
        tempFilter = { ...tempFilter, click1: false };
      }
      if (filter?.clickTypes?.includes(2)) {
        tempFilter = { ...tempFilter, click2: true };
      } else if (!filter?.clickTypes?.indexOf(2)) {
        tempFilter = { ...tempFilter, click2: false };
      }
      if (filter?.clickTypes?.includes(3)) {
        tempFilter = { ...tempFilter, click3: true };
      } else if (!filter?.clickTypes?.indexOf(3)) {
        tempFilter = { ...tempFilter, click3: false };
      }
      if (filter?.clickTypes?.includes(4)) {
        tempFilter = { ...tempFilter, click4: true };
      } else if (!filter?.clickTypes?.indexOf(4)) {
        tempFilter = { ...tempFilter, click4: false };
      }

      if (filter?.clickTypes?.includes(5)) {
        tempFilter = { ...tempFilter, notClicked: true };
      } else if (!filter?.clickTypes?.indexOf(4)) {
        tempFilter = { ...tempFilter, notClicked: false };
      }
    }

    if (
      buttonClick > 0 &&
      filter?.clickTypes.length === 1 &&
      filter?.clickTypes?.includes(5)
    ) {
      tempFilter = { ...tempFilter, click1: false };
      tempFilter = { ...tempFilter, click2: false };
      tempFilter = { ...tempFilter, click3: false };
      tempFilter = { ...tempFilter, click4: false };
    } else if (
      buttonClick > 0 &&
      filter?.clickTypes?.includes(1) &&
      filter?.clickTypes?.includes(2) &&
      filter?.clickTypes?.includes(3) &&
      filter?.clickTypes?.includes(4) &&
      !filter?.clickTypes?.includes(5)
    ) {
      tempFilter = { ...tempFilter, click1: true };
      tempFilter = { ...tempFilter, click2: true };
      tempFilter = { ...tempFilter, click3: true };
      tempFilter = { ...tempFilter, click4: true };
    }

    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);

    !window.sessionStorage.getItem("resultFilter") && loadHistory(tempFilter);
    changeCopyFilter(tempFilter);
  }, [filter]);

  const loadHistory = async (tempFilter: any) => {
    const resp: any = await getTrainingResult(tempFilter);
    setHistoryList(resp.payload.data.list);
  };

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      sendResult: {
        ...prev.sendResult,
        text: sendResults.filter((c) => c.id === prev.sendResult.id)[0].text,
      },
      callResult: {
        ...prev.callResult,
        text: callResults.filter((c) => c.id === prev.callResult.id)[0].text,
      },
    }));
  }, [i18n.language]);

  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useLayoutEffect(() => {
    const tableContainer = document.querySelector(
      ".table-scroll"
    ) as HTMLElement;

    if (tableContainer) {
      const scrollbarWidth =
        tableContainer.offsetWidth - tableContainer.clientWidth;
      setScrollbarWidth(scrollbarWidth);
    }
  }, [historyList]);

  const isItemLoaded = (index: number): boolean => {
    return index < historyList.length && historyList[index] !== undefined;
  };

  const loadMoreItems = async () => {
    if (isNextPageLoading) return;

    setIsNextPageLoading(true);

    let tempFilter: any = {
      deptName: filter.deptName ?? undefined,
      callResult:
        filter.callResult.id === 0
          ? null
          : filter.callResult.id === 1
          ? true
          : false,
      historyResult: filter.sendResult.id === 0 ? null : filter.sendResult.id,
      completionType:
        filter.completionType.id === 0 ? null : filter.completionType.id,
      configName: filter.configName,
      userSearchParam: filter.userSearchParam,
      configId: filter.configId,
      click1: null,
      click2: null,
      click3: null,
      click4: null,
      intervalTime: utilsCommon.getIntervalTime(),
      configNames: filter.configNames,
      userSearchParams: filter.userSearchParams,
      deptNames: filter.deptNames,
    };

    if (
      filter?.clickTypes.length > 0 &&
      !(
        filter?.clickTypes?.includes(1) &&
        filter?.clickTypes?.includes(2) &&
        filter?.clickTypes?.includes(3) &&
        filter?.clickTypes?.includes(4) &&
        filter?.clickTypes?.includes(5)
      ) &&
      !filter?.clickTypes?.includes(0)
    ) {
      if (filter?.clickTypes?.includes(1)) {
        tempFilter = { ...tempFilter, click1: true };
      } else if (!filter?.clickTypes?.indexOf(1)) {
        tempFilter = { ...tempFilter, click1: false };
      }
      if (filter?.clickTypes?.includes(2)) {
        tempFilter = { ...tempFilter, click2: true };
      } else if (!filter?.clickTypes?.indexOf(2)) {
        tempFilter = { ...tempFilter, click2: false };
      }
      if (filter?.clickTypes?.includes(3)) {
        tempFilter = { ...tempFilter, click3: true };
      } else if (!filter?.clickTypes?.indexOf(3)) {
        tempFilter = { ...tempFilter, click3: false };
      }
      if (filter?.clickTypes?.includes(4)) {
        tempFilter = { ...tempFilter, click4: true };
      } else if (!filter?.clickTypes?.indexOf(4)) {
        tempFilter = { ...tempFilter, click4: false };
      }

      if (filter?.clickTypes?.includes(5)) {
        tempFilter = { ...tempFilter, notClicked: true };
      } else if (!filter?.clickTypes?.indexOf(4)) {
        tempFilter = { ...tempFilter, notClicked: false };
      }
    }

    if (
      buttonClick > 0 &&
      filter?.clickTypes.length === 1 &&
      filter?.clickTypes?.includes(5)
    ) {
      tempFilter = { ...tempFilter, click1: false };
      tempFilter = { ...tempFilter, click2: false };
      tempFilter = { ...tempFilter, click3: false };
      tempFilter = { ...tempFilter, click4: false };
    } else if (
      buttonClick > 0 &&
      filter?.clickTypes?.includes(1) &&
      filter?.clickTypes?.includes(2) &&
      filter?.clickTypes?.includes(3) &&
      filter?.clickTypes?.includes(4) &&
      !filter?.clickTypes?.includes(5)
    ) {
      tempFilter = { ...tempFilter, click1: true };
      tempFilter = { ...tempFilter, click2: true };
      tempFilter = { ...tempFilter, click3: true };
      tempFilter = { ...tempFilter, click4: true };
    }

    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);

    try {
      const resp: any =
        !window.sessionStorage.getItem("resultFilter") &&
        (await getTrainingResult({
          ...tempFilter,
          size: PAGING_SIZE,
          cursor: historyList[historyList.length - 1].cursor,
        }));

      if (resp && resp.payload && resp.payload.data && resp.payload.data.list) {
        setHistoryList((prev) => {
          return [...prev, ...resp.payload.data.list];
        });

        if (resp.payload.data.list.length < PAGING_SIZE) {
          setHasNextPage(false);
        }
      }
    } catch (error) {
      console.error("Failed to load more items:", error);
    } finally {
      setIsNextPageLoading(false);
    }

    changeCopyFilter(tempFilter);
  };

  const [tableHeight, setTableHeight] = useState(660);

  useEffect(() => {
    const handleResize = () => {
      const tableElement = document.querySelector(
        ".table-scroll"
      ) as HTMLElement;
      const tempTableHeight = tableElement ? tableElement.offsetHeight : 0;
      tableElement && setTableHeight(tempTableHeight);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const htmlRef = useRef<HTMLDivElement>(null);
  const { customerState } = useCustomerStore();

  const exportToReport = async () => {
    if (filter.configId) {
      const customerCode = getLocalCustomerCode();

      if (customerCode) {
        const resp: any = await exportResultReport({
          ...copyFilter,
          size: 0,
          customerName: customerState.companyList.filter(
            (item: CustomerInfoBean) => item.customerCode
          )[0].companyName,
        });
      }
    }
  };

  return (
    <Box classes="main manageTrainResult">
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath
            path={[
              t("menu.trainingManagement"),
              t("menu.trainingSendAndResult"),
            ]}
          />
          <Typography
            classes="mg mt-8"
            fontWeight={700}
            size={24}
            lineHeight="32px"
          >
            {t("menu.trainingSendAndResult")}
          </Typography>
        </Box>
        <Box>
          {filter.configId && (
            <Button
              startIcon={
                <Icons variant="file" label="download" stroke="white" />
              }
              onClick={exportToReport}
              classes="mg mr-8"
            >
              {t("program.exportToReport")}
            </Button>
          )}
          {hasData && (
            <Button
              startIcon={
                <Icons variant="file" label="download" stroke="white" />
              }
              onClick={() => exportResultFile({ ...copyFilter, size: 0 })}
            >
              {t("common.button.exportToExcel")}
            </Button>
          )}
        </Box>
      </Box>
      <Box classes="mg mt-8" justifyContent="space-between">
        {hasData && (
          <>
            <Box alignItems="center">
              <Typography fontWeight={700} size={16}>
                {t("program.sendMail")}
              </Typography>
              <Typography
                classes="mg ml-8"
                size={16}
                color="green"
                fontWeight={700}
              >
                {historyListTotal?.toLocaleString()}
              </Typography>
            </Box>

            <Box>
              <Button
                startIcon={
                  <Icons variant="common" label="filter" stroke="#252d38" />
                }
                color="transparent"
                onClick={() => {
                  setIsFilterOpen((prev) => !prev);
                }}
              >
                {t("common.list.filter")}
              </Button>
            </Box>
          </>
        )}
      </Box>

      {/* filter start */}
      {isFilterOpen && (
        <Box classes="mg mt-12" alignItems="center">
          <SettingButton
            buttonClick={buttonClick}
            button={
              <Button
                color={filter.configId === null ? "tertiary" : "tertiary-click"}
              >
                {t("program.configRound")} ·{" "}
                {filter.configId === null
                  ? t("common.auth.all")
                  : t("common.list.numOrder", { round: filter.configId })}
              </Button>
            }
            classes="mg mr-8"
            listPosition={"right"}
            height={300}
          >
            <DropdownItem
              isChecked={filter.configId === null}
              onClick={() => {
                setFilter({ ...filter, configId: null });
              }}
              key={0}
            >
              {filter.configId === null ? (
                <Icons variant="common" label="check" stroke="#3182f6" />
              ) : (
                <Box width={28} />
              )}
              {t("common.auth.all")}
            </DropdownItem>
            {programState.configListWithCount?.list?.map(
              (item: ConfigListType) => (
                <DropdownItem
                  isChecked={item.configId === filter.configId}
                  onClick={() => {
                    setFilter({ ...filter, configId: item.configId });
                  }}
                  key={item.configId}
                >
                  {item.configId === filter.configId ? (
                    <Icons variant="common" label="check" stroke="#3182f6" />
                  ) : (
                    <Box width={28} />
                  )}
                  {t("common.list.numOrder", { round: item.configId })}
                </DropdownItem>
              )
            )}
          </SettingButton>

          {/* userSearchParam  */}

          <SettingButton
            buttonClick={buttonClick}
            button={
              <Button
                color={
                  filter.userSearchParams === undefined ||
                  filter.userSearchParams.length === 0
                    ? "tertiary"
                    : "tertiary-click"
                }
                classes="mg mr-8"
              >
                {t("program.trainingUser")}
                {filter.userSearchParams === undefined ||
                filter.userSearchParams.length === 0
                  ? " · " + t("quiz.label.all")
                  : ` · ${filter.userSearchParams.length}`}
              </Button>
            }
            listPosition="right"
          >
            <Box
              alignItems="center"
              classes="setting-button-list-search"
              height={40}
            >
              <TextField
                width={200}
                startLabel={
                  <Box justifyContent="center" alignItems="center" height={30}>
                    <Icons variant="mindsat" label="icon_search" width={20} />
                  </Box>
                }
                isSearch
                borderNone
                placeholder={t("user.msg.userUndefinedNameMsg")}
                text={searchUserName}
                hasText
                onChange={(e) => setSearchUserName(e.target.value)}
                onKeyPress={(e: any) => {
                  if (e.key === "Enter") {
                    if (e.target.value === "") return;
                    if (
                      searchUserNames &&
                      searchUserNames.includes(e.target.value)
                    ) {
                      return;
                    }
                    setSearchUserNames((prev) => [...prev, searchUserName]);
                    setSearchUserName("");
                  }
                }}
              />
            </Box>
            <Box classes="filter__tag__container">
              {searchUserNames &&
                searchUserNames.map((item) => (
                  <CategoryTag
                    key={item}
                    text={item}
                    endIcon={<Icons variant="common" label="delete" />}
                    onClick={() =>
                      setSearchUserNames((prev) =>
                        prev.filter((q) => q !== item)
                      )
                    }
                  />
                ))}
            </Box>
            <Box
              alignItems="center"
              classes="setting-button-list-footer"
              justifyContent="flex-end"
            >
              <Box>
                <Button
                  text={t("common.button.cancel")}
                  color="secondary"
                  onClick={() => {
                    setSearchUserName("");
                    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                  }}
                />
                <Button
                  text={t("common.button.apply")}
                  classes={"mg ml-8"}
                  color="primary"
                  onClick={() => {
                    if (searchUserName !== "") {
                      setSearchUserNames((prev) => [...prev, searchUserName]);
                      setFilter((prev) => ({
                        ...prev,
                        userSearchParams: [...searchUserNames, searchUserName],
                      }));
                    } else {
                      setFilter((prev) => ({
                        ...prev,
                        userSearchParams: searchUserNames,
                      }));
                    }
                    setSearchUserName("");
                  }}
                />
              </Box>
            </Box>
          </SettingButton>

          {/* deptName  */}
          <SettingButton
            buttonClick={buttonClick}
            button={
              <Button
                color={
                  filter.deptNames === undefined ||
                  filter.deptNames.length === 0
                    ? "tertiary"
                    : "tertiary-click"
                }
                classes="mg mr-8"
              >
                {t("quiz.table.depts")}
                {filter.deptNames === undefined || filter.deptNames.length === 0
                  ? " · " + t("quiz.label.all")
                  : ` · ${filter.deptNames.length}`}
              </Button>
            }
            listPosition="right"
          >
            <Box
              alignItems="center"
              classes="setting-button-list-search"
              height={40}
            >
              <TextField
                width={200}
                startLabel={
                  <Box justifyContent="center" alignItems="center" height={30}>
                    <Icons variant="mindsat" label="icon_search" width={20} />
                  </Box>
                }
                isSearch
                borderNone
                placeholder={t("dept.msg.undefinedNameMsg")}
                text={searchDeptName}
                hasText
                onChange={(e) => setSearchDeptName(e.target.value)}
                onKeyPress={(e: any) => {
                  if (e.key === "Enter") {
                    if (e.target.value === "") return;
                    if (
                      searchDeptNames &&
                      searchDeptNames.includes(e.target.value)
                    ) {
                      return;
                    }
                    setSearchDeptNames((prev) => [...prev, searchDeptName]);
                    setSearchDeptName("");
                  }
                }}
              />
            </Box>
            <Box classes="filter__tag__container">
              {searchDeptNames &&
                searchDeptNames.map((item) => (
                  <CategoryTag
                    key={item}
                    text={item}
                    endIcon={<Icons variant="common" label="delete" />}
                    onClick={() =>
                      setSearchDeptNames((prev) =>
                        prev.filter((q) => q !== item)
                      )
                    }
                  />
                ))}
            </Box>
            <Box
              alignItems="center"
              classes="setting-button-list-footer"
              justifyContent="flex-end"
            >
              <Box>
                <Button
                  text={t("common.button.cancel")}
                  color="secondary"
                  onClick={() => {
                    setSearchDeptName("");
                    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                  }}
                />
                <Button
                  text={t("common.button.apply")}
                  classes={"mg ml-8"}
                  color="primary"
                  onClick={() => {
                    // setSearchDeptName("");

                    // setFilter((prev) => ({
                    //   ...prev,
                    //   deptNames: searchDeptNames,
                    // }));

                    if (searchDeptName !== "") {
                      setSearchDeptNames((prev) => [...prev, searchDeptName]);
                      setFilter((prev) => ({
                        ...prev,
                        deptNames: [...searchDeptNames, searchDeptName],
                      }));
                    } else {
                      setFilter((prev) => ({
                        ...prev,
                        deptNames: searchDeptNames,
                      }));
                    }
                    setSearchDeptName("");
                  }}
                />
              </Box>
            </Box>
          </SettingButton>

          {/* configName */}

          <SettingButton
            buttonClick={buttonClick}
            button={
              <Button
                color={
                  filter.configNames === undefined ||
                  filter.configNames.length === 0
                    ? "tertiary"
                    : "tertiary-click"
                }
                classes="mg mr-8"
              >
                {t("program.programName")}
                {filter.configNames === undefined ||
                filter.configNames.length === 0
                  ? " · " + t("quiz.label.all")
                  : ` · ${filter.configNames.length}`}
              </Button>
            }
            listPosition="right"
          >
            <Box
              alignItems="center"
              classes="setting-button-list-search"
              height={40}
            >
              <TextField
                width={200}
                startLabel={
                  <Box justifyContent="center" alignItems="center" height={30}>
                    <Icons variant="mindsat" label="icon_search" width={20} />
                  </Box>
                }
                isSearch
                borderNone
                placeholder={t("program.msg.programUndefinedNameMsg")}
                text={searchConfigName}
                hasText
                onChange={(e) => setSearchConfigName(e.target.value)}
                onKeyPress={(e: any) => {
                  if (e.key === "Enter") {
                    if (e.target.value === "") return;
                    if (
                      searchConfigNames &&
                      searchConfigNames.includes(e.target.value)
                    ) {
                      return;
                    }
                    setSearchConfigNames((prev) => [...prev, searchConfigName]);
                    setSearchConfigName("");
                  }
                }}
              />
            </Box>
            <Box classes="filter__tag__container">
              {searchConfigNames &&
                searchConfigNames.map((item) => (
                  <CategoryTag
                    key={item}
                    text={item}
                    endIcon={<Icons variant="common" label="delete" />}
                    onClick={() =>
                      setSearchConfigNames((prev) =>
                        prev.filter((q) => q !== item)
                      )
                    }
                  />
                ))}
            </Box>
            <Box
              alignItems="center"
              classes="setting-button-list-footer"
              justifyContent="flex-end"
            >
              <Box>
                <Button
                  text={t("common.button.cancel")}
                  color="secondary"
                  onClick={() => {
                    setSearchConfigName("");
                    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                  }}
                />
                <Button
                  text={t("common.button.apply")}
                  classes={"mg ml-8"}
                  color="primary"
                  onClick={() => {
                    if (searchConfigName !== "") {
                      setSearchConfigNames((prev) => [
                        ...prev,
                        searchConfigName,
                      ]);
                      setFilter((prev) => ({
                        ...prev,
                        configNames: [...searchConfigNames, searchConfigName],
                      }));
                    } else {
                      setFilter((prev) => ({
                        ...prev,
                        configNames: searchConfigNames,
                      }));
                    }
                    setSearchConfigName("");
                  }}
                />
              </Box>
            </Box>
          </SettingButton>

          <SettingButton
            buttonClick={buttonClick}
            button={
              <Button
                color={
                  filter.sendResult.id === 0 ? "tertiary" : "tertiary-click"
                }
                classes="mg mr-8"
              >
                {t("program.programSendResult")} ·{filter.sendResult.text}
              </Button>
            }
            listPosition="right"
          >
            {sendResults.map((item) => (
              <DropdownItem
                isChecked={item.id === filter.sendResult.id}
                onClick={() =>
                  setFilter((prev) => {
                    return { ...prev, sendResult: item };
                  })
                }
              >
                {item.id === filter.sendResult.id ? (
                  <Icons variant="common" label="check" stroke="#3182f6" />
                ) : (
                  <Box width={28} />
                )}
                {item.text}
              </DropdownItem>
            ))}
          </SettingButton>

          <SettingButton
            buttonClick={buttonClick}
            button={
              <Button
                color={
                  filter.clickTypes?.length === 0 ||
                  filter.clickTypes?.length === 5
                    ? "tertiary"
                    : "tertiary-click"
                }
                classes="mg mr-8"
              >
                {t("program.result")} ·
                {filter.clickTypes?.length === 0 ||
                filter.clickTypes.length === 5
                  ? t("common.auth.all")
                  : filter.clickTypes.length === 1
                  ? clickTypes.filter(
                      (item) => item.id === filter.clickTypes[0]
                    )[0].text
                  : clickTypes.filter(
                      (item) => item.id === filter.clickTypes[0]
                    )[0].text +
                    " " +
                    t("common.msg.moreCnt", {
                      length: filter.clickTypes?.length - 1,
                    })}
              </Button>
            }
            listPosition="right"
          >
            {clickTypes.map((item) => (
              <Box
                alignItems="center"
                classes="setting-button-list-body-item"
                height={40}
                key={item.id}
              >
                <Checkbox
                  onChange={() => {
                    if (item.id === 0) {
                      if (filterTypeCheckbox.selected.length === 5) {
                        filterTypeCheckbox.handleAllClick([]);
                      } else {
                        filterTypeCheckbox.handleAllClick([1, 2, 3, 4, 5]);
                      }
                      return;
                    } else {
                      filterTypeCheckbox.handleClick(item.id);
                    }
                  }}
                  checked={
                    item.id === 0
                      ? filterTypeCheckbox.selected.length === 5
                      : filterTypeCheckbox.isSelected(item.id)
                  }
                />
                <Typography classes="mg ml-8">{item.text}</Typography>
              </Box>
            ))}
            <Box
              alignItems="center"
              classes="setting-button-list-footer"
              justifyContent="flex-end"
            >
              <Box>
                <Button
                  text={t("common.button.cancel")}
                  color="secondary"
                  onClick={() => {
                    let click = [] as number[];
                    filter.clickTypes.forEach((item) => {
                      if (item === 0) {
                        click.push(1);
                        click.push(2);
                        click.push(3);
                        click.push(4);
                        click.push(5);
                      } else if (item === 1) {
                        click.push(1);
                      } else if (item === 2) {
                        click.push(2);
                      } else if (item === 3) {
                        click.push(3);
                      } else if (item === 4) {
                        click.push(4);
                      } else if (item === 5) {
                        click.push(5);
                      }
                    });

                    filterTypeCheckbox.handleAllClick(
                      click?.length > 0 ? click : [1, 2, 3, 4, 5]
                    );
                    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                  }}
                />
                <Button
                  text={t("common.button.apply")}
                  classes={"mg ml-8"}
                  color="primary"
                  onClick={() => {
                    setFilter((prev) => {
                      return {
                        ...prev,
                        clickTypes: filterTypeCheckbox.selected as number[],
                      };
                    });
                  }}
                  disabled={filterTypeCheckbox.selected.length === 0}
                />
              </Box>
            </Box>
          </SettingButton>

          <SettingButton
            buttonClick={buttonClick}
            button={
              <Button
                color={
                  filter.callResult.id === 0 ? "tertiary" : "tertiary-click"
                }
                classes="mg mr-8"
              >
                {t("program.programCallResult")} · {filter.callResult.text}
              </Button>
            }
            listPosition="right"
          >
            {callResults.map((item) => (
              <DropdownItem
                isChecked={item.id === filter.callResult.id}
                onClick={() =>
                  setFilter((prev) => {
                    return { ...prev, callResult: item };
                  })
                }
              >
                {item.id === filter.callResult.id ? (
                  <Icons variant="common" label="check" stroke="#3182f6" />
                ) : (
                  <Box width={28} />
                )}
                {item.text}
              </DropdownItem>
            ))}
          </SettingButton>

          {/* completion Type  */}
          <SettingButton
            buttonClick={buttonClick}
            button={
              <Button
                color={
                  filter.completionType.id === 0 ? "tertiary" : "tertiary-click"
                }
                classes="mg mr-8"
              >
                {t("quiz.table.learning")} · {filter.completionType.text}
              </Button>
            }
            listPosition="right"
          >
            {completionTypes.map((item) => (
              <DropdownItem
                isChecked={item.id === filter.completionType.id}
                onClick={() =>
                  setFilter((prev) => {
                    return { ...prev, completionType: item };
                  })
                }
              >
                {item.id === filter.completionType.id ? (
                  <Icons variant="common" label="check" stroke="#3182f6" />
                ) : (
                  <Box width={28} />
                )}
                {item.text}
              </DropdownItem>
            ))}
          </SettingButton>
          {/* completion Type end */}
          <IconButton
            transparent
            onClick={() => {
              setFilter({
                configName: "",
                clickTypes: [],
                sendResult: { id: 0, text: t("common.auth.all") },
                callResult: { id: 0, text: t("common.auth.all") },
                completionType: { id: 0, text: t("common.auth.all") },
                deptName: "",
                userSearchParam: "",
                configId: null,
                configNames: [],
                deptNames: [],
                userSearchParams: [],
              });
              setSearchConfigNames([]);
              setSearchDeptNames([]);
              setSearchUserNames([]);
            }}
            classes="mg ml-8"
          >
            <Icons variant="common" label="refresh" stroke="#B5BBC2" />
          </IconButton>
        </Box>
      )}

      {/* filter end */}

      {/* table start */}
      {!hasData ? (
        <Box direction="column" alignItems="center">
          <img
            src={require("../../../shared/image/logo/NoTrainResult.png")}
            alt={"noTrain"}
            width={360}
            height={200}
            className="mg mt-48"
            style={{ marginTop: "160px" }}
          />
          <Typography classes="mg mt-24" size={16} color={"secondary"}>
            {t("program.msg.noSendMailInfoMsg")}
          </Typography>
        </Box>
      ) : (
        <Box
          direction="column"
          height={isFilterOpen ? "calc(100% - 170px)" : "calc(100% - 110px)"}
        >
          <Table classes="default-table mg mt-12">
            <colgroup>
              {/* 회차  */}
              <col style={{ width: "76px" }}></col>
              {/* 대상자 이름 */}
              <col style={{ width: "250px" }}></col>
              {/* 대상자 이름*/}
              <col style={{ width: "120px" }}></col>
              {/* 훈련 이름 */}
              <col style={{ width: "100%" }}></col>
              {/* 메일 회차 */}
              <col style={{ width: "80px" }}></col>
              {/* 시나리오 */}
              <col style={{ width: "160px" }}></col>
              {/* 발송 일시 */}
              <col style={{ width: "160px" }}></col>
              {/* 발송 현황 */}
              <col style={{ width: "80px" }}></col>
              {/* 훈련 탐지행위  */}
              <col style={{ width: "120px" }}></col>
              {/* 신고 여부 */}
              <col
                style={{ width: i18n.language === "ko" ? "80px" : "96px" }}
              ></col>
              {/* 학습 여부  */}
              <col
                style={{
                  width:
                    i18n.language === "ko"
                      ? `calc(80px + ${scrollbarWidth}px)`
                      : `calc(96px + ${scrollbarWidth}px)`,
                }}
              ></col>
            </colgroup>
            <thead>
              <tr>
                <th>{t("program.configRound")}</th>
                <th>{t("program.trainingUser")}</th>
                <th>{t("dept.deptLong")}</th>
                <th>{t("program.programName")}</th>
                <th>{t("program.sendRound")}</th>
                <th>{t("template.templateSimple")}</th>
                <th>{t("common.time.sendDate")}</th>
                <th>{t("program.programSendResult")}</th>
                <th>
                  <Box>
                    <Typography color="secondary">
                      {t("program.result")}
                    </Typography>
                    <ToolTip
                      message={
                        <Box direction="column">
                          <Box>
                            <Icons
                              variant="common"
                              label="mail"
                              stroke="#FFFFFF"
                              width={16}
                              height={16}
                              classes="mg mr-8"
                            />
                            {t("template.mailOpen")}
                          </Box>
                          <Box>
                            <Icons
                              variant="file"
                              label="copyLink"
                              stroke="#FFFFFF"
                              width={16}
                              height={16}
                              classes="mg mr-8"
                            />
                            {t("template.linkOpen")}
                          </Box>
                          <Box>
                            <Icons
                              variant="mindsat"
                              label="icon_edit2"
                              stroke="#FFFFFF"
                              width={16}
                              height={16}
                              classes="mg mr-8"
                            />
                            {t("template.personalInfo")}
                          </Box>
                          <Box>
                            <Icons
                              variant="mindsat"
                              label="icon_clip"
                              stroke="#FFFFFF"
                              width={16}
                              height={16}
                              classes="mg mr-8"
                            />
                            {t("dashboard.attachOpen")}
                          </Box>
                        </Box>
                      }
                    >
                      <Icons
                        variant="sideMenu"
                        label="info"
                        stroke="#717985"
                        classes="mg ml-8"
                        width={16}
                      />
                    </ToolTip>
                  </Box>
                </th>
                <th>{t("program.programCallResult")}</th>
                <th>{t("quiz.table.learning")}</th>
              </tr>
            </thead>
          </Table>
          {historyList.length === 0 ? (
            <Box direction="column" alignItems="center">
              <img
                src={require("../../../shared/image/logo/NoSearch.png")}
                alt={"noFilter"}
                width={200}
                height={120}
                className="mg mt-48"
                style={{ marginTop: "160px" }}
              />
              <Typography classes="mg mt-16" size={16} color={"secondary"}>
                {t("program.msg.noFilterResultMsg")}
              </Typography>
              <Button
                onClick={() =>
                  setFilter({
                    configName: "",
                    clickTypes: [],
                    sendResult: { id: 0, text: t("common.auth.all") },
                    callResult: { id: 0, text: t("common.auth.all") },
                    completionType: { id: 0, text: t("common.auth.all") },
                    deptName: "",
                    userSearchParam: "",
                    configId: null,
                    configNames: [],
                    deptNames: [],
                    userSearchParams: [],
                  })
                }
                classes="mg mt-16"
                startIcon={
                  <Icons variant="common" label="refresh" stroke="white" />
                }
              >
                {t("common.button.resetFilter")}
              </Button>
            </Box>
          ) : (
            <Box classes="table-scroll">
              <Table>
                {/* <tbody> */}
                <InfiniteLoaderComponent
                  isItemLoaded={isItemLoaded}
                  itemCount={
                    hasNextPage ? historyList.length + 1 : historyList.length
                  }
                  loadMoreItems={loadMoreItems}
                >
                  {({ onItemsRendered, ref }) => (
                    <FixedSizeListComponent
                      itemCount={historyList.length}
                      itemSize={54}
                      width="100%"
                      height={isFilterOpen ? tableHeight - 60 : tableHeight}
                      className={
                        i18n.language === "ko"
                          ? `result__scroll__table table-root`
                          : `result__scroll__table en table-root`
                      }
                      style={{ width: "100%" }}
                      onItemsRendered={onItemsRendered}
                      // ref={ref}
                    >
                      {({ style, index }) => {
                        const item = historyList[index];
                        return isItemLoaded(index) ? (
                          <tr key={item.sendHistoryId} style={style}>
                            <td>{item.configId}</td>
                            <td>
                              {item.userName} ({item.userEmail})
                            </td>
                            <td>{item.deptName}</td>
                            <td>
                              {item.quizIds && item.quizIds !== ""
                                ? `[${t("quiz.table.trainingQuiz")}] ${
                                    item.configName
                                  }`
                                : item.configName}
                            </td>
                            <td>{`${item.mailRank} / ${item.mailTotal}`}</td>
                            <td
                              onMouseEnter={() =>
                                onHoverTemplate(item.templateId)
                              }
                              onMouseLeave={() => (templateRef.current = false)}
                            >
                              {templateHtml ? (
                                <ToolTip
                                  message={
                                    <>
                                      <Box classes="mg mb-8">
                                        <Typography color="light">
                                          {item.templateName}
                                        </Typography>
                                      </Box>
                                      <MailBody
                                        mailWidth={240}
                                        height={200}
                                        allowScroll={false}
                                        body={
                                          templateHtml.imagePath
                                            ? templateHtml.templateHtml.replaceAll(
                                                "##IMAGE##",
                                                templateHtml.imagePath
                                              )
                                            : templateHtml.templateHtml
                                        }
                                        id={index}
                                        width="300px"
                                      />
                                    </>
                                  }
                                  width={"100%"}
                                >
                                  <Typography
                                    lineHeight="normal"
                                    ellipsis
                                    as={"p"}
                                    width={"90%"}
                                    // maxWidth={"115px"}
                                  >
                                    {item.templateName}
                                  </Typography>
                                </ToolTip>
                              ) : (
                                <ToolTip
                                  message={
                                    <Typography color="light">
                                      {t("template.deletedScenario")}
                                    </Typography>
                                  }
                                  width={"100%"}
                                  classes="scenario__tooltip"
                                >
                                  <Typography
                                    lineHeight="normal"
                                    ellipsis
                                    as={"p"}
                                    width={"90%"}
                                  >
                                    {item.templateName}
                                  </Typography>
                                </ToolTip>
                              )}
                            </td>
                            <td>
                              {utilsCommon.getFullDateStr(item.sendingDate)}
                            </td>
                            <td>
                              {item.historyResult === 1 ? (
                                // <ToolTip
                                //   message={utilsCommon.getFullDateStr(
                                //     item.sendingDate
                                //   )}
                                // >
                                <Box classes="dot__container">
                                  <Box classes="dot yellow__dot" />
                                  <Typography>
                                    {t("program.programSendWait")}
                                  </Typography>
                                </Box>
                              ) : // </ToolTip>
                              item.historyResult === 2 ? (
                                // <ToolTip
                                //   message={utilsCommon.getFullDateStr(
                                //     item.sendEntryDate
                                //   )}
                                // >
                                <Box classes="dot__container">
                                  <Box classes="dot green__dot" />
                                  <Typography>
                                    {t("program.programSendSuccess")}
                                  </Typography>
                                </Box>
                              ) : (
                                // </ToolTip>
                                // <ToolTip
                                //   message={utilsCommon.getFullDateStr(
                                //     item.sendingDate
                                //   )}
                                // >
                                <Box classes="dot__container">
                                  <Box classes="dot red__dot" />
                                  <Typography>
                                    {t("program.programSendFail")}
                                  </Typography>
                                </Box>
                                // </ToolTip>
                              )}
                            </td>

                            <td>
                              <Box alignItems="center">
                                {item.click1 === 1 && (
                                  <Icons
                                    variant="common"
                                    label="mail"
                                    stroke="#252d38"
                                    width={16}
                                    height={16}
                                    classes="mg mr-8"
                                  />
                                )}
                                {item.click2 === 1 && (
                                  <Icons
                                    variant="file"
                                    label="copyLink"
                                    stroke="#252d38"
                                    width={16}
                                    height={16}
                                    classes="mg mr-8"
                                  />
                                )}
                                {item.click3 === 1 && (
                                  <Icons
                                    variant="mindsat"
                                    label="icon_edit2"
                                    stroke="#252d38"
                                    width={16}
                                    height={16}
                                    classes="mg mr-8"
                                  />
                                )}
                                {item.click4 === 1 && (
                                  <Icons
                                    variant="mindsat"
                                    label="icon_clip"
                                    stroke="#252d38"
                                    width={16}
                                    height={16}
                                    classes="mg mr-8"
                                  />
                                )}

                                {item.click1 === 0 &&
                                  item.click2 === 0 &&
                                  item.click3 === 0 &&
                                  item.click4 === 0 &&
                                  "-"}
                              </Box>
                            </td>
                            <td>
                              {item.historyResult === 2 ? (
                                item.callResult ? (
                                  <Box classes="dot__container">
                                    <Box classes="dot blue__dot" />
                                    <Typography>
                                      {t("dashboard.report")}
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box classes="dot__container">
                                    <Box classes="dot secondary__dot" />
                                    <Typography>
                                      {t("dashboard.dashboardUnreport")}
                                    </Typography>
                                  </Box>
                                )
                              ) : (
                                <Box classes="dot__container">
                                  <Typography>-</Typography>
                                </Box>
                              )}
                            </td>
                            <td>
                              {/* {item.quizIds !== "" &&
                              item.historyResult === 2 ? (
                                item.completionFlag ? (
                                  // dhksfy
                                  <Box classes="dot__container">
                                    <Box classes="dot blue__dot" />
                                    <Typography>
                                      {t("quiz.label.completed")}
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box alignItems="center">
                                    <Box
                                      classes={
                                        item.resendFlag
                                          ? `dot__container resend__container`
                                          : `dot__container`
                                      }
                                    >
                                      <Box classes="dot gray__dot" />
                                      <Typography classes="resend__text">
                                        {t("quiz.label.notStarted")}
                                      </Typography>
                                      <Box
                                        classes="resend__button"
                                        onClick={() =>
                                          setIsResendModalOpen(
                                            item.sendHistoryId
                                          )
                                        }
                                      >
                                        {t("quiz.label.resend")}
                                      </Box>
                                    </Box>
                                  </Box>
                                )
                              ) : (
                                "-"
                              )} */}
                              {item.completionType === 1 ? (
                                "-"
                              ) : item.completionType === 2 ? (
                                `${t("quiz.label.notRequired")}`
                              ) : item.completionType === 3 ? (
                                <Box alignItems="center">
                                  <Box
                                    classes={
                                      item.resendFlag
                                        ? `dot__container resend__container`
                                        : `dot__container`
                                    }
                                  >
                                    <Box
                                      classes="dot gray__dot"
                                      // onClick={() =>
                                      //   setIsResendModalOpen(item.sendHistoryId)
                                      // }
                                    />
                                    <Typography classes="resend__text">
                                      {t("quiz.label.notStarted")}
                                    </Typography>
                                    <Box
                                      classes="resend__button"
                                      onClick={() =>
                                        setIsResendModalOpen(item.sendHistoryId)
                                      }
                                    >
                                      {t("quiz.label.resend")}
                                    </Box>
                                  </Box>
                                </Box>
                              ) : (
                                <Box classes="dot__container">
                                  <Box classes="dot blue__dot" />
                                  <Typography>
                                    {t("quiz.label.completed")}
                                  </Typography>
                                </Box>
                              )}
                            </td>
                          </tr>
                        ) : (
                          <tr>loading...</tr>
                        );
                      }}
                    </FixedSizeListComponent>
                  )}
                  {/* {historyList?.map((item, index) => (
                    <tr key={item.sendHistoryId}>
                      <td>{item.configId}</td>
                      <td>
                        {item.userName} ({item.userEmail})
                      </td>
                      <td>{item.deptName}</td>
                      <td>{item.configName}</td>
                      <td>{`${item.mailRank} / ${item.mailTotal}`}</td>
                      <td
                        onMouseEnter={() => onHoverTemplate(item.templateId)}
                        onMouseLeave={() => (templateRef.current = false)}
                        className="scenario__tooltip"
                      >
                        <Box width={"100%"}>
                          {templateHtml ? (
                            <>
                              <ToolTip
                                // backgroundColor="white"
                                message={
                                  <>
                                    <Box classes="mg mb-8">
                                      <Typography color="light">
                                        {item.templateName}
                                      </Typography>
                                    </Box>
                                    <MailBody
                                      mailWidth={240}
                                      height={200}
                                      allowScroll={false}
                                      body={
                                        templateHtml.imagePath
                                          ? templateHtml.templateHtml.replaceAll(
                                              "##IMAGE##",
                                              templateHtml.imagePath
                                            )
                                          : templateHtml.templateHtml
                                      }
                                      id={index}
                                      width="300px"
                                    />
                                  </>
                                }
                                width={"100%"}
                              >
                                <Typography
                                  lineHeight="normal"
                                  ellipsis
                                  as={"p"}
                                  width={"90%"}
                                  // maxWidth={"115px"}
                                >
                                  {item.templateName}
                                </Typography>
                              </ToolTip>
                            </>
                          ) : (
                            <ToolTip
                              message={
                                <Typography color="light">
                                  {t("template.deletedScenario")}
                                </Typography>
                              }
                              width={"100%"}
                            >
                              <Typography
                                lineHeight="normal"
                                ellipsis
                                as={"p"}
                                width={"90%"}
                              >
                                {item.templateName}
                              </Typography>
                            </ToolTip>
                          )}
                        </Box>
                      </td>
                      <td>
                        {item.historyResult === 1 ? (
                          <ToolTip
                            message={utilsCommon.getFullDateStr(
                              item.sendingDate
                            )}
                          >
                            <Box classes="dot__container">
                              <Box classes="dot yellow__dot" />
                              <Typography>
                                {t("program.programSendWait")}
                              </Typography>
                            </Box>
                          </ToolTip>
                        ) : item.historyResult === 2 ? (
                          <ToolTip
                            message={utilsCommon.getFullDateStr(
                              item.sendEntryDate
                            )}
                          >
                            <Box classes="dot__container">
                              <Box classes="dot green__dot" />
                              <Typography>
                                {t("program.programSendSuccess")}
                              </Typography>
                            </Box>
                          </ToolTip>
                        ) : (
                          <ToolTip
                            message={utilsCommon.getFullDateStr(
                              item.sendingDate
                            )}
                          >
                            <Box classes="dot__container">
                              <Box classes="dot red__dot" />
                              <Typography>
                                {t("program.programSendFail")}
                              </Typography>
                            </Box>
                          </ToolTip>
                        )}
                      </td>
                      <td>
                        <Box alignItems="center">
                          {item.click1 === 1 && (
                            <Icons
                              variant="common"
                              label="mail"
                              stroke="#252d38"
                              width={16}
                              height={16}
                              classes="mg mr-8"
                            />
                          )}
                          {item.click2 === 1 && (
                            <Icons
                              variant="file"
                              label="copyLink"
                              stroke="#252d38"
                              width={16}
                              height={16}
                              classes="mg mr-8"
                            />
                          )}
                          {item.click3 === 1 && (
                            <Icons
                              variant="mindsat"
                              label="icon_edit2"
                              stroke="#252d38"
                              width={16}
                              height={16}
                              classes="mg mr-8"
                            />
                          )}
                          {item.click4 === 1 && (
                            <Icons
                              variant="mindsat"
                              label="icon_clip"
                              stroke="#252d38"
                              width={16}
                              height={16}
                              classes="mg mr-8"
                            />
                          )}

                          {item.click1 === 0 &&
                            item.click2 === 0 &&
                            item.click3 === 0 &&
                            item.click4 === 0 &&
                            "-"}
                        </Box>
                      </td>
                      <td>
                        {item.historyResult === 2 ? (
                          item.callResult ? (
                            <Box classes="dot__container">
                              <Box classes="dot blue__dot" />
                              <Typography>{t("dashboard.report")}</Typography>
                            </Box>
                          ) : (
                            <Box classes="dot__container">
                              <Box classes="dot secondary__dot" />
                              <Typography>
                                {t("dashboard.dashboardUnreport")}
                              </Typography>
                            </Box>
                          )
                        ) : (
                          <Box classes="dot__container">
                            <Typography>-</Typography>
                          </Box>
                        )}
                      </td>
                    </tr>
                  ))} */}
                  {/* </tbody> */}
                </InfiniteLoaderComponent>
              </Table>
            </Box>
          )}
        </Box>
      )}
      {/* table end */}
      {isResendModalOpen !== -1 && (
        <CommonModal
          open={isResendModalOpen !== -1}
          title={t("quiz.label.resendMail")}
          subMessage={t("quiz.msg.resendConfirm")}
          onClose={() => setIsResendModalOpen(-1)}
          afterSubmitButton={onResendMail}
          footer={
            <Box justifyContent="flex-end">
              <Button
                text={t("common.button.cancel")}
                color="secondary"
                onClick={() => setIsResendModalOpen(-1)}
              />

              <Button
                text={t("quiz.label.resend")}
                classes={"mg ml-8"}
                color="primary"
                onClick={onResendMail}
              />
            </Box>
          }
        />
      )}
    </Box>
  );
};

export default ManageTrainResult;
