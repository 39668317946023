import {
  Box,
  Button,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import { useEffect, useState } from "react";
import useIpStore from "../../../redux/dispatcher/useIpStore";
import { IpFilterType } from "../../../types/Ip";
import { nanoid } from "@reduxjs/toolkit";
import { use } from "i18next";
import utilsCommon from "../../../shared/utils/common";
import { useTranslation } from "react-i18next";
interface InsertUpdateIPModalProps {
  isOpen: boolean;
  tab: number;
  modalType: string;
  targetIPId: number;
  onClose: () => void;
}

const InsertUpdateIPModal: React.FC<InsertUpdateIPModalProps> = ({
  isOpen,
  modalType,
  tab,
  targetIPId,
  onClose,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { ipState, insertIp, updateIp, getIpList } = useIpStore();
  const [ipInfo, changeIpInfo] = useState({
    ipType: tab === 0 ? "W" : "B",
    ipAddress: "",
    createUser: "admin",
    summary: "",
    ipId: undefined,
  });
  const [error, changeErr] = useState("");

  useEffect(() => {
    if (targetIPId === -1) return;
    changeIpInfo(
      ipState.ipList.filter((item: IpFilterType) => item.ipId === targetIPId)[0]
    );
  }, [ipState.ipList, targetIPId]);
  const handleInsertOrUpdateIp = async () => {
    if (!utilsCommon.ipValidation(ipInfo.ipAddress)) {
      changeErr("INVALID_IP");
      return;
    }
    let checkDup = ipState.ipList.filter(
      (item: IpFilterType) =>
        item.ipAddress === ipInfo.ipAddress && item.ipType === ipInfo.ipType
    );
    if (checkDup.length > 0 && checkDup[0].ipId !== ipInfo.ipId) {
      toast.toastMsg(nanoid(), t("log.msg.logDuplicateMsg"), "error");
      return;
    }
    let resp: any =
      modalType === "CREATE" ? await insertIp(ipInfo) : await updateIp(ipInfo);
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        modalType === "CREATE"
          ? t("log.msg.logAddSuccessMsg")
          : t("log.msg.logUpdateSuccsessMsg"),
        "success"
      );
      getIpList();
      onClose();
    } else {
      toast.toastMsg(
        nanoid(),
        modalType === "CREATE"
          ? t("log.msg.commonCreateIpFailMsg")
          : t("log.msg.commonUploadIpFailMsg"),
        "error"
      );
    }
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={modalType === "CREATE" ? t("log.add") : t("log.update")}
      footer={
        <Box justifyContent="flex-end">
          <Button color={"secondary"} onClick={onClose}>
            {t("common.button.cancel")}
          </Button>
          <Button
            classes="mg ml-8"
            onClick={handleInsertOrUpdateIp}
            disabled={ipInfo.ipAddress.trim() === ""}
          >
            {t("common.button.save")}
          </Button>
        </Box>
      }
      isDivider={false}
      width={536}
    >
      <Box direction="column" width={"100%"}>
        <Typography size={14} isBold>
          {t("common.list.type")}
        </Typography>
        <Typography color={"secondary"} size={12} classes="mg mt-12">
          {t("log.msg.logGuideMsg")}
        </Typography>
        <RadioGroup
          classes="mg mt-12"
          onChange={(e) => changeIpInfo({ ...ipInfo, ipType: e.target.value })}
        >
          <Radio
            label={t("log.whiteList")}
            value={"W"}
            checked={ipInfo.ipType === "W"}
          />
          <Radio
            label={t("log.blackList")}
            value={"B"}
            checked={ipInfo.ipType === "B"}
          />
        </RadioGroup>
        <Typography classes="mg mt-20" isBold>
          IP
        </Typography>
        <TextField
          size={"lg"}
          fullWidth
          classes="mg mt-12"
          onChange={(e) =>
            changeIpInfo({ ...ipInfo, ipAddress: e.target.value })
          }
          placeholder={t("log.msg.logIpPlaceholderMsg")}
          text={ipInfo.ipAddress}
          hasText
          maxLength={15}
          error={error.includes("IP")}
          errorMsg={t("log.msg.commonInvalidIpMsg")}
        />
        <Typography classes="mg mt-20" isBold>
          {t("log.description")}
        </Typography>
        <TextField
          size={"lg"}
          fullWidth
          maxLength={255}
          classes="mg mt-12"
          placeholder={t("log.msg.logDescPlaceholderMsg")}
          onChange={(e) => changeIpInfo({ ...ipInfo, summary: e.target.value })}
          text={ipInfo.summary}
          hasText
        />
      </Box>
    </Modal>
  );
};

export default InsertUpdateIPModal;
