import {
  Box,
  Button,
  FormArea,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import { SimpleTreeNode, UserType } from "../../types/User";
import { useEffect, useState } from "react";
import useDeptStore from "../../redux/dispatcher/useDeptStore";
import { nanoid } from "@reduxjs/toolkit";
import utilsCommon from "../../shared/utils/common";
import { SelectItem } from "fasoo-ui-component-next/src/components/atom/Select";
import { useTranslation } from "react-i18next";

interface CreateUpdateUserModalProps {
  isOpen: boolean;
  targetDept?: SimpleTreeNode;
  updateUserId?: string;
  onClose: () => void;
}

const CreateUpdateUserModal: React.FC<CreateUpdateUserModalProps> = ({
  isOpen,
  targetDept,
  updateUserId,
  onClose,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const {
    deptState,
    insertOrganUserList,
    updateOrganUserList,
    getOrganUserList,
  } = useDeptStore();

  const [tempUserInfo, changeTempUserInfo] = useState({
    userId: "",
    userName: "",
    userEmail: "",
    deptCode: targetDept?.deptCode,
    jobTitle: "",
    managerFlag: false,
  } as UserType);
  const [errorInput, changeErrorInput] = useState({
    type: "",
    message: "",
  });
  useEffect(() => {
    if (!updateUserId) return;
    changeTempUserInfo(
      deptState.userListInDept?.filter(
        (item: UserType) => item.userId === updateUserId
      )[0]
    );
  }, [deptState.userListInDept]);
  useEffect(() => {
    if (!deptState.userListInDept && !!updateUserId)
      getOrganUserList(targetDept?.deptCode);
  });
  const handeRegisterNewUser = async () => {
    if (!utilsCommon.userNameValidation(tempUserInfo.userName)) {
      changeErrorInput({
        type: "name",
        message: t("user.msg.nameErrorMsg"),
      });
      return;
    } else {
      changeErrorInput({
        type: "name",
        message: "",
      });
    }
    if (!utilsCommon.emailValidation(tempUserInfo.userEmail)) {
      changeErrorInput({
        type: "email",
        message: t("user.msg.userInvalidEmailMsg"),
      });
      return;
    } else {
      changeErrorInput({
        type: "email",
        message: "",
      });
    }
    let resp: any = updateUserId
      ? await updateOrganUserList(tempUserInfo)
      : await insertOrganUserList(tempUserInfo);
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        updateUserId
          ? t("user.msg.userUpdateSuccessMsg")
          : t("user.msg.userAddSuccessMsg"),
        "success"
      );
      getOrganUserList(targetDept?.deptCode);
      onClose();
    } else if (resp.payload && resp.payload.code === "DATA_DUPLICATE") {
      toast.toastMsg(nanoid(), t("account.msg.joinExistsEmailMsg"), "error");
    } else {
      toast.toastMsg(
        nanoid(),
        updateUserId
          ? t("user.msg.userUpdateFailMsg")
          : t("user.msg.userAddFailMsg"),
        "error"
      );
    }
  };
  return (
    <Modal
      open={isOpen}
      title={!updateUserId ? t("user.commonUserAdd") : t("user.commonUserEdit")}
      isDivider={false}
      footer={
        <Box width={"100%"} justifyContent="flex-end">
          <Button color={"secondary"} onClick={onClose}>
            {t("common.button.cancel")}
          </Button>
          <Button
            classes="mg ml-8"
            onClick={handeRegisterNewUser}
            disabled={
              !tempUserInfo.userEmail?.trim() ||
              !tempUserInfo.deptCode ||
              !tempUserInfo.userName?.trim()
            }
          >
            {!updateUserId ? t("common.button.save") : t("common.button.save")}
          </Button>
        </Box>
      }
      onClose={onClose}
    >
      <Box direction="column" width={"100%"}>
        <Box>
          <Typography size={14} fontWeight={700} lineHeight="20px">
            {t("user.userName")}
          </Typography>
          <Typography
            size={14}
            fontWeight={700}
            lineHeight="20px"
            classes="mg ml-4"
            color={"red"}
          >
            *
          </Typography>
        </Box>
        <TextField
          placeholder={t("user.msg.userUndefinedNameMsg")}
          size={"lg"}
          classes="mg mt-12"
          text={tempUserInfo.userName}
          hasText
          maxLength={60}
          onChange={(e) =>
            changeTempUserInfo({
              ...tempUserInfo,
              userName: e.target.value,
            })
          }
          error={errorInput.type === "name"}
          errorMsg={errorInput.message}
        />
        <Box classes="mg mt-28">
          <Box direction="column" width={"50%"}>
            <Box>
              <Typography size={14} fontWeight={700} lineHeight="20px">
                {t("dept.dept")}
              </Typography>
              <Typography
                size={14}
                fontWeight={700}
                lineHeight="20px"
                classes="mg ml-4"
                color={"red"}
              >
                *
              </Typography>
            </Box>
            <Select
              selectedEventKey={tempUserInfo?.deptCode}
              classes="mg mt-12"
              onClick={(e) =>
                changeTempUserInfo({
                  ...tempUserInfo,
                  deptCode: e,
                })
              }
              isWidthFull
              height={40}
            >
              {deptState.organList
                ?.filter((item1: SimpleTreeNode) => item1.type === "dept")
                ?.map((item: SimpleTreeNode) => (
                  <SelectItem
                    eventKey={item.deptCode}
                    key={"dept-" + item.deptCode}
                  >
                    {item.deptName}
                  </SelectItem>
                ))}
            </Select>
          </Box>
          <Box direction="column" width={"50%"} classes="mg ml-24">
            <Box>
              <Typography size={14} fontWeight={700} lineHeight="20px">
                {t("dept.managerFlag")}
              </Typography>
              <Typography
                size={14}
                fontWeight={700}
                lineHeight="20px"
                classes="mg ml-4"
                color={"red"}
              >
                *
              </Typography>
            </Box>
            <RadioGroup
              classes="mg mt-23"
              onChange={(e) =>
                changeTempUserInfo({
                  ...tempUserInfo,
                  managerFlag: e.target.value === "true",
                })
              }
            >
              <Radio
                label={t("common.button.yes")}
                value={"true"}
                checked={tempUserInfo.managerFlag}
              />
              <Radio
                label={t("common.button.no")}
                value={"false"}
                checked={!tempUserInfo.managerFlag}
              />
            </RadioGroup>
          </Box>
        </Box>
        <Box classes="mg mt-28">
          <Box direction="column" width={"50%"}>
            <Typography size={14} fontWeight={700} lineHeight="20px">
              {t("user.userPosition")}
            </Typography>
            <TextField
              placeholder={t("user.msg.userPositionPlaceholder")}
              size={"lg"}
              classes="mg mt-12"
              text={tempUserInfo.jobTitle ?? ""}
              hasText
              onChange={(e) =>
                changeTempUserInfo({
                  ...tempUserInfo,
                  jobTitle: e.target.value,
                })
              }
              maxLength={30}
            />
          </Box>
          <Box direction="column" width={"50%"} classes="mg ml-24">
            <Box>
              <Typography size={14} fontWeight={700} lineHeight="20px">
                {t("user.userEmail")}
              </Typography>
              <Typography
                size={14}
                fontWeight={700}
                lineHeight="20px"
                classes="mg ml-4"
                color={"red"}
              >
                *
              </Typography>
            </Box>
            <TextField
              placeholder={t("user.msg.userUndefinedEmailMsg")}
              size={"lg"}
              classes="mg mt-12"
              error={errorInput.type === "email"}
              errorMsg={errorInput.message}
              text={tempUserInfo.userEmail ?? ""}
              hasText
              onChange={(e) => {
                if (utilsCommon.emailValidation(tempUserInfo.userEmail)) {
                  changeErrorInput({
                    type: "",
                    message: "",
                  });
                }
                changeTempUserInfo({
                  ...tempUserInfo,
                  userEmail: e.target.value,
                });
              }}
            />
          </Box>
        </Box>
        <Typography
          size={14}
          fontWeight={700}
          lineHeight="20px"
          classes="mg mt-28"
        >
          {t("user.userMemo")}
        </Typography>
        <FormArea
          placeholder={t("user.msg.memoPlaceholder")}
          size={"lg"}
          rows={1}
          value={tempUserInfo.etc1 ?? ""}
          padding={8}
          onChange={(e) =>
            changeTempUserInfo({
              ...tempUserInfo,
              etc1: e.target.value,
            })
          }
          maxLength={64}
          classes="mg mt-12"
        />
      </Box>
    </Modal>
  );
};

export default CreateUpdateUserModal;
