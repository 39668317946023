const QuizStatUser = ({
  classes,
  width,
}: {
  classes?: string;
  width?: string | number;
}) => {
  return (
    <svg
      width={width || 40}
      height={41}
      className={classes}
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M27.6094 3.63672H12.3906C7.20431 3.63672 3 7.84103 3 13.0273V28.2461C3 33.4324 7.20431 37.6367 12.3906 37.6367H27.6094C32.7957 37.6367 37 33.4324 37 28.2461V13.0273C37 7.84103 32.7957 3.63672 27.6094 3.63672Z"
        fill="#AFC8FA"
      />
      <path
        d="M24.2002 19.0177L26.5379 21.6367L31.0833 16.6367"
        stroke="#446BBE"
        stroke-width="2.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.3589 19.6367C19.568 19.6367 21.3588 17.8459 21.3588 15.6367C21.3588 13.4276 19.568 11.6367 17.3589 11.6367C15.1497 11.6367 13.3589 13.4276 13.3589 15.6367C13.3589 17.8459 15.1497 19.6367 17.3589 19.6367Z"
        fill="#446BBE"
      />
      <path
        d="M22.306 29.707L12.694 29.707C11.8469 29.707 11.0345 29.3614 10.4355 28.7461C9.83651 28.1307 9.5 27.2962 9.5 26.426V26.3132C9.5 25.1467 9.95121 24.0279 10.7542 23.203C11.5573 22.3781 12.6464 21.9147 13.782 21.9147H21.218C22.3536 21.9147 23.4427 22.3781 24.2458 23.203C25.0488 24.0279 25.5 25.1467 25.5 26.3132V26.4363C25.4974 27.3047 25.1596 28.1366 24.5608 28.7497C23.9621 29.3628 23.1514 29.707 22.306 29.707Z"
        fill="#446BBE"
      />
    </svg>
  );
};

export default QuizStatUser;
