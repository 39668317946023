import "./index.scss";
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  Icons,
  IconButton,
  useToast,
} from "fasoo-ui-component-next";
import MindsatIcon from "../../shared/image/logo/mindsat";
import LoginImage from "../../shared/image/logo/Login";
import utilsCommon from "../../shared/utils/common";
import {
  removeLocalCustomerCode,
  removeSessionCustomerCode,
  removeUserInfoFromLocalStorage,
  setAccessToken,
} from "../../shared/utils/auth";
import { useNavigate } from "react-router-dom";
// import axios from "../../shared/utils/axios";
import { useCookies } from "react-cookie";
import useAccountStore from "../../redux/dispatcher/useAccountStore";
import { AccountResDTO } from "../../types/Login";
import { useTranslation } from "react-i18next";
import { nanoid } from "@reduxjs/toolkit";

const Login: React.FC = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();

  /** State */
  const [loginInfo, changeLoginInfo] = useState({
    email: "",
    name: "",
    pwd: "",
    checkPwd: "",
  });
  const [errorType, changeErrorType] = useState({
    isError: false,
    errorTarget: "",
    errorMsg: "",
  });
  const [showPassword, changeShowPassword] = useState({
    original: false,
    check: false,
  });
  const [pageStatus, changePageStatus] = useState<"login" | "findPW" | "setPW">(
    "login"
  );
  const [cookies, setCookie, removeCookie] = useCookies(["rememberEmail"]);
  const [rememberEmail, setRememberEmail] = useState<boolean>(
    !!cookies.rememberEmail
  );
  const [infoLoginMsg, changeInfoLoginMsg] = useState(false);
  const { loginSession, resetPassword, changePassword, sessionCheck } =
    useAccountStore();

  // useEffect(() => {
  //   // let path = window.location.pathname;

  //   sessionCheck();
  // }, [window.location.pathname]);

  const handleSessionCheck = async () => {
    const resp: any = await sessionCheck();

    if (resp.payload && resp.payload.data.code === "SUCCESS") {
      window.location.href = `${process.env.PUBLIC_URL}/dashboard`;
      return;
    } else {
      /** Page 가 바뀔때마다 state 값 초기화 */
      removeUserInfoFromLocalStorage();
      removeSessionCustomerCode();
      removeLocalCustomerCode();

      changeLoginInfo({
        email: cookies.rememberEmail ?? "",
        name: "",
        pwd: "",
        checkPwd: "",
      });
      changeErrorType({
        isError: false,
        errorTarget: "",
        errorMsg: "",
      });
      changeShowPassword({ original: false, check: false });
      changeInfoLoginMsg(false);
    }
  };
  /** UseEffect */
  useEffect(() => {
    // sessionCheck();
    handleSessionCheck();
  }, [pageStatus]);

  /** Function */
  const handleAfterLogin = async (data: AccountResDTO) => {
    const isSuper = data.user.managerType === 1 ? true : false;
    setAccessToken(data.token);
    // setAxiosHeaders();
    if (rememberEmail)
      setCookie("rememberEmail", data.user.managerEmail, { maxAge: 2629743 });
    else removeCookie("rememberEmail");
    // let managerInfo: any = await getManager(data.email);
    // if (managerInfo.payload && managerInfo.payload.manager_code) {
    // getMenu({
    //   accessType: data.user.managerType,
    //   managerCode: data.user.managerEmail,
    // });
    navigate(isSuper ? "/manageTrain/scenario" : "/dashboard");
    // }
  };
  // 임시 비밀번호
  const [tempPw, setTempPw] = useState<string | null>();

  /** 사용자에게서 받은 값들을 기준으로 validation check 후, 각 페이지에 맞는 액션을 취한다.
   * @param {"login" | "findPW" | "setPW"} type login (로그인 페이지) , findPW (비밀번호 찾기 페이지), setPW(임시 비밀번호 변경 페이지)
   */
  const handleLoginOrFindpw = async (type: "login" | "findPW" | "setPW") => {
    if (!checkValidation(type)) return;
    changeErrorType({
      isError: false,
      errorTarget: "",
      errorMsg: "",
    });

    // Do Action
    if (type === "login") {
      setCookie("rememberEmail", loginInfo.email, {
        maxAge: 60 * 60 * 24 * 30,
        path: "/",
      });
      const resp: any = await loginSession({
        userId: loginInfo.email,
        userPwd: loginInfo.pwd,
      });
      if (resp.payload && resp.payload.code === "SUCCESS") {
        /**임시 비밀번호 페이지 이동 */
        if (resp.payload?.data?.user?.isChangedPwd) {
          setTempPw(loginInfo.pwd);
          changePageStatus("setPW");
        } else {
          handleAfterLogin(resp.payload.data);
        }
      } else {
        changeErrorType({
          isError: true,
          errorTarget: "INVALID_INFO",
          errorMsg: t("login.msg.loginFailMsg"),
        });
      }
    }
    if (type === "findPW") {
      const resp: any = await resetPassword({
        email: loginInfo.email ?? rememberEmail,
        name: loginInfo.name,
      });
      if (resp.payload) {
        if (!resp.payload.data) {
          changeErrorType({
            isError: true,
            errorTarget: "findPW-name",
            errorMsg:
              resp.payload.code === "BAD_REQUEST"
                ? t("account.msg.commonNotMatchEmailAndNameMsg")
                : resp.payload.message ?? t("login.msg.loginFailMsg"),
          });
        } else {
          changeInfoLoginMsg(true);
        }
      }
    }
    if (type === "setPW") {
      const resp: any = await changePassword({
        email: loginInfo.email ?? rememberEmail,
        pwd: tempPw ?? "",
        newPwd: loginInfo.checkPwd,
      });
      if (resp.payload) {
        if (resp.payload.code === "SUCCESS") {
          toast.toastMsg(
            nanoid(),
            t("account.msg.commonPwUpdateSuccessMsg"),
            "success"
          );
          changePageStatus("login");
        } else {
          toast.toastMsg(nanoid(), t("login.msg.loginFailMsg"), "error");
        }
      }
    }
  };
  const checkValidation = (type: "login" | "findPW" | "setPW") => {
    if (type === "findPW" && !loginInfo.email) {
      changeErrorType({
        isError: true,
        errorTarget: "email",
        errorMsg: t("account.msg.enterUserId"),
      });
      return false;
    }
    if (type === "findPW" && !loginInfo.name) {
      changeErrorType({
        isError: true,
        errorTarget: "name",
        errorMsg: t("user.msg.userUndefinedNameMsg"),
      });
      return false;
    }
    if (type !== "setPW" && !loginInfo.email) {
      changeErrorType({
        isError: true,
        errorTarget: "email",
        errorMsg: t("account.msg.enterUserId"),
      });
      return false;
    }
    if (type !== "findPW" && !loginInfo.pwd) {
      changeErrorType({
        isError: true,
        errorTarget: "pwd",
        errorMsg: t("account.msg.enterPassword"),
      });
      return false;
    }
    if (type === "setPW" && !loginInfo.checkPwd) {
      changeErrorType({
        isError: true,
        errorTarget: "checkPwd",
        errorMsg: t("account.msg.commomAddNewPwMsg"),
      });
      return false;
    }
    if (
      type === "setPW" &&
      loginInfo.pwd &&
      !utilsCommon.passwordValidation(loginInfo.pwd)
    ) {
      changeErrorType({
        isError: true,
        errorTarget: "pwd",
        errorMsg: t("manager.pwdInfo"),
      });
      return false;
    }
    if (
      loginInfo.checkPwd &&
      !utilsCommon.passwordValidation(loginInfo.checkPwd)
    ) {
      changeErrorType({
        isError: true,
        errorTarget: "checkPwd",
        errorMsg: t("manager.pwdInfo"),
      });
      return false;
    }
    if (type === "setPW" && loginInfo.checkPwd !== loginInfo.pwd) {
      changeErrorType({
        isError: true,
        errorTarget: "checkPwd",
        errorMsg: t("account.msg.commomNotMatchPwMsg"),
      });
      return false;
    }
    return true;
  };
  /** UI - 로그인 페이지 */
  const LoginPage = () => {
    return (
      <Box direction="column" alignItems="center" width={"360px"}>
        <MindsatIcon classes="login-logo" width="222px" />
        <Box classes="login-form">
          <Typography size={14} fontWeight={700}>
            {t("login.loginEmailPlaceholder")}
          </Typography>
          <TextField
            size={"lg"}
            placeholder={t("login.loginEmailPlaceholder")}
            text={loginInfo.email}
            hasText
            error={
              errorType.errorTarget === "email" ||
              errorType.errorTarget === "INVALID_INFO"
            }
            errorMsg={
              errorType.errorTarget === "email" ? errorType.errorMsg : ""
            }
            onChange={(e: any) =>
              changeLoginInfo({ ...loginInfo, email: e.target.value })
            }
            onKeyPress={(e: any) =>
              e.key === "Enter" && handleLoginOrFindpw("login")
            }
          />
        </Box>
        <Box classes="login-form">
          <Typography size={14} fontWeight={700}>
            {t("common.auth.pwd")}
          </Typography>
          <Box direction="column">
            <TextField
              size={"lg"}
              placeholder={t("common.auth.pwd")}
              error={
                errorType.errorTarget === "pwd" ||
                errorType.errorTarget === "INVALID_INFO"
              }
              errorMsg={errorType.errorMsg}
              text={loginInfo.pwd}
              onChange={(e: any) =>
                changeLoginInfo({ ...loginInfo, pwd: e.target.value })
              }
              onKeyPress={(e: any) =>
                e.key === "Enter" && handleLoginOrFindpw("login")
              }
              type={showPassword.original ? "text" : "password"}
              endIcon={
                <IconButton
                  transparent
                  classes="hiddenEye"
                  onClick={() =>
                    changeShowPassword({
                      ...showPassword,
                      original: !showPassword.original,
                    })
                  }
                >
                  <Icons
                    variant="common"
                    label={showPassword.original ? "view" : "stopView"}
                    stroke="#b5bbc2"
                  />
                </IconButton>
              }
            />
          </Box>
        </Box>
        <Button
          fullWidth
          isBold
          onClick={() => handleLoginOrFindpw("login")}
          classes="mg mt-4"
        >
          {t("common.auth.signin")}
        </Button>
        <Box classes="login-under">
          <Box alignItems="center">
            <Checkbox
              checked={!!rememberEmail}
              onChange={() => setRememberEmail(!rememberEmail)}
            />
            <Typography classes="mg ml-4">
              {t("login.loginSaveEmail")}
            </Typography>
          </Box>
          <Button
            color={"text-noline"}
            textColor={"green"}
            onClick={() => changePageStatus("findPW")}
          >
            {t("account.commonFindPwd")}
          </Button>
        </Box>
      </Box>
    );
  };
  /** UI - 비밀번호 찾기 페이지 */
  const FindPWPage = () => {
    return (
      <Box direction="column" alignItems="center" width={"360px"}>
        <Typography size={24} fontWeight={700} classes="login-logo">
          {t("account.msg.findPwdMsg")}
        </Typography>
        <Box classes="login-form">
          <Typography size={14} fontWeight={700}>
            {t("login.loginEmailPlaceholder")}
          </Typography>
          <TextField
            size={"lg"}
            placeholder={t("login.loginEmailPlaceholder")}
            text={loginInfo.email}
            hasText
            error={errorType.errorTarget === "email"}
            errorMsg={errorType.errorMsg}
            onChange={(e: any) =>
              changeLoginInfo({ ...loginInfo, email: e.target.value })
            }
            onKeyPress={(e: any) =>
              e.key === "Enter" && handleLoginOrFindpw("findPW")
            }
          />
        </Box>
        <Box classes="login-form">
          <Typography size={14} fontWeight={700}>
            {t("common.list.name")}
          </Typography>
          <TextField
            size={"lg"}
            placeholder={t("user.userName")}
            text={loginInfo.name}
            error={
              errorType.errorTarget === "findPW-name" ||
              errorType.errorTarget === "name"
            }
            hasText
            errorMsg={errorType.errorMsg}
            onChange={(e: any) =>
              changeLoginInfo({ ...loginInfo, name: e.target.value })
            }
            onKeyPress={(e: any) =>
              e.key === "Enter" && handleLoginOrFindpw("findPW")
            }
          />
          {infoLoginMsg && (
            <Typography color={"blue"}>
              {t("account.msg.commonReLoginMsg")}
            </Typography>
          )}
        </Box>
        <Button
          fullWidth
          isBold
          onClick={() => handleLoginOrFindpw("findPW")}
          classes="mg mt-4"
        >
          {t("account.commonSendTempPw")}
        </Button>
        <Button
          fullWidth
          isBold
          onClick={() => changePageStatus("login")}
          classes="mg mt-32"
          color={"text-noline"}
          textColor="green"
        >
          {t("account.commonBackToLogin")}{" "}
        </Button>
      </Box>
    );
  };
  /** UI - 임시 비밀번호 페이지 */
  const SetPWPage = () => {
    return (
      <Box direction="column" alignItems="center" width={"360px"}>
        <Typography size={24} fontWeight={700} classes="login-logo">
          {t("account.changeTempPwd")}
        </Typography>
        <Box classes="login-form">
          <Typography size={14} fontWeight={700}>
            {t("login.loginEmailPlaceholder")}
          </Typography>
          <TextField
            size={"lg"}
            placeholder={t("login.loginEmailPlaceholder")}
            text={loginInfo.email}
            disabled
          />
        </Box>
        <Box classes="login-form">
          <Typography size={14} fontWeight={700}>
            {t("common.auth.pwd")}
          </Typography>
          <TextField
            size={"lg"}
            placeholder={t("common.auth.pwd")}
            text={loginInfo.pwd}
            error={errorType.errorTarget === "pwd"}
            errorMsg={errorType.errorMsg}
            type={showPassword.original ? "text" : "password"}
            endIcon={
              <IconButton
                transparent
                classes="hiddenEye"
                onClick={() =>
                  changeShowPassword({
                    ...showPassword,
                    original: !showPassword.original,
                  })
                }
              >
                <Icons
                  variant="common"
                  label={showPassword.original ? "view" : "stopView"}
                  stroke="#b5bbc2"
                />
              </IconButton>
            }
            onChange={(e: any) =>
              changeLoginInfo({ ...loginInfo, pwd: e.target.value })
            }
            onKeyPress={(e: any) =>
              e.key === "Enter" && handleLoginOrFindpw("setPW")
            }
          />
        </Box>
        <Box classes="login-form">
          <Typography size={14} fontWeight={700}>
            {t("account.commonPwCheck")}
          </Typography>
          <TextField
            size={"lg"}
            placeholder={t("account.commonPwCheck")}
            text={loginInfo.checkPwd}
            error={errorType.errorTarget === "checkPwd"}
            errorMsg={errorType.errorMsg}
            type={showPassword.check ? "text" : "password"}
            endIcon={
              <IconButton
                transparent
                classes="hiddenEye"
                onClick={() =>
                  changeShowPassword({
                    ...showPassword,
                    check: !showPassword.check,
                  })
                }
              >
                <Icons
                  variant="common"
                  label={showPassword.check ? "view" : "stopView"}
                  stroke="#b5bbc2"
                />
              </IconButton>
            }
            onChange={(e: any) =>
              changeLoginInfo({ ...loginInfo, checkPwd: e.target.value })
            }
            onKeyPress={(e: any) =>
              e.key === "Enter" && handleLoginOrFindpw("setPW")
            }
          />
        </Box>
        <Button
          fullWidth
          isBold
          onClick={() => handleLoginOrFindpw("setPW")}
          classes="mg mt-4"
        >
          {t("common.auth.signin")}
        </Button>
        <Button
          fullWidth
          isBold
          onClick={() => changePageStatus("login")}
          classes="mg mt-32"
          color={"text-noline"}
          textColor="green"
        >
          {t("account.commonBackToLogin")}
        </Button>
      </Box>
    );
  };
  return (
    <Box alignItems="center" height={"100%"} classes="login">
      {pageStatus === "login"
        ? LoginPage()
        : pageStatus === "findPW"
        ? FindPWPage()
        : SetPWPage()}
      <LoginImage classes="login-img" />
    </Box>
  );
};

export default Login;
