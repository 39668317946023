import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import deptApi from "../api/deptApi";
import { SimpleTreeNode, UserType } from "../../types/User";
import FileSaver from "file-saver";

interface DeptState {
  organList?: SimpleTreeNode[];
  userListInDept?: UserType[];
  userTotal?: number;
}

const initialState: DeptState = {
  organList: undefined,
  userListInDept: undefined,
};

export const trunkGetDeptList = createAsyncThunk(
  "dept/getDeptList",
  async (deptCodes?: string[]) => {
    const resp = await deptApi.getDeptList(deptCodes);

    return resp;
  }
);

export const trunkGetOrganUserList = createAsyncThunk(
  "organ/userList",
  async (deptCode?: string) => {
    const resp = await deptApi.getOrganUserList(deptCode);
    return resp;
  }
);
export const trunkGetOrganUserCount = createAsyncThunk(
  "organ/userCount",
  async (deptCode?: string) => {
    const resp = await deptApi.getOrganUserCount(deptCode);
    return resp;
  }
);
export const trunkInsertOrganUserList = createAsyncThunk(
  "organ/userList/insert",
  async (user: UserType) => {
    const resp = await deptApi.insertOrganUserList(user);
    return resp;
  }
);
export const trunkUpdateOrganUserList = createAsyncThunk(
  "organ/userList/update",
  async (user: UserType) => {
    const resp = await deptApi.updateOrganUserList(user);
    return resp;
  }
);
export const trunkDeleteOrganUserList = createAsyncThunk(
  "organ/userList/delete",
  async (userId: string[]) => {
    const resp = await deptApi.deleteOrganUserList(userId);
    return resp;
  }
);
export const trunkGetDeptListForUser = createAsyncThunk(
  "dept/getDeptListForUser",
  async () => {
    const resp = await deptApi.getDeptListForUser();

    return resp;
  }
);

export const trunkGetDept = createAsyncThunk(
  "dept/getDept",
  async (dept_code: string) => {
    const resp = await deptApi.getDept(dept_code);

    return resp;
  }
);

export const trunkUpdateDept = createAsyncThunk(
  "dept/updateDept",
  async (req: { deptCode: string; deptName: string; parentCode: string }) => {
    const resp = await deptApi.updateDept(req);

    return resp;
  }
);

export const trunkUploadCsv = createAsyncThunk(
  "dept/uploadCsv",
  async (formData: FormData) => {
    const resp = await deptApi.uploadCsv(formData);

    return resp;
  }
);
export const trunkCheckBeforeUpdateUserCSV = createAsyncThunk(
  "dept/checkUserCsv",
  async (formData: File) => {
    const resp = await deptApi.checkBeforeUpdateUserCSV(formData);

    return resp;
  }
);
export const trunkCheckBeforeUpdateDeptCSV = createAsyncThunk(
  "dept/checkDeptCsv",
  async (formData: File) => {
    const resp = await deptApi.checkBeforeUpdateDeptCSV(formData);

    return resp;
  }
);
export const trunkUploadUserCSV = createAsyncThunk(
  "dept/uploadUserCsv",
  async (formData: File) => {
    const resp = await deptApi.uploadUserCSV(formData);

    return resp;
  }
);
export const trunkUploadDeptCSV = createAsyncThunk(
  "dept/uploadDeptCsv",
  async (formData: File) => {
    const resp = await deptApi.uploadDeptCSV(formData);

    return resp;
  }
);

export const trunkInsertCsv = createAsyncThunk(
  "dept/insertCsv",
  async (req: {
    deptCodes: string;
    deptNames: string;
    parentCodes: string;
  }) => {
    const resp = await deptApi.insertCsv(req);

    return resp;
  }
);
export const trunkExportDeptFile = createAsyncThunk(
  "dept/download",
  async () => {
    const resp = await deptApi.downloadDeptFile();

    return resp;
  }
);

export const trunkAddDept = createAsyncThunk(
  "dept/addDept",
  async (req: { deptCode: string; deptName: string; parentCode: string }) => {
    const resp = await deptApi.addDept(req);

    return resp;
  }
);

export const trunkDeleteDept = createAsyncThunk(
  "dept/deleteDept",
  async (deptCode: string) => {
    const resp = await deptApi.deleteDept(deptCode);

    return resp;
  }
);

const deptSlice = createSlice({
  name: "dept",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(trunkGetDeptList.fulfilled, (state, action) => {
      const deptCodeList = state.organList?.map((item) => item.deptCode);
      const newDeptCodes = action.payload.data?.list?.filter(
        (item) => !deptCodeList?.includes(item.deptCode)
      );
      state.organList = (state.organList ?? [])?.concat(newDeptCodes);
      state.organList = state.organList?.map((item) => ({
        ...item,
        children: [],
        totalUserCount: item?.userCount,
      }));
      state.organList?.forEach((item) => {
        if (item.parentCode) {
          state.organList
            ?.filter((item1) => item1.deptCode === item.parentCode)[0]
            .children?.push(item.deptCode);
        }
      });
      state.organList = state.organList?.map((item) => {
        if (item.children && item.children.length > 0) {
          let cnt = item?.userCount;
          state.organList
            ?.filter((item1) => item.children?.includes(item1?.deptCode))
            .map((item2) => (cnt += item2?.userCount));
          return {
            ...item,
            totalUserCount: cnt,
          };
        } else return item;
      });
    });
    builder.addCase(
      trunkGetDeptListForUser.fulfilled,
      (state, { payload: data }) => {
        console.log("-->", data);
      }
    );
    builder.addCase(
      trunkGetOrganUserList.fulfilled,
      (state, { payload: data }) => {
        state.userListInDept = data.data?.list;
      }
    );
    builder.addCase(
      trunkGetOrganUserCount.fulfilled,
      (state, { payload: data }) => {
        state.userTotal = data.data?.total;
      }
    );
    builder.addCase(
      trunkExportDeptFile.fulfilled,
      (state, { payload: data }) => {
        const href = URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "deptUser.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }
    );
    builder.addCase(trunkGetDept.fulfilled, (state, { payload: data }) => {
      console.log("-->", data);
    });

    builder.addCase(trunkUpdateDept.fulfilled, (state, { payload: data }) => {
      const parentCode = state.organList?.filter(
        (item) => item.deptCode === data.data.deptCode
      )[0].parentCode;
      const isSameDept = data.data.parentCode === parentCode;
      const newParentCode =
        state.organList?.filter(
          (item) => item.deptCode === data.data?.parentCode
        )[0]?.deptCode ?? "";

      state.organList = state.organList?.map((item) =>
        item.deptCode === data.data.deptCode
          ? {
              ...item,
              deptName: data.data.deptName,
              parentCode: data.data.parentCode,
            }
          : item
      );
      if (parentCode && !isSameDept) {
        state.organList = state.organList?.map((item) =>
          item.deptCode === parentCode
            ? { ...item, deptCount: item.deptCount - 1 }
            : item.deptCode === newParentCode
            ? { ...item, deptCount: item.deptCount + 1 }
            : { ...item }
        );
      }
    });
    builder.addCase(trunkUploadCsv.fulfilled, (state, { payload: data }) => {
      console.log("-->", data);
    });
    builder.addCase(trunkInsertCsv.fulfilled, (state, { payload: data }) => {
      console.log("-->", data);
    });
    builder.addCase(trunkAddDept.fulfilled, (state, { payload: data }) => {
      if (data.code === "SUCCESS") {
        const newDpet: SimpleTreeNode = {
          ...data.data,
          type: "dept",
          deptCount: 0,
          userCount: 0,
        };
        state.organList = state.organList
          ? [...state.organList, newDpet]
          : [newDpet];
        if (data.data.parentCode) {
          state.organList = state.organList?.map((item) =>
            item.deptCode === data.data.parentCode
              ? { ...item, deptCount: item.deptCount + 1 }
              : { ...item }
          );
        }
      }
    });
    builder.addCase(trunkDeleteDept.fulfilled, (state, { payload: data }) => {
      if (data.code === "SUCCESS") {
        const parentCode = state.organList?.filter(
          (item) => item.deptCode === data.data.deptCode
        )[0].parentCode;
        state.organList = state.organList?.filter(
          (item) => item.deptCode !== data.data.deptCode
        );
        if (parentCode) {
          state.organList = state.organList?.map((item) =>
            item.deptCode === parentCode
              ? { ...item, deptCount: item.deptCount - 1 }
              : { ...item }
          );
        }
      }
    });
  },
});

export const deptActions = deptSlice.actions;

export default deptSlice.reducer;
