import "./index.scss";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Icons,
  ScrollBox,
  TextField,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import TitlePath from "../../../component/TitlePath";
import { useEffect, useState } from "react";
import { QuizContentDTO } from "../../../types/Quiz";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
import CategoryTag from "../../../component/CategoryTag";
import CommonModal from "../../../component/CommonModal";
import { nanoid } from "@reduxjs/toolkit";
import { getLocalCustomerCode } from "../../../shared/utils/auth";
import useCheckboxGroup from "../../../shared/utils/checkbox";

const QuizContentDetail: React.FC = () => {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const quizId = searchParams.get("quiz_id");
  const localCustomerCode = getLocalCustomerCode();
  const categoryCheckboxGroup = useCheckboxGroup();
  const {
    quizState,
    getQuizContentDetail,
    deleteQuizContent,
    updateQuizContent,
    getQuizCategory,
  } = useQuizStore();
  const [categoryList, setCategoryList] = useState<string[]>([]);
  const [categoryInput, setCategoryInput] = useState<string>("");
  const [quizContent, setQuizContent] = useState<QuizContentDTO>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<
    "USED" | "NOT_USED" | null
  >(null);
  const [isUpdateModalopen, setIsUpdateModalOpen] = useState<number | null>(
    null
  );

  useEffect(() => {
    getQuizCategory();
    quizId && getQuizContentDetail({ quizId: parseInt(quizId) });
  }, []);

  useEffect(() => {
    if (quizState.quizContent) {
      setQuizContent(quizState.quizContent);
    }
  }, [quizState.quizContent]);

  useEffect(() => {
    const newCatName = categoryInput.trim();
    if (newCatName.length > 0) {
      setCategoryList(
        quizState.quizContentCategory
          .filter((item: string) => item.includes(newCatName))
          .map((c: string) => c)
      );
    } else {
      setCategoryList([]);
    }
  }, [categoryInput]);

  const onDelete = async () => {
    if (quizId) {
      const resp: any = await deleteQuizContent({
        quizIds: [parseInt(quizId)],
      });
      if (resp.payload && resp.payload.code === "SUCCESS") {
        toast.toastMsg(
          nanoid(),
          t("quiz.msg.deleteQuizContentSuccess"),
          "success"
        );
        setIsDeleteModalOpen(null);
        navigate(`${process.env.PUBLIC_URL}/quiz/content`);
      } else {
        toast.toastMsg(
          nanoid(),
          t("template.msg.templateDuplicatedTemplateMsg"),
          "error"
        );
      }
    }
  };

  const updateCategory = async (item: string, type: string) => {
    const newCategoryName = categoryInput.trim();
    if (quizId && quizContent) {
      if (type === "DELETE") {
        const resp: any = await updateQuizContent({
          quizId: parseInt(quizId),
          quizName: quizContent.quizName,
          quizType: quizContent.quizType,
          quizBody: quizContent.quizBody,
          quizComments: quizContent.quizComments,
          quizAnswer: quizContent.quizAnswer,
          quizChoices: quizContent.quizChoices,
          categorys:
            quizContent.categorys &&
            quizContent.categorys.filter((c) => c !== item),
        });

        if (resp.payload && resp.payload.code === "SUCCESS") {
          setQuizContent((prev) => ({
            ...prev,
            categorys:
              quizContent.categorys &&
              quizContent.categorys.filter((c) => c !== item),
          }));
        } else {
          toast.toastMsg(nanoid(), t("quiz.msg.duplicateQuizName"), "error");
        }
      } else if (type === "ADD") {
        if (newCategoryName.length > 30) {
          toast.toastMsg(
            nanoid(),
            t("category.msg.categoryNameLengthLimitMsg"),
            "error"
          );
          return;
        }
        if (newCategoryName === "") {
          return;
        }

        if (/^\s*$/.test(newCategoryName)) {
          return;
        }

        if (!categoryList.some((item) => item === newCategoryName)) {
          const resp: any = await updateQuizContent({
            quizId: parseInt(quizId),
            quizName: quizContent.quizName,
            quizType: quizContent.quizType,
            quizBody: quizContent.quizBody,
            quizComments: quizContent.quizComments,
            quizAnswer: quizContent.quizAnswer,
            quizChoices: quizContent.quizChoices,
            categorys: quizContent.categorys && [
              ...quizContent.categorys,
              item,
            ],
          });

          if (resp.payload && resp.payload.code === "SUCCESS") {
            setQuizContent((prev) => ({
              ...prev,
              categorys: quizContent.categorys && [
                ...quizContent.categorys,
                item,
              ],
            }));
          } else {
            toast.toastMsg(nanoid(), t("quiz.msg.duplicateQuizName"), "error");
          }
        }
        setCategoryInput("");
      }
    }
  };

  // useEffect(() => {
  //   const newCatName = categoryInput.trim();
  //   if (newCatName.length > 0) {
  //     setCategoryList(
  //       quizState.quizContentCategory
  //         .filter((item: string) => item.includes(newCatName))
  //         .map((c: string) => c)
  //     );
  //   } else {
  //     setCategoryList([]);
  //   }
  // }, [categoryInput]);

  const onCopy = () => {
    window.sessionStorage.setItem(
      "copyQuizFilter",
      JSON.stringify(quizContent)
    );
    navigate(`${process.env.PUBLIC_URL}/quiz/content/write`);
  };

  return (
    <ScrollBox classes="main">
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath path={[t("menu.quizTitle"), t("menu.quizContent")]} />
          <Box alignItems="center" classes="mg mt-8">
            <Box
              onClick={() => navigate(`${process.env.PUBLIC_URL}/quiz/content`)}
              style={{ cursor: "pointer" }}
            >
              <Icons variant="arrow" label="left" classes="mg mr-8" />
            </Box>
            <Typography fontWeight={700} size={24} lineHeight="32px">
              {t("quiz.label.details")}
            </Typography>
          </Box>
        </Box>
        {quizId &&
        (quizContent?.commonId === undefined || !localCustomerCode) ? (
          <Box>
            <Button
              color={"secondary"}
              startIcon={<Icons variant="file" label="edit" />}
              onClick={() => setIsUpdateModalOpen(parseInt(quizId))}
            >
              {t("common.button.update")}
            </Button>

            <Button
              color={"lightWarning"}
              startIcon={
                <Icons variant="common" label="trash" stroke="#ef5350" />
              }
              onClick={() => setIsDeleteModalOpen("USED")}
              classes="mg ml-10"
            >
              {t("common.button.delete")}
            </Button>
          </Box>
        ) : (
          <Box>
            <Button
              color={"secondary"}
              startIcon={<Icons variant="common" label="template" />}
              onClick={onCopy}
              classes="mg ml-10"
            >
              {t("common.button.copy")}
            </Button>
          </Box>
        )}
      </Box>

      {quizContent && (
        <Box direction="column">
          <Box classes="mg mt-36 mb-36 ml-16">
            <Box width={248} alignItems="center" height={20}>
              <Typography fontWeight={700} color="secondary">
                {t("quiz.label.quizName")}
              </Typography>
            </Box>
            <Box alignItems="center" width={"598px"}>
              <Typography>{quizContent?.quizName}</Typography>
            </Box>
          </Box>

          <Box classes="mg mb-36 ml-16">
            <Box width={248} alignItems="center" height={20}>
              <Typography fontWeight={700} color="secondary">
                {t("quiz.label.question")}
              </Typography>
            </Box>
            <Box alignItems="center" width={"598px"}>
              <Typography>{quizContent.quizBody}</Typography>
            </Box>
          </Box>

          <Box classes="mg mb-36 ml-16">
            <Box width={248} alignItems="center" height={20}>
              <Typography fontWeight={700} color="secondary">
                {t("quiz.label.format")}
              </Typography>
            </Box>
            <Box alignItems="center" width={"598px"}>
              <Box direction="column">
                <Typography>
                  {quizContent.quizType === 1
                    ? t("quiz.label.formatTF")
                    : t("quiz.label.formatChoice")}
                </Typography>
                <Divider direction="row" classes="mg mt-20 mb-20" />
                {quizContent.quizType !== 1 && (
                  <Box classes="mg mt-20" direction="column">
                    {quizContent.quizChoices?.map((q, index) => (
                      <Box classes="mg mb-20">
                        <Box width={120} classes="mg mr-16 mb-20">
                          <Typography color="secondary" width="120px">
                            {`${t("quiz.label.choice2")}  ${index + 1}`}
                          </Typography>
                        </Box>
                        {q}
                      </Box>
                    ))}
                  </Box>
                )}
                <Box classes="quiz__detail__answer__container">
                  <Box width={104} classes="mg mr-16">
                    <Typography color="secondary">
                      {t("quiz.label.answer")}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      {quizContent.quizType === 1
                        ? quizContent.quizAnswer === 0
                          ? t("quiz.label.choiceO")
                          : t("quiz.label.choiceX")
                        : quizContent?.quizAnswer !== undefined &&
                          quizContent?.quizAnswer + 1}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box classes="mg mb-20 ml-16">
            <Box width={248} alignItems="center" height={20}>
              <Typography fontWeight={700} color="secondary">
                {t("quiz.label.answerEx")}
              </Typography>
            </Box>
            <Box alignItems="center" width={"598px"}>
              <Typography>{quizContent.quizComments}</Typography>
            </Box>
          </Box>

          {/* category start */}
          <Box
            style={{
              marginBottom: "62px",
              marginTop: "24px",
              marginLeft: "16px",
            }}
          >
            <Box width={248} alignItems="center" height={20}>
              <Typography fontWeight={700} classes="mg mb-12" color="secondary">
                {t("quiz.label.category")}
              </Typography>
            </Box>

            <Box width={"598px"} direction="column">
              <Box classes="category__select" width={598}>
                <TextField
                  placeholder={t("quiz.label.categoryHolder")}
                  width={598}
                  height={40}
                  borderRadius={8}
                  text={categoryInput}
                  hasText={true}
                  onChange={(e) => setCategoryInput(e.target.value)}
                  onKeyPress={(e) =>
                    e.key === "Enter" && updateCategory(categoryInput, "ADD")
                  }
                  maxLength={30}
                />

                {categoryList.length > 0 && (
                  <Box classes="mg category__select__list">
                    {categoryList.map((item) => (
                      <Box
                        classes="category__select__item"
                        key={item}
                        style={{
                          backgroundColor: categoryCheckboxGroup.isSelected(
                            item
                          )
                            ? "#EAF4FF"
                            : "#FFFFFF",
                        }}
                      >
                        <Typography>{item}</Typography>
                        <Checkbox
                          radio={true}
                          onChange={(e: any) => updateCategory(item, "ADD")}
                          checked={categoryCheckboxGroup.isSelected(item)}
                        />
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>

              <Box
                width={"100%"}
                style={{ flexWrap: "wrap", marginTop: "24px" }}
              >
                {quizContent.categorys?.map((item: string) => (
                  <CategoryTag
                    key={item}
                    text={item}
                    endIcon={<Icons variant="common" label="delete" />}
                    onClick={() => updateCategory(item, "DELETE")}
                  />
                ))}
              </Box>
            </Box>
          </Box>
          {/* category end  */}

          {/* footer start */}

          {/* footer end */}
        </Box>
      )}
      {isDeleteModalOpen && (
        <CommonModal
          open={isDeleteModalOpen === null ? false : true}
          onClose={() => setIsDeleteModalOpen(null)}
          title={t("quiz.msg.deleteQuizContentTitle")}
          afterSubmitButton={onDelete}
          subMessage={
            isDeleteModalOpen === "USED"
              ? t("quiz.msg.deleteQuizContentAlert")
              : t("quiz.msg.deleteQuizContentMsg")
          }
          type="delete"
        />
      )}
      {!!isUpdateModalopen && (
        <CommonModal
          open={!!isUpdateModalopen}
          onClose={() => setIsUpdateModalOpen(null)}
          title={t("quiz.msg.updateQuizContentTitle")}
          afterSubmitButton={() => {
            navigate(
              `${process.env.PUBLIC_URL}/quiz/content/write?quiz_id=${isUpdateModalopen}`
            );
            setIsUpdateModalOpen(null);
          }}
          subMessage={t("quiz.msg.updateQuizContentAlert")}
        />
      )}
    </ScrollBox>
  );
};

export default QuizContentDetail;
