import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import { useEffect, useRef, useState } from "react";
import { ImagesInfo, SiteInfo } from "../../../types/Info";
import useContentStore from "../../../redux/dispatcher/useContentStore";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

interface RegitOrEditSiteModalProps {
  isOpen: boolean;
  onClose: () => void;
  imageId?: number;
}

const RegitOrEditImageModal: React.FC<RegitOrEditSiteModalProps> = ({
  isOpen,
  onClose,
  imageId,
}) => {
  const { t } = useTranslation();
  const hiddenFileInput = useRef<any>(null);
  const [imageInfo, setImageInfo] = useState<ImagesInfo>();
  const [file, setFile] = useState<File | null>();
  const [imageName, setImageName] = useState<string | null>();

  const { contentState, uploadImage, updateImage, getImages } =
    useContentStore();
  const toast = useToast();

  const ext = ["gif", "svg", "png", "jpg"];

  useEffect(() => {
    setImageName(
      imageId
        ? contentState.images.filter(
            (item: ImagesInfo) => item.imageId === imageId
          )[0].imageName
        : null
    );
    setFile(null);
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = "";
    }
  }, [imageId]);

  const onSubmit = async () => {
    //유효성 검사

    if (imageName) {
      if (/^\s*$/.test(imageName)) {
        toast.toastMsg(nanoid(), t("common.msg.nameEmptyMsg"), "error");
        return;
      }

      if (imageName?.length > 128) {
        toast.toastMsg(nanoid(), t("common.msg.fieldLengthLimitMsg"), "error");
        return;
      }
      if (imageId) {
        const resp: any = await updateImage({
          request: { imageName, imageId: imageId },
          file: file ?? null,
        });

        if (resp.payload && resp.payload.code === "SUCCESS") {
          toast.toastMsg(
            nanoid(),
            t("image.msg.imageUpdateSuccessMsg"),
            "success"
          );
          getImages({});
        } else {
          if (resp.payload && resp.payload.code === "DATA_DUPLICATE") {
            toast.toastMsg(
              nanoid(),
              t("image.msg.imageDuplicatedNameMsg"),
              "error"
            );
            return;
          }
          toast.toastMsg(nanoid(), t("image.msg.imageUpdateErrorMsg"), "error");
        }
      } else {
        if (!file) {
          toast.toastMsg(nanoid(), t("image.msg.nofilePlaceholder"), "error");
          return;
        }

        const resp: any = await uploadImage({ file, request: { imageName } });
        if (resp.payload && resp.payload.code === "SUCCESS") {
          toast.toastMsg(
            nanoid(),
            t("image.msg.imageAddSuccessMsg"),
            "success"
          );
          getImages({});
        } else {
          if (resp.payload && resp.payload.code === "DATA_DUPLICATE") {
            toast.toastMsg(
              nanoid(),
              t("image.msg.imageDuplicatedNameMsg"),
              "error"
            );
            return;
          }

          toast.toastMsg(nanoid(), t("image.msg.imageInsertErrorMsg"), "error");
        }
      }
      setFile(null);
      setImageName(null);
      onClose();
    } else {
      toast.toastMsg(nanoid(), t("common.msg.nameEmptyMsg"), "error");
    }
  };

  const handleFileClick = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  const handleFileChange = (e: any) => {
    let value = "";
    const name = e.target.name;
    const chgcontent = "";

    if (name === "file") {
      const fileName = e.target.files[0] && e.target.files[0].name;
      const idx = fileName ? fileName.lastIndexOf(".") : -1;
      if (idx > 0) {
        const extension = fileName.substring(idx + 1);
        // 확장자 체크
        if (!ext.includes(extension.toLowerCase())) {
          toast.toastMsg(
            nanoid(),
            t("image.msg.imageInvalidExtenstionMsg"),
            "error"
          );
          e.target.value = "";
          return;
        }
      }

      // 사이즈 체크
      if (e.target.files[0] && e.target.files[0].size > 20971520) {
        toast.toastMsg(
          nanoid(),
          t("attach.msg.attachInvalidFileSizeMsg"),
          "error"
        );
        e.target.value = "";
        return;
      }

      setFile(e.target.files[0]);
    } else {
      value = e.target.value.trim();
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      width={480}
      title={imageId ? t("image.update") : t("image.add")}
      isDivider={false}
      footer={
        <Box justifyContent="flex-end" width="100%">
          <Button color="secondary" onClick={onClose}>
            {t("common.button.cancel")}
          </Button>
          <Button classes="mg ml-8" onClick={onSubmit}>
            {imageId ? t("common.button.save") : t("common.button.save")}
          </Button>
        </Box>
      }
    >
      <Box direction="column">
        <Box direction="column">
          <Typography fontWeight={700}>{t("image.name")}</Typography>
          <TextField
            classes="mg mt-12"
            height={40}
            borderRadius={8}
            text={imageName ?? ""}
            width={424}
            onChange={(e: any) => setImageName(e.target.value)}
            hasText
            placeholder={t("template.msg.imageNamePlaceholder")}
            maxLength={128}
          />
        </Box>
        <Box direction="column" classes="mg mt-32">
          <Typography fontWeight={700}>
            {t("common.button.imageUpload")}
          </Typography>
          <Box
            alignItems="center"
            // justifyContent="center"
            width={600}
            height={50}
            classes="mg mt-12"
          >
            <input
              style={{ display: "none" }}
              name="file"
              type="file"
              onChange={handleFileChange}
              className="modalField hide"
              ref={hiddenFileInput}
              accept="image/*"
            />

            <TextField
              readOnly
              name="fileName"
              hasText
              text={file ? file.name : ""}
              placeholder={t("image.msg.nofilePlaceholder")}
              height={40}
              width={329}
            />

            <Button
              color="secondary"
              onClick={handleFileClick}
              classes="mg mt-10 ml-8"
              width={87}
              padding={10}
            >
              {t("common.button.selectFile")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default RegitOrEditImageModal;
