import "./index.scss";
import {
  Box,
  Button,
  Calendar,
  Divider,
  DropdownItem,
  IconButton,
  Icons,
  SettingButton,
  Switch,
  Table,
  Tag,
  TextField,
  ToolTip,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import TitlePath from "../../../component/TitlePath";
import { useEffect, useLayoutEffect, useState } from "react";
import { ConfigListType } from "../../../types/Send";
import SettingChildren from "../../../component/SettingChildren";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import CommonModal from "../../../component/CommonModal";
import utilsCommon from "../../../shared/utils/common";
import RegitTrainingModal from "./RegitTrainingModal";
import useProgramStore from "../../../redux/dispatcher/useProgramStore";
import TrainingDetailModal from "./TrainingDetailModal";
import { nanoid } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { TrainingListReqDTO } from "../../../types/Training";
import NoInfoImage from "../../../shared/image/NoInfoImage";
import NoTemplateImage from "../../../shared/image/NoTemplateImage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CategoryTag from "../../../component/CategoryTag";

interface ProgressType {
  id: number;
  text: string;
}

interface FilterDateType {
  startDate?: string;
  endDate?: string;
}

const ManageTrainList: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { programState, getProgramList, deleteProgram, updateProgram } =
    useProgramStore();
  const [configList, setConfigList] = useState<ConfigListType[]>([]);
  const [configListTotal, setConfigListTotal] = useState<number>(0);
  const checkboxGroup = useCheckboxGroup<number>();
  // 필터 훈련 대상자
  const checkGroup = useCheckboxGroup<number>();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [progressState, setProgressState] = useState<ProgressType>({
    id: 0,
    text: t("common.auth.all"),
  });
  const [configIdState, setConfigState] = useState<ProgressType>({
    id: 0,
    text: t("common.auth.all"),
  });
  const [dateState, setDateState] = useState<ProgressType>({
    id: 0,
    text: t("common.auth.all"),
  });
  const [customDate, setCustomDate] = useState<FilterDateType>();
  const [filter, changeFilter] = useState<TrainingListReqDTO>({
    orderId: "configId",
    orderType: "desc",
    configId: undefined,
    trainingStatus: undefined,
    configName: undefined,
    configNames: [],
    sendStartDate: undefined,
    sendEndDate: undefined,
  });

  const [tempConfigName, changeTempConfigName] = useState("");
  const [tempConfigNames, setTempConfigNames] = useState<string[]>([]);
  const [isFilterCalendarOpen, setIsFilterCalendarOpen] =
    useState<boolean>(false);
  const [selectedConfigs, setSelectedConfigs] = useState<number[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isActivateModalOpen, setIsActivateModalOpen] =
    useState<boolean>(false);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] =
    useState<boolean>(false);
  const [sort, changeSort] = useState("");
  const [buttonClick, setButtonClick] = useState(0);
  const [configIdList, setConfigIdList] = useState<ConfigListType[]>([]);

  const toast = useToast();

  const renderTableHeaderWithSort = (key: string, name: string) => {
    return (
      <Box
        onClick={() =>
          changeSort(
            !sort.includes(key)
              ? key + "-Desc"
              : sort === key + "-Desc"
              ? key
              : ""
          )
        }
      >
        <Typography classes="cursor-pointer">{name}</Typography>
        {sort.includes(key) && (
          <Icons
            variant="arrow"
            label={sort.includes("-Desc") ? "down" : "up"}
          />
        )}
      </Box>
    );
  };

  const progressStates = [
    {
      id: 0,
      text: t("common.auth.all"),
    },
    { id: 1, text: t("program.filter.beforeStart") },
    { id: 2, text: t("program.filter.onProgress") },
    { id: 3, text: t("program.filter.finished") },
    { id: 4, text: t("program.filter.stop") },
  ];

  const dateStates = [
    { id: 0, text: t("common.auth.all") },
    { id: 1, text: t("program.filter.beforeOneMonth") },
    { id: 2, text: t("program.filter.beforeThreeMonth") },
    { id: 3, text: t("program.filter.beforeSixMonth") },
  ];
  useEffect(() => {
    initialPage();
  }, []);

  const initialPage = async () => {
    const resp: any = await getProgramList(filter);
    if (resp.payload && resp.payload.data.list.length > 0) {
      setConfigIdList(resp.payload.data.list);
    }
  };
  const refreshList = () => {
    getProgramList(filter);
  };
  useEffect(() => {
    setConfigList(programState.configListWithCount?.list ?? []);
    setConfigListTotal(programState.configListWithCount?.total ?? 0);
  }, [programState.configListWithCount]);

  useEffect(() => {
    refreshList();
    // let filteredList = programState.configListWithCount?.list;
    // if (filter.configId) {
    //   filteredList = filteredList.filter(
    //     (item: ConfigListType) => item.configId === filter.configId
    //   );
    // }
    // if (filter.configName) {
    //   filteredList = filteredList.filter(
    //     (item: ConfigListType) =>
    //       filter.configName && item.configName?.includes(filter.configName)
    //   );
    // }
    // if (filter.sendStartDate && filter.sendEndDate) {
    //   filteredList = filteredList.filter(
    //     (item: ConfigListType) =>
    //       filter.sendStartDate &&
    //       filter.sendEndDate &&
    //       !(
    //         dayjs(item.sendStartDate).valueOf() >
    //           dayjs(filter.sendEndDate).valueOf() ||
    //         dayjs(item.sendEndDate).valueOf() <
    //           dayjs(filter.sendStartDate).valueOf()
    //       )
    //   );
    // }
    // if (filter.trainingStatus) {
    //   filteredList = filteredList.filter((item: ConfigListType) => {
    //     let startDate =
    //       (typeof item.sendStartDate === "string"
    //         ? parseInt(item.sendStartDate)
    //         : item.sendStartDate ?? 0) * 1000;
    //     let endDate =
    //       (typeof item.sendEndDate === "string"
    //         ? parseInt(item.sendEndDate)
    //         : item.sendEndDate ?? 0) * 1000;
    //     switch (filter.trainingStatus) {
    //       case 1:
    //         return startDate > dayjs().valueOf();
    //       case 2:
    //         return (
    //           startDate <= dayjs().valueOf() &&
    //           endDate >= dayjs().valueOf() &&
    //           item.activateFlag
    //         );
    //       case 3:
    //         return endDate < dayjs().valueOf();
    //       case 4:
    //         return (
    //           startDate <= dayjs().valueOf() &&
    //           endDate >= dayjs().valueOf() &&
    //           !item.activateFlag
    //         );
    //       default:
    //         return true;
    //     }
    //   });
    // }
    // setConfigList(filteredList);
    // setConfigListTotal(filteredList?.length ?? 0);
    // setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
  }, [filter]);

  const onActivate = (flag?: boolean) => {
    if (flag) {
      setIsDeactivateModalOpen(true);
    } else {
      setIsActivateModalOpen(true);
    }
  };

  const updateActivate = async () => {
    //update로 활성화
    const resp: any = await updateProgram({
      configId: selectedConfigs[0],
      activateFlag: true,
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programActivateSuccessMsg"),
        "success"
      );
      refreshList();
    } else {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programActivateFailMsg"),
        "error"
      );
    }
    setIsActivateModalOpen(false);
  };

  const updateDectivate = async () => {
    const resp: any = await updateProgram({
      configId: selectedConfigs[0],
      activateFlag: false,
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programDeactivateSuccessMsg"),
        "success"
      );
      refreshList();
    } else {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programDeactivateFailMsg"),
        "error"
      );
    }
    setIsDeactivateModalOpen(false);
  };

  const onFilterDate = (date: Date, type: string) => {
    const dateStr = date.toString();
    if (type === "start") {
      setCustomDate((prev) => {
        return {
          ...prev,
          startDate: utilsCommon.getFullDate(dateStr).slice(0, 10),
        };
      });
    } else if (type === "end") {
      setCustomDate((prev) => {
        return {
          ...prev,
          endDate: utilsCommon.getFullDate(dateStr).slice(0, 10),
        };
      });
    }
  };
  const handleChangeDateFilter = (item: ProgressType) => {
    let startTime = undefined;
    let endTime = undefined;

    switch (item.id) {
      case 0:
        startTime = undefined;
        endTime = undefined;
        break;
      case 1:
        startTime = dayjs().subtract(1, "M").valueOf() / 1000;
        endTime = dayjs().valueOf() / 1000;
        break;
      case 2:
        startTime = dayjs().subtract(3, "M").valueOf() / 1000;
        endTime = dayjs().valueOf() / 1000;
        break;
      case 3:
        startTime = dayjs().subtract(6, "M").valueOf() / 1000;
        endTime = dayjs().valueOf() / 1000;
        break;
      default:
        break;
    }
    setDateState(item);
    changeFilter({
      ...filter,
      sendStartDate: startTime,
      sendEndDate: endTime,
    });
  };
  const handleInitializeFilter = () => {
    changeFilter({
      orderId: "configId",
      orderType: "desc",
      configId: undefined,
      trainingStatus: undefined,
      configName: undefined,
      configNames: [],
      sendStartDate: undefined,
      sendEndDate: undefined,
    });
    setProgressState({
      id: 0,
      text: t("common.auth.all"),
    });
    setConfigState({
      id: 0,
      text: t("common.auth.all"),
    });
    setDateState({
      id: 0,
      text: t("common.auth.all"),
    });
    setTempConfigNames([]);
    setCustomDate({});
  };
  const onDelete = async () => {
    const resp: any = await deleteProgram(selectedConfigs[0]);

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programDeleteSuccessMsg"),
        "success"
      );
    } else {
      toast.toastMsg(nanoid(), t("program.msg.programDeleteFailMsg"), "error");
    }

    checkboxGroup.handleAllClick([]);
    setSelectedConfigs([]);
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    setProgressState((prev) => ({
      ...prev,
      text: progressStates.filter((p) => p.id === prev.id)[0].text,
    }));

    setConfigState((prev) => ({
      id: prev.id,
      text:
        prev.id === 0
          ? t("common.auth.all")
          : t("common.list.numOrder", { round: prev.id }),
    }));

    setDateState((prev) => ({
      ...prev,
      text:
        prev.id === 5
          ? prev.text
          : dateStates.filter((d) => d.id === prev.id)[0].text,
    }));
  }, [i18n.language]);
  // 필터 - 훈련 기간 - 사용자 지정 범위
  const customeDateModal = (
    <>
      {isFilterCalendarOpen && (
        <CommonModal
          open={isFilterCalendarOpen}
          onClose={() => setIsFilterCalendarOpen(false)}
          title={t("graph.phishingCustomScope")}
          width={320}
          body={
            <Box alignItems="center">
              <Box classes="mg mr-4">
                <Calendar
                  onSubmit={(date) => onFilterDate(date, "start")}
                  placeholder={t("common.info.startDate")}
                  dateFormat="yyyy-MM-dd"
                  width={128}
                  maxDate={
                    customDate && customDate?.endDate
                      ? new Date(customDate?.endDate)
                      : undefined
                  }
                  lang={i18n.language}
                  selected={
                    customDate?.startDate
                      ? new Date(customDate?.startDate)
                      : undefined
                  }
                />
              </Box>
              <Box style={{ color: "#717985" }} alignItems="center">
                ~
              </Box>
              <Box classes="mg ml-4">
                <Calendar
                  onSubmit={(date) => onFilterDate(date, "end")}
                  placeholder={t("common.info.endDate")}
                  dateFormat="yyyy-MM-dd"
                  width={128}
                  minDate={
                    customDate && customDate?.startDate
                      ? new Date(customDate?.startDate)
                      : undefined
                  }
                  lang={i18n.language}
                  selected={
                    customDate?.endDate
                      ? new Date(customDate?.endDate)
                      : undefined
                  }
                />
              </Box>
            </Box>
          }
          afterSubmitButton={() => {
            setDateState({ id: 5, text: t("graph.phishingCustomScope") });
            changeFilter({
              ...filter,
              sendStartDate:
                dayjs(customDate?.startDate)
                  .set("hour", 0)
                  .set("minute", 0)
                  .set("seconds", 0)
                  .valueOf() / 1000,
              sendEndDate:
                dayjs(customDate?.endDate)
                  .set("hour", 23)
                  .set("minute", 59)
                  .set("seconds", 59)
                  .valueOf() / 1000,
            });
          }}
          disabledConfirm={
            !customDate || !customDate?.startDate || !customDate?.endDate
          }
        ></CommonModal>
      )}
    </>
  );

  // 상세정보
  const [selectedConfig, setSelectedConfig] = useState<ConfigListType | null>();
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false);
  const onOpenDetail = (config: ConfigListType) => {
    setSelectedConfig(config);
    setIsDetailModalOpen(true);
  };

  // 등록/수정 모달
  const [isOpenRegitModal, setIsOpenRegitModal] = useState(false);

  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useLayoutEffect(() => {
    const tableContainer = document.querySelector(
      ".table-scroll"
    ) as HTMLElement;

    if (tableContainer) {
      const scrollbarWidth =
        tableContainer.offsetWidth - tableContainer.clientWidth;
      setScrollbarWidth(scrollbarWidth);
    }
  }, [configList]);

  return (
    <Box classes="main manageTrainList">
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath
            path={[t("menu.trainingManagement"), t("menu.training")]}
          />
          <Typography
            classes="mg mt-8"
            fontWeight={700}
            size={24}
            lineHeight="32px"
          >
            {t("menu.training")}
          </Typography>
        </Box>
        <Button
          startIcon={<Icons variant="common" label="plus" stroke="#252d38" />}
          color="secondary"
          onClick={() =>
            navigate(`${process.env.PUBLIC_URL}/manageTrain/write`)
          }
        >
          {t("program.add")}
        </Button>
      </Box>
      <Box classes="mg mt-8" justifyContent="space-between">
        <Box alignItems="center">
          <Typography fontWeight={700} size={16}>
            {t("program.tests")}
          </Typography>
          <Typography
            classes="mg ml-8"
            size={16}
            color="green"
            fontWeight={700}
          >
            {configListTotal ?? 0}
          </Typography>
        </Box>
        <Box>
          <Button
            startIcon={
              <Icons variant="common" label="filter" stroke="#252d38" />
            }
            color="transparent"
            onClick={() => {
              setIsFilterOpen((prev) => !prev);
            }}
          >
            {t("common.list.filter")}
          </Button>
        </Box>
      </Box>

      {/* filter start */}
      {isFilterOpen && (
        <Box classes="mg mt-12" alignItems="center">
          <SettingButton
            buttonClick={buttonClick}
            button={
              <Button
                color={configIdState.id === 0 ? "tertiary" : "tertiary-click"}
              >
                {t("program.configRound")} · {configIdState.text}
              </Button>
            }
            classes="mg mr-8"
            listPosition={"right"}
            height={300}
          >
            <DropdownItem
              isChecked={configIdState.id === 0}
              onClick={() => {
                setConfigState({
                  id: 0,
                  text: t("common.auth.all"),
                });
                changeFilter({ ...filter, configId: undefined });
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
              key={0}
            >
              {configIdState.id === 0 ? (
                <Icons variant="common" label="check" stroke="#3182f6" />
              ) : (
                <Box width={28} />
              )}
              {t("common.auth.all")}
            </DropdownItem>
            {configIdList &&
              configIdList.map((item: ConfigListType) => (
                <DropdownItem
                  isChecked={item.configId === configIdState.id}
                  onClick={() => {
                    setConfigState({
                      id: item.configId,
                      text: t("common.list.numOrder", { round: item.configId }),
                    });
                    changeFilter({ ...filter, configId: item.configId });
                    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                  }}
                  key={item.configId}
                >
                  {item.configId === configIdState.id ? (
                    <Icons variant="common" label="check" stroke="#3182f6" />
                  ) : (
                    <Box width={28} />
                  )}
                  {t("common.list.numOrder", { round: item.configId })}
                </DropdownItem>
              ))}
          </SettingButton>

          <SettingButton
            buttonClick={buttonClick}
            button={
              <Button
                color={progressState.id === 0 ? "tertiary" : "tertiary-click"}
              >
                {t("program.filter.status")} · {progressState.text}
              </Button>
            }
            classes="mg mr-8"
            listPosition={"right"}
          >
            {progressStates.map((item) => (
              <DropdownItem
                isChecked={item.id === progressState.id}
                onClick={() => {
                  setProgressState(item);
                  changeFilter({ ...filter, trainingStatus: item.id });
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
                key={item.id}
              >
                {item.id === progressState.id ? (
                  <Icons variant="common" label="check" stroke="#3182f6" />
                ) : (
                  <Box width={28} />
                )}
                {item.text}
              </DropdownItem>
            ))}
          </SettingButton>
          <SettingButton
            buttonClick={buttonClick}
            button={
              <Button
                color={
                  filter.configNames && filter.configNames.length > 0
                    ? "tertiary-click"
                    : "tertiary"
                }
                classes="mg mr-8"
              >
                {t("menu.trainingName")} ·{" "}
                {filter.configNames && filter.configNames.length > 0
                  ? filter.configNames.length
                  : t("common.auth.all")}
              </Button>
            }
            width={280}
            listPosition={"right"}
          >
            <Box
              alignItems="center"
              classes="setting-button-list-search"
              height={40}
            >
              <TextField
                borderNone
                placeholder={t("program.msg.programUndefinedNameMsg")}
                text={tempConfigName}
                hasText
                startLabel={
                  <Box justifyContent="center" alignItems="center" height={30}>
                    <Icons variant="mindsat" label="icon_search" width={20} />
                  </Box>
                }
                onChange={(e) => changeTempConfigName(e.target.value)}
                // onKeyPress={(e: any) => {
                //   if (e.key === "Enter") {
                //     changeFilter({ ...filter, configName: tempConfigName });
                //     changeTempConfigName("");
                //   }
                // }}
                onKeyPress={(e: any) => {
                  if (e.key === "Enter") {
                    if (e.target.value === "") return;
                    if (
                      filter.configNames &&
                      filter.configNames.includes(e.target.value)
                    ) {
                      return;
                    }
                    setTempConfigNames((prev) => [...prev, tempConfigName]);
                    changeTempConfigName("");
                  }
                }}
              />
            </Box>
            <Box classes="filter__tag__container">
              {tempConfigNames &&
                tempConfigNames.map((item) => (
                  <CategoryTag
                    key={item}
                    text={item}
                    endIcon={<Icons variant="common" label="delete" />}
                    onClick={() =>
                      setTempConfigNames((prev) =>
                        prev.filter((q) => q !== item)
                      )
                    }
                  />
                ))}
            </Box>
            <Box
              alignItems="center"
              classes="setting-button-list-footer"
              justifyContent="flex-end"
            >
              {/* <IconButton
                unfilled
                onClick={() => {
                  changeFilter({ ...filter, configName: "" });
                  changeTempConfigName("");
                }}
              >
                <Icons variant="common" label="refresh" stroke="#B5BBC2" />
              </IconButton> */}

              <Box>
                <Button
                  text={t("common.button.cancel")}
                  color="secondary"
                  onClick={() => {
                    changeTempConfigName(filter.configName ?? "");
                    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                  }}
                />
                <Button
                  text={t("common.button.apply")}
                  classes={"mg ml-8"}
                  color="primary"
                  onClick={() => {
                    if (tempConfigName !== "") {
                      setTempConfigNames((prev) => [...prev, tempConfigName]);
                      changeFilter((prev) => ({
                        ...prev,
                        configNames: [...tempConfigNames, tempConfigName],
                      }));
                    } else {
                      changeFilter((prev) => ({
                        ...prev,
                        configNames: tempConfigNames,
                      }));
                    }
                    changeTempConfigName("");

                    setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                  }}
                />
              </Box>
            </Box>
          </SettingButton>
          <SettingButton
            buttonClick={buttonClick}
            button={
              <Button
                color={dateState.id === 0 ? "tertiary" : "tertiary-click"}
              >
                {t("program.trainingDuration")}·{" "}
                {dateState.id === 5
                  ? `${utilsCommon.getShortDate(
                      filter.sendStartDate
                    )} - ${utilsCommon.getShortDate(filter.sendEndDate)}`
                  : dateState.text}
              </Button>
            }
            classes="mg mr-8"
            listPosition="right"
          >
            {dateStates.map((item) => (
              <DropdownItem
                isChecked={item.id === dateState.id}
                onClick={() => {
                  handleChangeDateFilter(item);
                  setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
                }}
                key={item.id}
              >
                {item.id === dateState.id ? (
                  <Icons variant="common" label="check" stroke="#3182f6" />
                ) : (
                  <Box width={28} />
                )}
                {item.text}
              </DropdownItem>
            ))}
            <Divider direction="row" classes="mg mt-4 mb-4" />
            <DropdownItem
              isChecked={dateState.id === 5}
              onClick={() => {
                setIsFilterCalendarOpen((prev) => !prev);
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
            >
              {dateState.id === 5 ? (
                <Icons variant="common" label="check" stroke="#3182f6" />
              ) : (
                <Box width={28} />
              )}
              {t("graph.phishingCustomScope")}
            </DropdownItem>
          </SettingButton>
          {customeDateModal}
          <IconButton
            transparent
            onClick={handleInitializeFilter}
            classes="mg ml-8"
          >
            <Icons variant="common" label="refresh" stroke="#B5BBC2" />
          </IconButton>
        </Box>
      )}
      {/* filter  end  */}

      <Box
        direction="column"
        height={isFilterOpen ? "calc(100% - 156px)" : "calc(100% - 104px)"}
      >
        {configList?.length > 0 ? (
          <>
            <Table classes="default-table mg mt-12">
              <colgroup>
                {/*  */}
                <col style={{ width: "20px" }}></col>
                {/* 구분 */}
                <col style={{ width: "100px" }}></col>
                {/* 회차 */}
                <col style={{ width: "64px" }}></col>
                {/* 훈련 이름 */}
                <col style={{ width: "100%" }}></col>
                {/* 훈련 기간 */}
                <col style={{ width: "250px" }}></col>
                {/* 진행률 (%) */}
                <col style={{ width: "120px" }}></col>
                {/* 대상자 수 */}
                <col style={{ width: "80px" }}></col>
                {/* 메일 발송 수 */}
                <col style={{ width: "88px" }}></col>
                {/* 시나리오 수 */}
                <col style={{ width: "88px" }}></col>
                {/* 활성화 */}
                <col style={{ width: "80px" }}></col>
                {/*  */}
                <col
                  style={{ width: `calc(48px + ${scrollbarWidth}px)` }}
                ></col>
              </colgroup>
              <thead>
                <tr>
                  <th>
                    {/* <Checkbox
                      checked={
                        checkboxGroup.selected.length === configList?.length
                      }
                      onChange={() =>
                        checkboxGroup.handleAllClick(
                          checkboxGroup.selected.length === configList?.length
                            ? []
                            : configList?.map((item) => item.configId)
                        )
                      }
                    ></Checkbox> */}
                  </th>
                  <th>
                    {/* {renderTableHeaderWithSort("real_training_flag", "구분")} */}
                    {t("template.type")}
                  </th>
                  <th>{t("program.configRound")}</th>
                  <th>{t("program.programName")}</th>
                  <th>{t("program.trainingDuration")}</th>
                  <th>{t("program.progressStatus")}</th>
                  <th>{t("dashboard.user")}</th>
                  <th>{t("program.sendMail")}</th>
                  <th>{t("template.templateSimple")}</th>
                  <th>{t("program.programActivate")}</th>
                  <th></th>
                </tr>
              </thead>
            </Table>
            <Table classes="table-scroll">
              <colgroup>
                {/*  */}
                <col style={{ width: "20px" }}></col>
                {/* 구분 */}
                <col style={{ width: "100px" }}></col>
                {/* 회차 */}
                <col style={{ width: "64px" }}></col>
                {/* 훈련 이름 */}
                <col style={{ width: "100%" }}></col>
                {/* 훈련 기간 */}
                <col style={{ width: "250px" }}></col>
                {/* 진행률 (%) */}
                <col style={{ width: "120px" }}></col>
                {/* 대상자 수 */}
                <col style={{ width: "80px" }}></col>
                {/* 메일 발송 수 */}
                <col style={{ width: "88px" }}></col>
                {/* 시나리오 수 */}
                <col style={{ width: "88px" }}></col>
                {/* 활성화 */}
                <col style={{ width: "80px" }}></col>
                {/*  */}
                <col style={{ width: "48px" }}></col>
              </colgroup>
              <tbody className="roundTable">
                {configList?.length > 0 &&
                  configList?.map((config: ConfigListType) => {
                    const startTimeNum =
                      typeof config.sendStartDate === "string"
                        ? parseInt(config.sendStartDate)
                        : config.sendStartDate;
                    const endTimeNum =
                      typeof config.sendEndDate === "string"
                        ? parseInt(config.sendEndDate)
                        : config.sendEndDate;
                    const isStart =
                      startTimeNum && startTimeNum * 1000 > dayjs().valueOf();

                    const isEnd =
                      endTimeNum && endTimeNum * 1000 < dayjs().valueOf();

                    return (
                      <tr
                        onClick={() => onOpenDetail(config)}
                        className="cursor-pointer"
                        key={config.configId}
                      >
                        <td>
                          {/* <Checkbox
                          checked={checkboxGroup.isSelected(config.configId)}
                          onChange={() =>
                            checkboxGroup.handleClick(config.configId)
                          }
                        ></Checkbox> */}
                        </td>
                        <td>
                          {config.realTrainingFlag ? (
                            <Tag
                              name={t("program.realTrainingLabel")}
                              isBgFilled={false}
                              size={i18n.language === "ko" ? "ms" : "sm"}
                              color="green"
                              radius={6}
                            />
                          ) : (
                            <Tag
                              name={t("program.mockTrainingLabel")}
                              isBgFilled={false}
                              size={i18n.language === "ko" ? "ms" : "sm"}
                              color="gray"
                              radius={6}
                            />
                          )}
                        </td>
                        <td>{config.configId}</td>
                        <td>
                          <Box width={"100%"}>
                            <ToolTip
                              message={config.configName}
                              maxWidth={"90%"}
                            >
                              {config.quizType ? (
                                <Typography lineHeight="normal">
                                  {`[${t("quiz.table.trainingQuiz")}] `}
                                </Typography>
                              ) : (
                                ``
                              )}
                              <Typography ellipsis lineHeight="normal">
                                {config.configName}
                              </Typography>
                            </ToolTip>
                          </Box>
                        </td>
                        <td>{`${utilsCommon.getFullDateStr(
                          config.sendStartDate
                        )} ~ ${utilsCommon.getFullDateStr(
                          config.sendEndDate
                        )}`}</td>
                        <td>
                          {config.sendCount === 0
                            ? config.startedFlag ||
                              (config.sendStartDate &&
                                dayjs(config.sendStartDate).valueOf() * 1000 <
                                  dayjs().valueOf())
                              ? "0%"
                              : t("program.programSendWait")
                            : config.sendCount &&
                              config.userCount &&
                              config.sendMailCount
                            ? `${(
                                (config.sendCount /
                                  (config.userCount * config.sendMailCount)) *
                                100
                              ).toFixed(0)} %`
                            : t("program.programSendWait")}
                        </td>
                        <td>
                          {config.userCount}
                          {t("program.programPeopleUnit")}
                        </td>
                        <td>
                          {config.sendMailCount}
                          {t("program.programDataUnit")}
                        </td>
                        <td>
                          {config.templateCount}
                          {t("program.programTemplateUnit")}
                        </td>
                        <td>
                          {(config?.userCount &&
                            config?.sendMailCount &&
                            config.sendCount ===
                              config?.userCount * config?.sendMailCount) ||
                          dayjs(config?.sendEndDate).valueOf() * 1000 <
                            dayjs().valueOf() ? (
                            <Tag
                              name={t("program.finished")}
                              isBgFilled
                              size="sm"
                              style={{ fontSize: 12 }}
                              color="gray"
                              radius={6}
                            />
                          ) : (
                            <ToolTip
                              message={
                                config.activateFlag
                                  ? t("program.programActivateHeader")
                                  : t("program.programDeactivateHeader")
                              }
                            >
                              <Switch
                                checked={config.activateFlag}
                                onChange={() => {
                                  setSelectedConfigs([config.configId]);
                                  onActivate(config.activateFlag);
                                }}
                              />
                            </ToolTip>
                          )}
                        </td>
                        <td>
                          {!(
                            (config?.userCount &&
                              config?.sendMailCount &&
                              config.sendCount ===
                                config?.userCount * config?.sendMailCount) ||
                            isEnd
                          ) || isStart ? (
                            <SettingButton buttonClick={buttonClick}>
                              <SettingChildren
                                editEvent={
                                  !(
                                    (config?.userCount &&
                                      config?.sendMailCount &&
                                      config.sendCount ===
                                        config?.userCount *
                                          config?.sendMailCount) ||
                                    isEnd
                                  )
                                    ? () => (
                                        // getProgramDetail(config.configId),
                                        setSelectedConfig(config),
                                        navigate(
                                          `${process.env.PUBLIC_URL}/manageTrain/write?config_id=${config.configId}`
                                        )
                                      )
                                    : undefined
                                }
                                deleteEvent={() => (
                                  setSelectedConfigs([config?.configId]),
                                  setIsDeleteModalOpen(true)
                                )}
                              />
                            </SettingButton>
                          ) : (
                            <SettingButton buttonClick={buttonClick}>
                              <SettingChildren
                                editEvent={undefined}
                                deleteEvent={() => (
                                  setSelectedConfigs([config?.configId]),
                                  setIsDeleteModalOpen(true)
                                )}
                              />
                            </SettingButton>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </>
        ) : !filter.trainingStatus &&
          !filter.sendStartDate &&
          !filter.sendEndDate &&
          filter.configNames &&
          filter.configNames.length === 0 ? (
          <Box
            width="100%"
            justifyContent="center"
            height="100%"
            alignItems="center"
            direction="column"
          >
            <NoTemplateImage />
            <Typography color="secondary" size={16} classes="mg mt-24 mb-24">
              {t("program.msg.noTrainingNewMsg")}
            </Typography>
            <Button
              startIcon={
                <Icons variant="common" label="plus" stroke="#FFFFFF" />
              }
              onClick={() => {
                setSelectedConfig(null);
                // setIsOpenRegitModal(true);
                navigate(`${process.env.PUBLIC_URL}/manageTrain/write`);
              }}
            >
              {t("menu.createTraining")}
            </Button>
          </Box>
        ) : (
          <Box
            width={"100%"}
            height={"100%"}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <NoInfoImage />
            <Typography
              size={16}
              classes="mg mt-16 mb-16"
              lineHeight="24px"
              color={"secondary"}
            >
              {t("program.msg.noFilterMsg")}
            </Typography>
            <Button
              startIcon={
                <Icons variant="common" label="refresh" stroke="white" />
              }
              onClick={handleInitializeFilter}
            >
              {t("common.button.resetFilter")}
            </Button>
          </Box>
        )}
        {/* detail start */}
        {selectedConfig && isDetailModalOpen && (
          <TrainingDetailModal
            isOpen={isDetailModalOpen}
            onClose={() => {
              setSelectedConfig(undefined);
              setIsDetailModalOpen(false);
            }}
            refreshList={refreshList}
            configId={selectedConfig.configId}
          />
        )}
        {/* detail end */}
      </Box>
      {isOpenRegitModal && (
        <RegitTrainingModal
          isOpen={isOpenRegitModal}
          onClose={() => {
            setSelectedConfig(undefined);
            setIsOpenRegitModal(false);
          }}
          refreshList={refreshList}
          configId={selectedConfig && selectedConfig.configId}
        />
      )}
      {isDeleteModalOpen && (
        <CommonModal
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          title={t("program.delete")}
          afterSubmitButton={onDelete}
          subMessage={t("program.msg.deleteConfirmMsg")}
          type="delete"
        />
      )}
      <CommonModal
        open={isActivateModalOpen}
        onClose={() => setIsActivateModalOpen(false)}
        title={t("program.programActivateHeader")}
        afterSubmitButton={updateActivate}
        body={
          <Box direction="column" justifyContent="flex-start" width={"100%"}>
            <Typography color={"secondary"} size={14} width="200">
              {t("program.msg.programActivateConfrimMsg")}
            </Typography>
            {/* <Typography color={"secondary"} size={14} classes="mg mt-10">
              {t("program.msg.programDeactivateInfoMsg")}
            </Typography> */}
          </Box>
        }
        footer={
          <Box justifyContent="flex-end">
            <Button
              text={t("common.button.cancel")}
              color="secondary"
              onClick={() => setIsActivateModalOpen(false)}
            />
            <Button
              text={t("program.programActivate")}
              classes={"mg ml-8"}
              color="primary"
              onClick={updateActivate}
            />
          </Box>
        }
      />

      <CommonModal
        open={isDeactivateModalOpen}
        onClose={() => setIsDeactivateModalOpen(false)}
        title={t("program.programDeactivateHeader")}
        afterSubmitButton={updateDectivate}
        footer={
          <Box justifyContent="flex-end">
            <Button
              text={t("common.button.cancel")}
              color="secondary"
              onClick={() => setIsDeactivateModalOpen(false)}
            />
            <Button
              text={t("program.programDeactive")}
              classes={"mg ml-8"}
              color="primary"
              onClick={updateDectivate}
            />
          </Box>
        }
        body={
          <Box direction="column" justifyContent="flex-start" width="100%">
            <Typography color={"secondary"} size={14}>
              {t("program.msg.programDeactivateConfrimMsg")}
            </Typography>
            <Typography color={"secondary"} size={14} classes="mg mt-10">
              {t("program.msg.programDeactivateInfoMsg")}
            </Typography>
          </Box>
        }
      />
    </Box>
  );
};

export default ManageTrainList;
