import "./index.scss";
import {
  Accordion,
  AccordionItem,
  Box,
  Button,
  Calendar,
  Checkbox,
  Divider,
  Icons,
  ScrollBox,
  Select,
  Switch,
  Table,
  Tag,
  TextField,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import TitlePath from "../../../component/TitlePath";
import { useEffect, useState } from "react";
import { QuizContentDTO, QuizCourseDTO } from "../../../types/Quiz";
import NoQuizImage from "../../../shared/image/NoQuizImage";
import QuizModal from "./QuizModal";
import { useTranslation } from "react-i18next";
import UserModal from "./UserModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import { SimpleTreeNode, UserType } from "../../../types/User";
import useDeptStore from "../../../redux/dispatcher/useDeptStore";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
import NoSelectedUserImage from "../../../shared/image/NoSelectedUserImage";
import CategoryTagList from "../../../component/CategoryTag/CategoryTagList";
import { nanoid } from "@reduxjs/toolkit";
import { SelectItem } from "fasoo-ui-component-next/src/components/atom/Select";
import { DeptType } from "../../../types/Dept";
import OrganCard from "../../../component/OrganCard";

const WriteQuizCourse: React.FC = () => {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const courseId = searchParams.get("course_id");
  const navigate = useNavigate();
  const [course, setCourse] = useState<QuizCourseDTO>({
    courseName: "",
    activateFlag: false,
    quizSolvePeriod: 7,
  });
  const [type, changeType] = useState<"CREATE" | "UPDATE">("CREATE");

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startDateTime, setStartDateTime] = useState<string | null>(null);
  const [endDateTime, setEndDateTime] = useState<string | null>(null);
  const [isAllDayDate, setIsAllDayDate] = useState<boolean>(false);
  // const [startTime, setStartTime] = useState<string>("");
  // const [endTime, setEndTime] = useState<string>("");
  const [isAllDayTime, setIsAllDayTime] = useState<boolean>(false);
  const [isStarted, changeIsStared] = useState(false);

  const [isUserModalOpen, setIsUserModalOpen] = useState<boolean>(false);
  const [isQuizModalOpen, setIsQuizModalOpen] = useState<boolean>(false);

  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [selectedDeptIds, setSelectedDeptIds] = useState<string[]>([]);
  const [selectedUserInfo, setSelectedUserInfo] = useState<UserType[]>([]);
  const [selectedDeptInfo, setSelectedDeptInfo] = useState<DeptType[]>([]);

  const [selectedQuizList, setSelectedQuizList] = useState<QuizContentDTO[]>(
    []
  );

  const [userList, setUserList] = useState<UserType[]>();

  const userCheckboxGroup = useCheckboxGroup<string>();
  const deptCheckboxGroup = useCheckboxGroup<string>();
  const [organTree, changeOrganTree] = useState<SimpleTreeNode[] | undefined>();

  const { deptState, getDeptList } = useDeptStore();
  const {
    insertQuizCourse,
    updateQuizCourse,
    getSelectedOrgan,
    getQuizCourseDetail,
    getQuizContentList,
    quizState,
  } = useQuizStore();

  useEffect(() => {
    getDeptList();
    userCheckboxGroup.initSelected(selectedUserIds);
    deptCheckboxGroup.initSelected(selectedDeptIds);

    if (courseId) {
      changeType("UPDATE");
      getQuizCourseDetail({ courseId: parseInt(courseId) });
    }
  }, []);

  const initializeUserCheckbox = async () => {
    let resp: any = await getDeptList(selectedDeptIds);
    if (resp.payload) {
      let newUsers =
        resp.payload?.data?.list?.map(
          (item: SimpleTreeNode) => item.deptCode
        ) ?? [];
      newUsers = newUsers.concat(
        selectedUserIds.filter((item: string) => item)
      );
      userCheckboxGroup.handleAllClick(
        newUsers.filter((item: string, index: number) => {
          return newUsers.indexOf(item) === index;
        })
      );
    }
  };

  useEffect(() => {
    if (courseId && quizState.quizCourse) {
      setCourse({
        ...quizState.quizCourse,
      });

      setStartDate(new Date(quizState.quizCourse.sendStartDate * 1000));
      setEndDate(new Date(quizState.quizCourse.sendEndDate * 1000));

      if (quizState.quizCourse.deptIdTemp !== "") {
        setSelectedDeptIds(quizState.quizCourse.deptIds);
      }
      if (quizState.quizCourse.userIdTemp !== "") {
        setSelectedUserIds(quizState.quizCourse.userIds);
      }

      changeIsStared(quizState.quizCourse.startedFlag);

      if (quizState.quizCourseUserList) {
        setUserList(quizState.quizCourseUserList);
      }

      getQuizContentList({});

      getOrganInfo();
    }
  }, [quizState.quizCourse]);

  useEffect(() => {
    if (courseId && quizState.quizCourse) {
      if (
        quizState.quizContentList &&
        quizState.quizContentList.list.length > 0
      ) {
        setSelectedQuizList(
          quizState.quizContentList.list.filter((t: QuizContentDTO) =>
            quizState.quizCourse.quizIds.some(
              (q: string) => parseInt(q) === t.quizId
            )
          )
        );
      }
    }
  }, [quizState.quizContentList]);

  useEffect(() => {
    changeOrganTree(deptState.organList);
  }, [deptState.organList]);

  const getOrganInfo = async () => {
    const resp: any = await getSelectedOrgan({
      userIds: selectedUserIds,
      deptCodes: selectedDeptIds,
    });

    setSelectedDeptInfo(resp.payload.data.deptList);
    setSelectedUserInfo(resp.payload.data.userList);
    userCheckboxGroup.handleAllClick(selectedUserIds);
    deptCheckboxGroup.handleAllClick(selectedDeptIds);
    initializeUserCheckbox();
  };

  useEffect(() => {
    if (selectedDeptIds.length !== 0 || selectedUserIds.length !== 0) {
      getOrganInfo();
    }
  }, [selectedUserIds, selectedDeptIds]);

  const onSubmit = async () => {
    //validation
    if (!startDate || !endDate) {
      toast.toastMsg(
        nanoid(),
        t("program.msg.notSelectedDurationMsg"),
        "error"
      );

      return;
    }

    const tempStartDate = new Date(dayjs(startDate).valueOf());
    const tempEndDate = new Date(dayjs(endDate).valueOf());

    // 시나리오
    if (isAllDayDate) {
      tempStartDate.setHours(0);
      tempStartDate.setMinutes(0);
      tempStartDate.setSeconds(0);
      tempEndDate.setHours(23);
      tempEndDate.setMinutes(59);
      tempEndDate.setSeconds(59);
    }

    if (startDate >= endDate) {
      toast.toastMsg(nanoid(), t("quiz.msg.courseEndBeforeStart"), "error");
      return;
    }
    if (
      (!isStarted && dayjs(tempStartDate).valueOf() < dayjs().valueOf()) ||
      dayjs(tempEndDate).valueOf() < dayjs().valueOf()
    ) {
      toast.toastMsg(nanoid(), t("quiz.msg.courseNowFail"), "error");
      return;
    }

    // if (!isAllDayTime && startTime >= endTime) {
    //   toast.toastMsg(
    //     nanoid(),
    //     t("program.msg.programStartTimeExceedMsg"),
    //     "error"
    //   );
    //   return;
    // }

    if (course.courseName && startDate && endDate && course.sendMailCount) {
      if (courseId) {
        const params = {
          courseId: parseInt(courseId),
          courseName: course.courseName,
          sendStartDate: tempStartDate.getTime() / 1000,
          sendEndDate: tempEndDate.getTime() / 1000,
          sendMailCount: course.sendMailCount,
          templateId: 1,
          deptIds: selectedDeptIds,
          userIds: organTree
            ?.filter(
              (item) =>
                item.type === "user" &&
                userCheckboxGroup.selected.includes(item.deptCode) &&
                !deptCheckboxGroup.selected.includes(item.parentCode)
            )
            .map((item1) => item1?.deptCode),
          quizIds: selectedQuizList
            .filter((q) => q.quizId != null)
            .map((item) => (item.quizId ? item.quizId.toString() : "")),
          activateFlag: course.activateFlag,
          quizCount: course.quizCount,
          quizSolvePeriod: course.quizSolvePeriod,
        };

        const resp: any = await updateQuizCourse(params);

        if (resp.payload && resp.payload.code === "SUCCESS") {
          toast.toastMsg(
            nanoid(),
            t("quiz.msg.updateQuizCourseSuccess"),
            "success"
          );
          navigate(`${process.env.PUBLIC_URL}/quiz/course`);
        } else {
          toast.toastMsg(nanoid(), t("quiz.msg.addQuizCourseFail"), "error");
        }
      } else {
        const params = {
          courseName: course.courseName,
          sendStartDate: tempStartDate.getTime() / 1000,
          sendEndDate: tempEndDate.getTime() / 1000,
          sendMailCount: course.sendMailCount,
          templateId: 1,
          deptIds: selectedDeptIds,
          userIds: organTree
            ?.filter(
              (item) =>
                item.type === "user" &&
                userCheckboxGroup.selected.includes(item.deptCode) &&
                !deptCheckboxGroup.selected.includes(item.parentCode)
            )
            .map((item1) => item1?.deptCode),
          quizIds: selectedQuizList
            .filter((q) => q.quizId != null)
            .map((item) => (item.quizId ? item.quizId.toString() : "")),
          activateFlag: course.activateFlag,
          quizCount: course.quizCount,
          quizSolvePeriod: course.quizSolvePeriod,
        };

        //   if (courseId) {

        //   } else {
        const resp: any = await insertQuizCourse(params);

        if (resp.payload && resp.payload.code === "SUCCESS") {
          toast.toastMsg(
            nanoid(),
            t("quiz.msg.addQuizCourseSuccess"),
            "success"
          );
          navigate(`${process.env.PUBLIC_URL}/quiz/course`);
        } else {
          toast.toastMsg(nanoid(), t("quiz.msg.addQuizCourseFail"), "error");
        }

        //   }
      }
    }
  };

  const initializeDate = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  };

  const handleDate = (key: string, type: string, target: "hour" | "min") => {
    if (type === "start") {
      setStartDateTime(key);
      const tempDate = startDate ?? initializeDate();
      target === "hour" && tempDate.setHours(parseInt(key, 10));
      target === "min" && tempDate.setMinutes(parseInt(key, 10));
      tempDate.setMilliseconds(0);
      // console.log(2);

      setStartDate(tempDate);
    } else {
      setEndDateTime(key);
      const tempDate = endDate ? endDate : initializeDate();
      target === "hour" && tempDate.setHours(parseInt(key, 10));
      target === "min" && tempDate.setMinutes(parseInt(key, 10));
      tempDate.setMilliseconds(0);
      setEndDate(tempDate);
    }
  };

  return (
    <ScrollBox classes="main">
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath path={[t("menu.quizTitle"), t("menu.quizCourse")]} />
          <Typography
            classes="mg mt-8"
            fontWeight={700}
            size={24}
            lineHeight="32px"
          >
            {/* 퀴즈 코스 생성 */}
            {type === "UPDATE"
              ? t("quiz.label.editQuizCourse")
              : t("quiz.label.addQuizCourse")}
          </Typography>
        </Box>
      </Box>

      {/* main start */}
      <Box classes="mg mt-24" direction="column">
        {/* basic info start  */}
        <Box>
          <Accordion>
            <AccordionItem
              title={t("quiz.label.basicInfo")}
              expandedProp={true}
              type="CLOSEINFO"
              classes="training__accordian"
              iconLeft={true}
            >
              <Divider direction="row" classes="mg mb-28" />
              <Box alignItems="center" classes="mg mb-28 ml-16" height={40}>
                <Box width={220} classes="mg mr-28">
                  <Typography color="secondary" fontWeight={700}>
                    {t("quiz.label.courseTitle")}
                  </Typography>
                </Box>
                <TextField
                  width={598}
                  height={40}
                  maxLength={128} // 시나리오 제목 길이 사용
                  borderRadius={8}
                  placeholder={t("quiz.label.courseTitleHolder")}
                  text={course.courseName}
                  hasText
                  onChange={(e) =>
                    setCourse((prev) => ({
                      ...prev,
                      courseName: e.target.value,
                    }))
                  }
                  disabled={isStarted}
                />
              </Box>

              <Box classes="mg mb-24 ml-16" height={92}>
                <Box width={220} classes="mg mr-28">
                  <Typography color="secondary" fontWeight={700}>
                    {t("quiz.label.period")}
                  </Typography>
                </Box>
                <Box
                  direction="column"
                  height={92}
                  justifyContent="space-between"
                >
                  <Box alignItems="center">
                    <Box classes="mg mr-18">
                      <Typography>{t("quiz.label.startDate")}</Typography>
                    </Box>
                    <Calendar
                      onSubmit={(date) => setStartDate(date)}
                      height={40}
                      width={325}
                      minDate={new Date(dayjs().subtract(1, "D").valueOf())}
                      maxDate={endDate ? endDate : undefined}
                      selected={startDate ? startDate : undefined}
                      dateFormat="yyyy-MM-dd"
                      lang={i18n.language}
                      placeholder={t("quiz.label.selectDate")}
                      disable={isStarted}
                    />
                    <Select
                      width={100}
                      height={40}
                      classes="mg ml-8"
                      disabled={isAllDayDate || isStarted}
                      selectedEventKey={dayjs(startDate).get("hour") + ""}
                      onClick={(key: string) =>
                        handleDate(key, "start", "hour")
                      }
                    >
                      {Array(24)
                        .fill("")
                        .map((item, index) => (
                          <SelectItem eventKey={index + ""}>
                            {index < 10 ? "0" + index : index}
                          </SelectItem>
                        ))}
                    </Select>
                    <Select
                      width={100}
                      classes="mg ml-8"
                      height={40}
                      disabled={isAllDayDate || isStarted}
                      selectedEventKey={dayjs(startDate).get("minute") + ""}
                      onClick={(key: string) => handleDate(key, "start", "min")}
                    >
                      {Array(60)
                        .fill("")
                        .map((item, index) => (
                          <SelectItem eventKey={index + ""}>
                            {index < 10 ? "0" + index : index}
                          </SelectItem>
                        ))}
                    </Select>
                  </Box>
                  <Box alignItems="center">
                    <Box classes="mg mr-18">
                      <Typography>{t("quiz.label.endDate")}</Typography>
                    </Box>
                    <Calendar
                      onSubmit={(date) => setEndDate(date)}
                      dateFormat="yyyy-MM-dd"
                      minDate={
                        startDate ??
                        new Date(dayjs().subtract(1, "D").valueOf())
                      }
                      selected={endDate ? endDate : undefined}
                      height={40}
                      width={325}
                      placeholder={t("quiz.label.selectDate")}
                      disable={isStarted}
                    />
                    <Select
                      width={100}
                      height={40}
                      classes="mg ml-8"
                      selectedEventKey={dayjs(endDate).get("hour") + ""}
                      disabled={isAllDayDate || isStarted}
                      onClick={(key: string) => handleDate(key, "end", "hour")}
                    >
                      {Array(24)
                        .fill("")
                        .map((item, index) => (
                          <SelectItem eventKey={index + ""}>
                            {index < 10 ? "0" + index : index}
                          </SelectItem>
                        ))}
                    </Select>
                    <Select
                      width={100}
                      classes="mg ml-8"
                      height={40}
                      disabled={isAllDayDate || isStarted}
                      selectedEventKey={dayjs(endDate).get("minute") + ""}
                      onClick={(key: string) => handleDate(key, "end", "min")}
                    >
                      {Array(60)
                        .fill("")
                        .map((item, index) => (
                          <SelectItem eventKey={index + ""}>
                            {index < 10 ? "0" + index : index}
                          </SelectItem>
                        ))}
                    </Select>
                  </Box>
                </Box>
              </Box>

              <Box classes="mg mb-28 ml-16">
                <Box width={220} classes="mg mr-28" alignItems="center">
                  <Typography color="secondary" fontWeight={700}>
                    {t("quiz.label.solvePeriod")}
                  </Typography>
                </Box>

                <Box alignItems="center">
                  <Select
                    width={66}
                    height={40}
                    classes="mg mr-8"
                    disabled={isAllDayDate || isStarted}
                    selectedEventKey={course.quizSolvePeriod + ""}
                    onClick={(key: string) =>
                      setCourse((prev) => ({
                        ...prev,
                        quizSolvePeriod: parseInt(key),
                      }))
                    }
                    title={
                      course.quizSolvePeriod !== undefined
                        ? course.quizSolvePeriod + ""
                        : "7"
                    }
                  >
                    {Array(8)
                      .fill("")
                      .map((item, index) => (
                        <SelectItem eventKey={index + ""}>{index}</SelectItem>
                      ))}
                  </Select>
                  일
                </Box>
              </Box>

              <Box classes="mg mb-28 ml-16" height={42}>
                <Box
                  direction="column"
                  justifyContent="space-between"
                  width={220}
                  classes="mg mr-28"
                >
                  <Typography color="secondary" fontWeight={700}>
                    {t("quiz.label.emailsPerTarget")}
                  </Typography>
                  <Typography color="secondary" size={12}>
                    {t("quiz.label.valueRange")}: 1-99
                  </Typography>
                </Box>

                <TextField
                  width={66}
                  height={40}
                  borderRadius={8}
                  type="number"
                  hasText
                  maxLength={2}
                  text={course.sendMailCount?.toString()}
                  onChange={(e) =>
                    setCourse((prev) => ({
                      ...prev,
                      sendMailCount: parseInt(e.target.value),
                    }))
                  }
                  disabled={isStarted}
                />

                <Box classes="mg ml-8" alignItems="center">
                  <Typography>{t("quiz.label.ea1")}</Typography>
                </Box>
              </Box>

              <Box classes="mg mb-28 ml-16" height={42}>
                <Box
                  direction="column"
                  justifyContent="space-between"
                  width={220}
                  classes="mg mr-28"
                >
                  <Typography color="secondary" fontWeight={700}>
                    {t("quiz.label.quizPerMail")}
                  </Typography>
                  <Typography color="secondary" size={12}>
                    {t("quiz.label.valueRange")}: 1-10
                  </Typography>
                </Box>

                <TextField
                  width={66}
                  height={40}
                  borderRadius={8}
                  maxLength={2}
                  text={course.quizCount?.toString()}
                  hasText
                  onChange={(e) => {
                    if (
                      !e.target.value ||
                      (parseInt(e.target.value) <= 10 &&
                        parseInt(e.target.value) >= 0)
                    )
                      setCourse((prev) => ({
                        ...prev,
                        quizCount: e.target.value
                          ? parseInt(e.target.value)
                          : 0,
                      }));
                  }}
                  disabled={isStarted}
                />
                <Box classes="mg ml-8" alignItems="center">
                  <Typography>{t("quiz.label.ea1")}</Typography>
                </Box>
              </Box>

              <Box classes="mg mb-28 ml-16" height={42}>
                <Box width={220} classes="mg mr-28">
                  <Typography color="secondary" fontWeight={700}>
                    {t("quiz.label.courseStatus")}
                  </Typography>
                </Box>
                <Switch
                  checked={course.activateFlag}
                  onChange={() =>
                    setCourse((prev) => ({
                      ...prev,
                      activateFlag: !prev.activateFlag,
                    }))
                  }
                  label={course.activateFlag ? "ON" : "OFF"}
                />
              </Box>

              <Box></Box>
            </AccordionItem>
          </Accordion>
        </Box>
        {/* basic info end   */}

        {/* user info start  */}
        <Box>
          <Accordion>
            <AccordionItem
              title={t("quiz.label.targets")}
              expandedProp={true}
              type="CLOSEINFO"
              classes="training__accordian"
              iconLeft={true}
            >
              <Divider direction="row" classes="mg mb-28" />
              <Box>
                <Box classes="mg mb-28 ml-16">
                  <Box width={220} classes="mg mr-28">
                    <Typography color="secondary" fontWeight={700}>
                      {t("quiz.label.user")}
                    </Typography>
                  </Box>
                  {/* {isStarted ? (
                    <Box>
                      <Box
                        classes="mg ml-8"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 300px)",
                        }}
                      >
                        {userList &&
                          userList.map((u) => (
                            <Box alignItems="center" width={292} height={32}>
                              <Icons
                                variant="chat"
                                label="user"
                                stroke="#5E92F5"
                              />
                              <Box classes="mg ml-4">
                                <Typography>{`${u.userName} (${u.userEmail})`}</Typography>
                              </Box>
                            </Box>
                          ))}
                      </Box>
                    </Box>
                  ) : ( */}
                  <Box direction="column">
                    <Box>
                      <Button
                        startIcon={
                          <Icons
                            variant="common"
                            label="plus"
                            stroke={isStarted ? "#D1D6DB" : "#252d38"}
                          />
                        }
                        color="secondary"
                        onClick={() => setIsUserModalOpen(true)}
                        disabled={isStarted}
                        classes="quiz__select__button"
                      >
                        {t("quiz.label.selectOrgChart")}
                      </Button>
                    </Box>
                    <Box classes="mg mt-8">
                      <Box
                        classes="selected__user__container"
                        alignItems="center"
                        direction="column"
                      >
                        <ScrollBox
                          width={"100%"}
                          classes="pd pt-8 pl-8 pr-8 pb-8"
                        >
                          {(selectedDeptInfo && selectedDeptInfo.length > 0) ||
                          (selectedUserInfo && selectedUserInfo.length > 0) ? (
                            <Box direction="column">
                              {selectedDeptInfo &&
                                selectedDeptInfo.length > 0 &&
                                selectedDeptInfo.map((i) => (
                                  <OrganCard
                                    userName={i.deptName}
                                    userId={i.deptCode}
                                    type="DEPT"
                                    deleteEvent={() =>
                                      setSelectedDeptIds((prev) =>
                                        prev.filter((p) => p !== i.deptCode)
                                      )
                                    }
                                  />
                                ))}
                              {selectedUserInfo &&
                                selectedUserInfo.length > 0 &&
                                selectedUserInfo.map((i) => (
                                  <OrganCard
                                    userId={i.userId ?? undefined}
                                    userName={i.userName}
                                    jobTitle={i.jobTitle ?? undefined}
                                    deptName={i.deptName}
                                    userEmail={i.userEmail}
                                    deleteEvent={
                                      isStarted
                                        ? undefined
                                        : () =>
                                            setSelectedUserIds((prev) =>
                                              prev.filter((p) => p !== i.userId)
                                            )
                                    }
                                  />
                                ))}
                            </Box>
                          ) : (
                            <Box
                              width={"100%"}
                              alignItems="center"
                              direction="column"
                            >
                              <Box classes="mg mt-24">
                                <NoSelectedUserImage />
                              </Box>
                              <Box>
                                <Typography color="secondary">
                                  {t("quiz.msg.addTargetOrgChart")}
                                </Typography>
                              </Box>
                            </Box>
                          )}
                        </ScrollBox>
                      </Box>
                    </Box>
                  </Box>
                  {/* )} */}
                </Box>
              </Box>
            </AccordionItem>
          </Accordion>
        </Box>
        {/* user info end   */}

        {/* quiz  info start  */}
        <Box>
          <Accordion>
            <AccordionItem
              title={t("quiz.label.quizQuestions")}
              expandedProp={true}
              type="CLOSEINFO"
              classes="training__accordian"
              iconLeft={true}
            >
              <Divider direction="row" classes="mg mb-12" />
              <Box classes="mg ml-16" direction="column">
                <Box classes="mg mb-28">
                  <Typography color="secondary">
                    {t("quiz.msg.choiceQuizCourse")}
                  </Typography>
                </Box>
                <Box alignItems="center">
                  <Button
                    startIcon={
                      <Icons
                        variant="common"
                        label="plus"
                        stroke={isStarted ? "#D1D6DB" : "#252d38"}
                      />
                    }
                    color="secondary"
                    onClick={() => setIsQuizModalOpen(true)}
                    disabled={isStarted}
                    classes="quiz__select__button"
                  >
                    {t("quiz.label.choiceQuiz")}
                  </Button>
                  <Box classes="mg ml-12">
                    <Typography>{t("quiz.label.added")}</Typography>
                  </Box>
                  <Box classes="mg ml-4">
                    <Typography
                      color={
                        course.quizCount === undefined ||
                        course.quizCount === 0 ||
                        course.sendMailCount === undefined ||
                        course.sendMailCount === 0 ||
                        (course.sendMailCount &&
                          course.quizCount &&
                          selectedQuizList.length <
                            course.sendMailCount * course.quizCount)
                          ? "red"
                          : "green"
                      }
                    >
                      {selectedQuizList.length}
                    </Typography>
                    <Box classes="mg mr-4"></Box>
                    <Typography>
                      {course.sendMailCount &&
                        course.quizCount &&
                        `/ ${course.sendMailCount * course.quizCount}`}
                    </Typography>
                  </Box>
                </Box>
                <Table classes="mg mt-12">
                  <colgroup>
                    <col style={{ width: "44px" }} />
                    <col style={{ width: "72px" }} />
                    <col style={{ width: "360px" }} />
                    <col style={{ width: "*" }} />
                    <col style={{ width: "160px" }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>{/* <Checkbox /> */}</th>
                      <th>{t("quiz.table.type")}</th>
                      <th>{t("quiz.table.quizName")}</th>
                      <th>{t("quiz.table.category")}</th>
                      <th>{t("quiz.table.formal")}</th>
                    </tr>
                  </thead>
                </Table>
                <Box classes="table-scroll">
                  {selectedQuizList.length === 0 ? (
                    <Box
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      width="100%"
                      height={154}
                    >
                      <NoQuizImage />
                      <Typography color="secondary">
                        {t("quiz.msg.choiceQuiz")}
                      </Typography>
                    </Box>
                  ) : (
                    <Table>
                      <colgroup>
                        <col style={{ width: "44px" }} />
                        <col style={{ width: "72px" }} />
                        <col style={{ width: "360px" }} />
                        <col style={{ width: "*" }} />
                        <col style={{ width: "160px" }} />
                      </colgroup>
                      <tbody>
                        {selectedQuizList.map((q) => (
                          <tr>
                            <td></td>
                            <td>
                              {q.commonId ? (
                                <Tag
                                  name={t("template.public")}
                                  size={i18n.language === "ko" ? "ms" : "sm"}
                                  color="blue"
                                  radius={6}
                                />
                              ) : (
                                <Tag
                                  name={t("template.private")}
                                  size={i18n.language === "ko" ? "ms" : "sm"}
                                  color="pink"
                                  radius={6}
                                />
                              )}
                            </td>
                            <td>{q.quizName}</td>
                            <td>
                              {q.categorys && (
                                <CategoryTagList categorys={q.categorys} />
                              )}
                            </td>

                            <td>
                              {q.quizType === 1
                                ? t("quiz.label.formatTF")
                                : t("quiz.label.formatChoice")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </Box>
              </Box>
            </AccordionItem>
          </Accordion>
        </Box>
        {/* quiz  info end   */}

        <Box justifyContent="flex-end" classes="mg mt-12">
          <Button
            color="secondary"
            onClick={() => navigate(`${process.env.PUBLIC_URL}/quiz/course`)}
          >
            {t("common.button.cancel")}
          </Button>

          <Button
            classes="mg ml-8"
            onClick={onSubmit}
            disabled={
              !(course.sendMailCount ?? 0) ||
              !(course.quizCount ?? 0) ||
              !(course.courseName ?? 0) ||
              userCheckboxGroup.selected.length === 0 ||
              (selectedDeptIds.length === 0 && selectedUserIds.length === 0) ||
              selectedQuizList.length <
                (course.sendMailCount ?? 0) * (course.quizCount ?? 0)
            }
          >
            {t("quiz.label.save")}
          </Button>
        </Box>
      </Box>
      {/* main end  */}
      {isQuizModalOpen && (
        <QuizModal
          isOpen={isQuizModalOpen}
          onClose={() => {
            setIsQuizModalOpen(false);
          }}
          selectedQuizList={selectedQuizList}
          setSelectedQuizList={setSelectedQuizList}
        />
      )}

      {isUserModalOpen && (
        <UserModal
          isOpen={isUserModalOpen}
          onClose={() => {
            setIsUserModalOpen(false);
          }}
          selectedUserIds={selectedUserIds}
          selectedDeptIds={selectedDeptIds}
          setSelectedUserIds={setSelectedUserIds}
          setSelectedDeptIds={setSelectedDeptIds}
          userCheckboxGroup={userCheckboxGroup}
          deptCheckboxGroup={deptCheckboxGroup}
          organTree={organTree}
        />
      )}
    </ScrollBox>
  );
};

export default WriteQuizCourse;
