import {
  Box,
  Button,
  IconButton,
  Icons,
  Modal,
  TextField,
  Typography,
} from "fasoo-ui-component-next";
import { title } from "process";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserInfoFromLocalStorage } from "../../shared/utils/auth";

interface PwdModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  changePwdInfo: {
    nowPwd: string;
    newPwd: string;
    checkPwd: string;
  };
  setChangePwdInfo: React.Dispatch<
    React.SetStateAction<{
      nowPwd: string;
      newPwd: string;
      checkPwd: string;
    }>
  >;
  errorType: {
    isError: boolean;
    errorTarget: string;
    errorMsg: string;
  };
  changeErrorType: React.Dispatch<
    React.SetStateAction<{
      isError: boolean;
      errorTarget: string;
      errorMsg: string;
    }>
  >;
}
const PwdModal: React.FC<PwdModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  changePwdInfo,
  setChangePwdInfo,
  errorType,
  changeErrorType,
}: PwdModalProps) => {
  const { t } = useTranslation();

  const userInfo = getUserInfoFromLocalStorage();

  const [showPassword, changeShowPassword] = useState({
    original: false,
    new: false,
    check: false,
  });

  return (
    <Modal
      open={isOpen}
      width={540}
      title={t("common.auth.changePwd")}
      isDivider={false}
      onClose={onClose}
      footer={
        <Box width={"100%"} justifyContent="flex-end">
          <Button color={"secondary"} onClick={onClose}>
            {t("common.button.cancel")}
          </Button>
          <Button
            classes="mg ml-8"
            disabled={
              changePwdInfo.nowPwd === "" ||
              changePwdInfo.newPwd === "" ||
              changePwdInfo.checkPwd === ""
            }
            onClick={onSubmit}
          >
            {t("common.button.save")}
          </Button>
        </Box>
      }
    >
      <Box direction="column">
        <Box classes="login-form">
          <Typography size={14} fontWeight={700}>
            {t("user.userEmail")}
          </Typography>
          <TextField
            size={"lg"}
            borderRadius={8}
            width={484}
            placeholder={t("login.loginEmailPlaceholder")}
            text={userInfo?.email ?? ""}
            hasText
            error={errorType.errorTarget === "email"}
            errorMsg={errorType.errorMsg}
            disabled
            onChange={(e: any) =>
              setChangePwdInfo((prev) => ({ ...prev, email: e.target.value }))
            }
            // onKeyPress={(e: any) =>
            //   e.key === "Enter" && onSubmit()
            // }
          />
        </Box>
        <Box classes="login-form">
          <Typography size={14} fontWeight={700}>
            {t("account.nowPwd")}
          </Typography>
          <TextField
            size={"lg"}
            borderRadius={8}
            width={484}
            placeholder={t("account.msg.nowPwdPlaceholder")}
            text={changePwdInfo.nowPwd}
            error={
              errorType.errorTarget === "nowPwdNotMatch"
              //   || errorType.errorTarget === "name"
            }
            hasText
            errorMsg={errorType.errorMsg}
            onChange={(e: any) =>
              setChangePwdInfo((prev) => ({ ...prev, nowPwd: e.target.value }))
            }
            type={showPassword.original ? "text" : "password"}
            endIcon={
              <IconButton
                transparent
                classes="hiddenEye"
                onClick={() =>
                  changeShowPassword({
                    ...showPassword,
                    original: !showPassword.original,
                  })
                }
              >
                <Icons
                  variant="common"
                  label={showPassword.original ? "view" : "stopView"}
                  stroke="#b5bbc2"
                />
              </IconButton>
            }
            // onKeyPress={(e: any) =>
            //   e.key === "Enter" &&onSubmit()
            // }
          />
        </Box>
        <Box classes="login-form">
          <Typography size={14} fontWeight={700}>
            {t("account.newPwd")}
          </Typography>
          <TextField
            size={"lg"}
            borderRadius={8}
            width={484}
            placeholder={t("account.msg.newPwdPlaceholder")}
            text={changePwdInfo.newPwd}
            error={
              errorType.errorTarget === "findPW-name" ||
              errorType.errorTarget === "name"
            }
            hasText
            errorMsg={errorType.errorMsg}
            onChange={(e: any) =>
              setChangePwdInfo((prev) => ({ ...prev, newPwd: e.target.value }))
            }
            type={showPassword.new ? "text" : "password"}
            endIcon={
              <IconButton
                transparent
                classes="hiddenEye"
                onClick={() =>
                  changeShowPassword({
                    ...showPassword,
                    new: !showPassword.new,
                  })
                }
              >
                <Icons
                  variant="common"
                  label={showPassword.new ? "view" : "stopView"}
                  stroke="#b5bbc2"
                />
              </IconButton>
            }
            // onKeyPress={(e: any) =>
            //   e.key === "Enter" &&onSubmit()
            // }
          />
        </Box>
        <Box classes="login-form">
          <Typography size={14} fontWeight={700}>
            {t("account.confirmPwd")}
          </Typography>
          <TextField
            size={"lg"}
            borderRadius={8}
            width={484}
            placeholder={t("account.msg.confirmPwdPlaceholder")}
            text={changePwdInfo.checkPwd}
            error={errorType.errorTarget === "pwdNotMatch"}
            hasText
            errorMsg={errorType.errorMsg}
            onChange={(e: any) =>
              setChangePwdInfo((prev) => ({
                ...prev,
                checkPwd: e.target.value,
              }))
            }
            type={showPassword.check ? "text" : "password"}
            endIcon={
              <IconButton
                transparent
                classes="hiddenEye"
                onClick={() =>
                  changeShowPassword({
                    ...showPassword,
                    check: !showPassword.check,
                  })
                }
              >
                <Icons
                  variant="common"
                  label={showPassword.check ? "view" : "stopView"}
                  stroke="#b5bbc2"
                />
              </IconButton>
            }
            // onKeyPress={(e: any) =>
            //   e.key === "Enter" &&onSubmit()
            // }
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default PwdModal;
