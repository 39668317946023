import {
  Box,
  Button,
  Checkbox,
  DropdownItem,
  Modal,
  Radio,
  RadioGroup,
  ScrollBox,
  Select,
  TextField,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import useDeptStore from "../../../redux/dispatcher/useDeptStore";
import { useCallback, useEffect, useRef, useState } from "react";
import { UserType } from "../../../types/User";
import "./index.scss";
import useCustomerStore from "../../../redux/dispatcher/useCustomerStore";
import { CustomerInfoBean } from "../../../types/Info";
import { SelectItem } from "fasoo-ui-component-next/src/components/atom/Select";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import utilsCommon from "../../../shared/utils/common";
import useManagerStore from "../../../redux/dispatcher/useManagerStore";
import { nanoid } from "@reduxjs/toolkit";
import { accessMenuOptions, groupedMenus, menuData, parentMenus } from ".";
import { ManagerBean } from "../../../types/Manage";
import {
  getLocalCustomerCode,
  getUserInfoFromLocalStorage,
} from "../../../shared/utils/auth";
import { useTranslation } from "react-i18next";

interface RegistUpdateUserModalProps {
  isOpen: boolean;
  modalType: string;
  updateUserCode: string;
  onClose: () => void;
}

const RegistUpdateUserModal: React.FC<RegistUpdateUserModalProps> = ({
  isOpen,
  modalType,
  updateUserCode,
  onClose,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const ref = useRef<HTMLInputElement>(null);
  const checkboxGroup = useCheckboxGroup<number>();

  const { customerState, getAccessCompanyList } = useCustomerStore();
  const { deptState, getOrganUserList } = useDeptStore();
  const { managerState, addNewManager, updateManagerInfo, getManagerList } =
    useManagerStore();

  const [userList, changeUserList] = useState<UserType[]>([]);
  const [managerInfo, changeManagerInfo] = useState({
    managerPwd: "",
    managerName: "",
    managerType: 2,
    customerCode: getLocalCustomerCode() ?? "",
    managerEmail: "",
    menuAccess: "[]",
  });
  const [selectManagerType, changeSelectManagerType] = useState<string>("2");
  const [selectUsertType, changeSelectUserType] = useState<string>("NEW");
  const [showAutoComplete1, changeAutoComplete1] = useState(false);
  const [companyList, chageCompanyList] = useState<CustomerInfoBean[]>([]);
  const [inputErr, changeInputErr] = useState("");
  const clickOutside = useCallback((e: any) => {
    if (ref.current && !(ref.current! as any).contains(e.target)) {
      if (e.target !== null) {
        changeAutoComplete1(false);
      }
    }
  }, []);

  useEffect(() => {
    if (!deptState.userListInDept || deptState.userListInDept?.length === 0)
      getOrganUserList();
  }, []);
  useEffect(() => {
    if (updateUserCode === "") return;

    let targetManager = managerState.managerList?.filter(
      (item: ManagerBean) => item.managerCode === updateUserCode
    )[0];
    let menuAccess = targetManager?.menuAccess;
    changeManagerInfo(targetManager);
    changeSelectManagerType(targetManager.managerType + "");
    checkboxGroup.handleAllClick(
      menuAccess
        .substring(1, menuAccess.length - 1)
        .split(",")
        .filter((item2: string) => item2)
        .map((item1: string) => parseInt(item1))
    );
  }, [updateUserCode]);
  useEffect(() => {
    if (customerState?.companyList)
      chageCompanyList(customerState?.companyList);
    else getAccessCompanyList();
  }, [customerState?.companyList]);
  useEffect(() => {
    changeUserList(deptState?.userListInDept ?? []);
  }, [deptState?.userListInDept]);
  useEffect(() => {
    if (modalType === "CREATE")
      changeManagerInfo({ ...managerInfo, managerEmail: "", managerName: "" });
  }, [selectUsertType]);
  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  });

  const handleUpdateManagerInfo = async () => {
    if (
      !utilsCommon.passwordValidation(managerInfo.managerPwd) &&
      modalType === "CREATE"
    ) {
      changeInputErr("INVALID_PASSWORD");
      return;
    }
    if (
      !utilsCommon.emailValidation(managerInfo.managerEmail) &&
      (selectUsertType === "NEW" || modalType === "CREATE")
    ) {
      changeInputErr("INVALID_MAIL");
      return;
    }
    changeInputErr("");

    let resp: any =
      modalType === "CREATE"
        ? await addNewManager({
            ...managerInfo,
            managerType: selectManagerType
              ? parseInt(selectManagerType)
              : undefined,
            menuAccess:
              selectManagerType === "2"
                ? "[" + checkboxGroup.selected.join(",") + "]"
                : null,
            customerCode:
              selectManagerType === "2" ? managerInfo.customerCode : undefined,
          })
        : await updateManagerInfo({
            managerCode: updateUserCode,
            managerName: managerInfo.managerName,
            managerType: selectManagerType
              ? parseInt(selectManagerType)
              : undefined,
            menuAccess:
              selectManagerType === "2" && checkboxGroup.selected.length > 0
                ? "[" + checkboxGroup.selected.join(",") + "]"
                : null,
            customerCode:
              selectManagerType === "2" ? managerInfo.customerCode : undefined,
            managerPwd: managerInfo.managerPwd
              ? managerInfo.managerPwd
              : undefined,
          });
    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("manager.msg.managerUpdateSuccsessMsg"),
        "success"
      );
      getManagerList();
      onClose();
    } else if (resp.payload && resp.payload.code === "DATA_DUPLICATE") {
      toast.toastMsg(nanoid(), t("account.msg.joinExistsEmailMsg"), "error");
    } else {
      toast.toastMsg(nanoid(), t("manager.msg.managerAddFailMsg"), "error");
    }
  };

  return (
    <Modal
      open={isOpen}
      title={modalType === "CREATE" ? t("manager.add") : t("manager.update")}
      isDivider={false}
      width={"536px"}
      height={720}
      classes="registUpdateUserModal"
      footer={
        <Box width={"100%"} justifyContent="flex-end">
          <Button color={"secondary"} onClick={onClose}>
            {t("common.button.cancel")}
          </Button>
          <Button
            classes="mg ml-8"
            disabled={
              (modalType === "CREATE" &&
                (!managerInfo.managerName ||
                  !managerInfo.managerEmail ||
                  !managerInfo.managerPwd)) ||
              (selectManagerType === "2" && !managerInfo.customerCode) ||
              (modalType === "UPDATE" &&
                (!managerInfo.managerName || !managerInfo.managerEmail)) ||
              (selectManagerType === "2" && checkboxGroup.selected.length === 0)
            }
            onClick={handleUpdateManagerInfo}
          >
            {t("common.button.save")}
          </Button>
        </Box>
      }
      onClose={onClose}
    >
      <Box direction="column" width={"100%"}>
        <Box>
          <Typography isBold>{t("manager.name")}</Typography>
          {!!getLocalCustomerCode() && (
            <RadioGroup
              onChange={(e) => changeSelectUserType(e.target.value)}
              classes="mg ml-20"
              disabled={modalType === "UPDATE"}
            >
              <Radio
                label={t("manager.directInput")}
                value={"NEW"}
                checked={selectUsertType === "NEW"}
              />
              <Radio
                label={t("manager.searchInUser")}
                value={"OLD"}
                checked={selectUsertType === "OLD"}
              />
            </RadioGroup>
          )}
        </Box>
        <TextField
          classes="mg mt-22"
          size={"lg"}
          text={managerInfo.managerName}
          hasText
          onChange={(e) => {
            selectUsertType === "OLD" && changeAutoComplete1(true);
            changeManagerInfo({ ...managerInfo, managerName: e.target.value });
          }}
          disabled={modalType === "UPDATE"}
          id={"showAutoComplete1"}
        />
        {selectUsertType === "OLD" &&
          showAutoComplete1 &&
          userList?.filter(
            (user) =>
              user.userName.includes(managerInfo.managerName) &&
              !user.managerFlag
          )?.length > 0 && (
            <Box direction="row" inputRef={ref} width={"100%"}>
              <ScrollBox
                id="selectTagFocus"
                width={"480px"}
                classes="selectbox"
                maxHeight={200}
              >
                <ul>
                  {userList
                    ?.filter((user) =>
                      user.userName.includes(managerInfo.managerName)
                    )
                    ?.map((user1, index) => (
                      <DropdownItem
                        id={user1.userId ?? ""}
                        onClick={() => {
                          changeManagerInfo({
                            ...managerInfo,
                            managerName: user1.userName,
                            managerEmail: user1.userEmail,
                          });
                          changeAutoComplete1(false);
                        }}
                        key={index}
                        tabIndex={index}
                      >
                        <Typography size={14}>{user1.userName}</Typography>
                      </DropdownItem>
                    ))}
                </ul>
              </ScrollBox>
            </Box>
          )}
        <Typography isBold classes="mg mt-20">
          {t("common.auth.email")}
        </Typography>
        <TextField
          classes="mg mt-22"
          size={"lg"}
          text={managerInfo.managerEmail}
          hasText
          error={inputErr === "INVALID_MAIL"}
          errorMsg={t("user.msg.userInvalidEmailMsg")}
          disabled={selectUsertType === "OLD" || modalType === "UPDATE"}
          onChange={(e) =>
            changeManagerInfo({ ...managerInfo, managerEmail: e.target.value })
          }
        />
        {modalType !== "UPDATE" && (
          <>
            <Typography isBold classes="mg mt-20">
              {t("common.auth.pwd")}
            </Typography>
            <TextField
              classes="mg mt-22"
              size={"lg"}
              text={managerInfo.managerPwd}
              hasText
              type={"password"}
              onChange={(e) =>
                changeManagerInfo({
                  ...managerInfo,
                  managerPwd: e.target.value,
                })
              }
              disabled={
                modalType === "UPDATE" &&
                managerInfo.managerEmail !==
                  getUserInfoFromLocalStorage()?.email
                // modalType === "UPDATE"
              }
              error={inputErr === "INVALID_PASSWORD"}
              errorMsg={t("manager.pwdInfo")}
            />
          </>
        )}
        {!getLocalCustomerCode() && modalType !== "UPDATE" && (
          <>
            <Typography isBold classes="mg mt-32">
              {t("common.auth.right")}
            </Typography>
            <RadioGroup
              onChange={(e) => changeSelectManagerType(e.target.value)}
              classes="mg mt-22"
            >
              <Radio
                label={"Super"}
                value={"1"}
                checked={selectManagerType === "1"}
              />
              <Radio
                label={t("common.auth.generalAuth")}
                value={"2"}
                checked={selectManagerType === "2"}
              />
            </RadioGroup>

            <Typography isBold classes="mg mt-20">
              {t("manager.customer")}
            </Typography>
            <Select
              classes="mg mt-22"
              height={40}
              isWidthFull
              title={
                managerInfo.customerCode
                  ? companyList?.filter(
                      (item) => item.customerCode === managerInfo.customerCode
                    )[0].companyName
                  : t("manager.selectCustomer")
              }
              disabled={selectManagerType === "1"}
              onClick={(e) =>
                changeManagerInfo({ ...managerInfo, customerCode: e })
              }
            >
              {companyList?.map((item) => (
                <SelectItem eventKey={item.customerCode}>
                  {item.companyName}
                </SelectItem>
              ))}
            </Select>
          </>
        )}
        <Typography isBold classes="mg mt-32">
          {t("manager.accessMenu")}
        </Typography>
        <Box classes="mg mt-22" direction="column">
          {/* {[3, 4, 5, 6].map((item) => (
            <Checkbox
              label={t(accessMenuOptions[item])}
              disabled={selectManagerType === "1"}
              checked={checkboxGroup.isSelected(item)}
              onChange={() => checkboxGroup.handleClick(item)}
            />
          ))} */}
          {/* {[3, 4, 5, 6].map((item) => {
            const parentMenu = menuData[item];
            // const subMenus = groupedMenus[itemIndex] || [];

            return (
              <Box direction="column" classes="mg mr-12">
                <Checkbox
                  label={t(parentMenu[0])}
                  disabled={selectManagerType === "1"}
                  checked={checkboxGroup.isSelected(item)}
                  onChange={() => checkboxGroup.handleClick(item)}
                  key={item}
                />
                {subMenus.length
                  ? subMenus.map((i, index) => (
                      <Checkbox
                        label={t(i.label)}
                        onChange={checkboxGroup.handleClick(index)}
                      />
                    ))
                  : ""}
              </Box>
            );
          })} */}

          {parentMenus.map((parentMenu) => {
            const subMenus = groupedMenus[parentMenu.index] || []; // 해당 parent의 하위 메뉴만 가져오기
            const isParentChecked =
              subMenus.length > 0
                ? subMenus.every((subMenu) =>
                    checkboxGroup.selected.includes(subMenu.index)
                  )
                : checkboxGroup.selected.includes(parentMenu.index);

            const isIconDash =
              subMenus.length > 0 &&
              subMenus.some((subMenu) =>
                checkboxGroup.selected.includes(subMenu.index)
              ) &&
              !subMenus.every((subMenu) =>
                checkboxGroup.selected.includes(subMenu.index)
              );

            const handleParentClick = () => {
              const isSelected = checkboxGroup.isSelected(parentMenu.index);
              const indicesToToggle = [
                parentMenu.index,
                ...subMenus.map((subMenu) => subMenu.index),
              ];

              if (isSelected) {
                // 선택 해제
                const updatedSelection = checkboxGroup.selected.filter(
                  (index) => !indicesToToggle.includes(index)
                );

                checkboxGroup.handleAllClick(updatedSelection);
              } else {
                // 선택
                const updatedSelection = Array.from(
                  new Set([...checkboxGroup.selected, ...indicesToToggle])
                );

                checkboxGroup.handleAllClick(updatedSelection);
              }
            };
            return (
              <Box
                key={parentMenu.index}
                direction="column"
                classes="menu-item mg mr-12"
              >
                {/* 상위 메뉴 체크박스 */}
                <Checkbox
                  label={t(parentMenu.label)}
                  disabled={selectManagerType === "1"}
                  checked={selectManagerType === "1" ? true : isParentChecked}
                  onChange={handleParentClick}
                  isIconDash={isIconDash}
                  classes={subMenus.length === 0 ? "mg mb-16" : ""}
                />
                {/* 하위 메뉴 렌더링 */}
                {subMenus.length > 0 && (
                  <Box classes="access__submenu__container">
                    {subMenus.map((subMenu) => (
                      <Checkbox
                        disabled={selectManagerType === "1"}
                        key={subMenu.index}
                        label={t(subMenu.label)}
                        checked={
                          selectManagerType === "1"
                            ? true
                            : checkboxGroup.isSelected(subMenu.index)
                        }
                        onChange={() =>
                          checkboxGroup.handleClick(subMenu.index)
                        }
                      />
                    ))}
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Modal>
  );
};

export default RegistUpdateUserModal;
