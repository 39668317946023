import {
  Box,
  Button,
  Calendar,
  Divider,
  DropdownItem,
  IconButton,
  Icons,
  Modal,
  ScrollBox,
  SettingButton,
  Switch,
  Table,
  Tag,
  TextField,
  ToolTip,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TitlePath from "../../../component/TitlePath";
import { useEffect, useLayoutEffect, useState } from "react";
import { QuizCourseDTO, QuizCourseReqDTO } from "../../../types/Quiz";
import useCheckboxGroup from "../../../shared/utils/checkbox";
import NoSearchResultImage from "../../../shared/image/NoSearchResultImage";
import useQuizStore from "../../../redux/dispatcher/useQuizStore";
import utilsCommon from "../../../shared/utils/common";
import dayjs from "dayjs";
import SettingChildren from "../../../component/SettingChildren";
import CommonModal from "../../../component/CommonModal";
import { nanoid } from "@reduxjs/toolkit";
import NoDashboardImage from "../../../shared/image/NoDashboardImage";
import CategoryTag from "../../../component/CategoryTag";

const QuizCourse: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();

  const { quizState, getQuizCourses, updateQuizCourse, deleteQuizCourse } =
    useQuizStore();

  const [openDateModal, toggleDateModal] = useState(false);
  const [tempPeriod, changeTempPeriod] = useState({
    start: dayjs()
      .subtract(1, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });
  const [selectedPeriod, changeSelectedPeriod] = useState({
    value: "ALL",
    start: new Date().valueOf(),
    end: new Date().valueOf(),
  });
  const changeDateFilter = (type: string) => {
    if (type === "ALL") {
      changeSelectedPeriod({
        value: type,
        start: new Date().valueOf(),
        end: new Date().valueOf(),
      });
    } else if (type !== "PERIOD_CUSTOM") {
      changeSelectedPeriod({
        value: type,
        start: dayjs()
          .subtract(type === "PERIOD_6MONTH" ? 6 : 12, "month")
          .add(1, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .valueOf(),

        end: dayjs()
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .valueOf(),
      });
    } else {
      toggleDateModal(true);
    }
  };

  const [courseList, setCourseList] = useState<QuizCourseDTO[]>([]);
  const [courseIdList, setCourseIdList] = useState<number[]>();
  const [totalCourseCount, setTotalCourseCount] = useState<number>(0);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useLayoutEffect(() => {
    const tableContainer = document.querySelector(
      ".table-scroll"
    ) as HTMLElement;

    if (tableContainer) {
      const scrollbarWidth =
        tableContainer.offsetWidth - tableContainer.clientWidth;
      setScrollbarWidth(scrollbarWidth);
    }
  }, [courseList]);
  const [buttonClick, setButtonClick] = useState(0);

  const [selectedCourses, setSelectedCourses] = useState<number[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const [isActivateModalOpen, setIsActivateModalOpen] =
    useState<boolean>(false);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] =
    useState<boolean>(false);

  const [filterData, setFilterData] = useState<QuizCourseReqDTO>(
    {} as QuizCourseReqDTO
  );

  const resetFilter = () => {
    setFilterData({} as QuizCourseReqDTO);
    changeSelectedPeriod({
      value: "ALL",
      start: new Date().valueOf(),
      end: new Date().valueOf(),
    });
  };

  const [progressStatus, setProgressStatus] = useState<number>(0);
  const [tempCourseName, setTempCourseName] = useState<string>("");
  const [tempCourseNames, setTempCourseNames] = useState<string[]>([]);

  const checkboxGroup = useCheckboxGroup();

  const filterProgress = (list: any) => {
    let tempList = list.filter((item: QuizCourseDTO) => {
      let startDate =
        (typeof item.sendStartDate === "string"
          ? parseInt(item.sendStartDate)
          : item.sendStartDate ?? 0) * 1000;
      let endDate =
        (typeof item.sendEndDate === "string"
          ? parseInt(item.sendEndDate)
          : item.sendEndDate ?? 0) * 1000;
      switch (progressStatus) {
        case 1:
          return startDate > dayjs().valueOf();
        case 2:
          return (
            startDate <= dayjs().valueOf() &&
            endDate >= dayjs().valueOf() &&
            item.activateFlag
          );
        case 3:
          return endDate < dayjs().valueOf();
        case 4:
          return (
            startDate <= dayjs().valueOf() &&
            endDate >= dayjs().valueOf() &&
            !item.activateFlag
          );
        default:
          return true;
      }
    });
    setCourseList(tempList);
    setTotalCourseCount(tempList.length);
  };

  const makeCourseIdList = async () => {
    const resp: any = await getQuizCourses({});

    if (resp.payload && resp.payload.code === "SUCCESS") {
      setCourseIdList(resp.payload.data.list.map((c: any) => c.courseId));
    }
  };

  const getQuizCourseList = async () => {
    let resp: any;
    if (selectedPeriod.value === "ALL") {
      resp = await getQuizCourses({
        ...filterData,
      });
    } else {
      resp = await getQuizCourses({
        ...filterData,
        sendStartDate: selectedPeriod.start / 1000,
        sendEndDate: selectedPeriod.end / 1000,
      });
    }

    if (resp.payload && resp.payload.code === "SUCCESS") {
      if (progressStatus !== 0) {
        filterProgress(resp.payload.data.list);
      }
    }
  };
  useEffect(() => {
    makeCourseIdList();
  }, []);

  useEffect(() => {
    getQuizCourseList();
  }, [progressStatus]);

  useEffect(() => {
    if (filterData) {
      getQuizCourseList();
    }
  }, [filterData, selectedPeriod]);

  useEffect(() => {
    if (quizState.quizCourseList.list) {
      setCourseList(quizState.quizCourseList.list);
      setTotalCourseCount(quizState.quizCourseList.total);
    }
  }, [quizState.quizCourseList]);

  const refreshList = () => {
    getQuizCourses({});
  };

  const updateActivate = async () => {
    //update로 활성화
    const resp: any = await updateQuizCourse({
      courseId: selectedCourses[0],
      activateFlag: true,
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(nanoid(), t("quiz.msg.courseActivateSuccess"), "success");
      refreshList();
    } else {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programActivateFailMsg"),
        "error"
      );
    }
    setIsActivateModalOpen(false);
  };

  const updateDectivate = async () => {
    const resp: any = await updateQuizCourse({
      courseId: selectedCourses[0],
      activateFlag: false,
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("quiz.msg.courseDeactivateSuccess"),
        "success"
      );
      refreshList();
    } else {
      toast.toastMsg(
        nanoid(),
        t("program.msg.programDeactivateFailMsg"),
        "error"
      );
    }
    setIsDeactivateModalOpen(false);
  };
  const handleDeleteCourse = async () => {
    const resp: any = await deleteQuizCourse({
      courseId: selectedCourses[0],
    });

    if (resp.payload && resp.payload.code === "SUCCESS") {
      toast.toastMsg(
        nanoid(),
        t("quiz.msg.deleteQuizCourseSuccess"),
        "success"
      );
      refreshList();
    } else {
      toast.toastMsg(nanoid(), t("quiz.msg.deleteQuizCourseFail"), "error");
    }
    setIsDeleteModalOpen(false);
  };

  const header = (
    <Box
      classes="mg mt-8"
      justifyContent="space-between"
      width={"100%"}
      height={32}
    >
      <Box alignItems="center">
        <Typography fontWeight={700} size={16}>
          {t("quiz.label.courses")}
        </Typography>
        <Typography classes="mg ml-8" size={16} color="green" fontWeight={700}>
          {totalCourseCount}
        </Typography>
      </Box>
      <Box>
        {checkboxGroup.selected.length > 0 && (
          <Box>
            {checkboxGroup.selected.length === 1 && (
              <Button
                color={"text-noline"}
                startIcon={
                  <Icons variant="mindsat" label="icon_edit3" width={20} />
                }
                onClick={() => {
                  // const item: Scenario = templates.filter(
                  //   (t) => t.templateId === checkboxGroup.selected[0]
                  // )[0];
                  // if (item.commonId) {
                  //   toast.toastMsg(
                  //     nanoid(),
                  //     t("common.msg.cannotDeletePublicContentsMsg"),
                  //     "error"
                  //   );
                  //   return;
                  // }
                  // if (item.useConfig && parseInt(item.useConfig) > 0) {
                  //   setIsUsedModal(item.templateId);
                  // } else {
                  //   navigate(`/manageScenario/update/${item.templateId}`);
                  // }
                }}
              >
                {t("common.button.update")}
              </Button>
            )}
            <Button
              color={"text-noline"}
              textColor="red"
              height={37}
              startIcon={
                <Icons variant="mindsat" label="icon_trash" classes="mg mr-4" />
              }
              onClick={() => {
                // let commonFlag = false;
                // let usedFlag = false;
                // checkboxGroup.selected.map((c) => {
                //   const item: Scenario[] = templates.filter(
                //     (t) => t.templateId === c
                //   );
                //   item.forEach((i) => {
                //     console.log(i.useConfig);
                //     if (i.commonId) {
                //       commonFlag = true;
                //     }
                //     if (i.useConfig && parseInt(i.useConfig) > 0) {
                //       usedFlag = true;
                //     }
                //   });
                // });
                // if (commonFlag) {
                //   toast.toastMsg(
                //     nanoid(),
                //     t("template.msg.cannotDeletePublicScenarioMsg"),
                //     "error"
                //   );
                // } else if (usedFlag) {
                //   setIsDeleteModalOpen("USED");
                // } else {
                //   setIsDeleteModalOpen("NOT_USED");
                // }
              }}
            >
              {t("common.button.delete")}
            </Button>
          </Box>
        )}
        <Button
          startIcon={<Icons variant="common" label="filter" stroke="#252d38" />}
          color="transparent"
          onClick={() => {
            setIsFilterOpen((prev) => !prev);
          }}
        >
          {t("common.list.filter")}
        </Button>
      </Box>
    </Box>
  );

  const filter = (
    <Box classes="mg mt-12" alignItems="center">
      {/* courseId  */}
      <SettingButton
        height={200}
        buttonClick={buttonClick}
        button={
          <Button
            color={
              filterData.courseId && filterData.courseId > 0
                ? "tertiary-click"
                : "tertiary"
            }
            classes="mg mr-8"
          >
            {t("quiz.table.session")}

            {filterData.courseId === undefined
              ? " · " + t("quiz.label.all")
              : " · " +
                t("common.list.numOrder", { round: filterData.courseId })}
          </Button>
        }
        listPosition="right"
      >
        <DropdownItem
          isChecked={filterData.courseId === undefined}
          onClick={() =>
            setFilterData((prev) => ({ ...prev, courseId: undefined }))
          }
        >
          {filterData.courseId === undefined ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("common.auth.all")}
        </DropdownItem>
        {courseIdList &&
          courseIdList.map((c: number) => (
            <DropdownItem
              isChecked={filterData.courseId === c}
              onClick={() => {
                setFilterData((prev) => ({ ...prev, courseId: c }));
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
            >
              {filterData.courseId === c ? (
                <Icons
                  variant="mindsat"
                  label="icon_check"
                  stroke="#3182F6"
                  classes="mg mr-4"
                />
              ) : (
                <Box width={20} classes="mg mr-4" />
              )}
              {t("common.list.numOrder", { round: c })}
            </DropdownItem>
          ))}
      </SettingButton>

      {/* courseId end */}

      {/* status start  */}
      <SettingButton
        buttonClick={buttonClick}
        button={
          <Button
            color={progressStatus > 0 ? "tertiary-click" : "tertiary"}
            classes="mg mr-8"
          >
            {t("quiz.table.status")}
            {progressStatus > 0
              ? progressStatus === 1
                ? " · " + t("program.filter.beforeStart")
                : progressStatus === 2
                ? " · " + t("program.filter.onProgress")
                : progressStatus === 3
                ? " · " + t("program.filter.finished")
                : " · " + t("program.filter.stop")
              : " · " + t("quiz.label.all")}
          </Button>
        }
        listPosition="right"
      >
        <DropdownItem
          isChecked={progressStatus === 0}
          onClick={() => {
            // setFilterData((prev) => ({ ...prev, historyResult: undefined })) {
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
            setProgressStatus(0);
          }}
        >
          {progressStatus === 0 ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("common.auth.all")}
        </DropdownItem>
        <DropdownItem
          isChecked={progressStatus === 1}
          onClick={() => {
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
            setProgressStatus(1);
          }}
        >
          {progressStatus === 1 ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("program.filter.beforeStart")}
        </DropdownItem>
        <DropdownItem
          isChecked={progressStatus === 2}
          onClick={() => {
            setProgressStatus(2);
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
        >
          {progressStatus === 2 ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("program.filter.onProgress")}
        </DropdownItem>
        <DropdownItem
          isChecked={progressStatus === 3}
          onClick={() => {
            setProgressStatus(3);
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
        >
          {progressStatus === 3 ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("program.filter.finished")}
        </DropdownItem>

        <DropdownItem
          isChecked={progressStatus === 4}
          onClick={() => {
            setProgressStatus(4);
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
        >
          {progressStatus === 4 ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("program.filter.stop")}
        </DropdownItem>
      </SettingButton>
      {/* end status */}

      {/* courseName  */}
      <SettingButton
        buttonClick={buttonClick}
        button={
          <Button
            color={
              filterData.courseNames === undefined ||
              filterData.courseNames.length === 0
                ? "tertiary"
                : "tertiary-click"
            }
            classes="mg mr-8"
          >
            {t("quiz.table.courseTitle")}

            {filterData.courseNames === undefined ||
            filterData.courseNames.length === 0
              ? " · " + t("quiz.label.all")
              : ` · ${filterData.courseNames.length}`}
          </Button>
        }
        listPosition="right"
      >
        <Box
          alignItems="center"
          classes="setting-button-list-search"
          height={40}
        >
          <TextField
            width={200}
            startLabel={
              <Box justifyContent="center" alignItems="center" height={30}>
                <Icons variant="mindsat" label="icon_search" width={20} />
              </Box>
            }
            isSearch
            borderNone
            placeholder={t("quiz.label.courseTitleHolder")}
            text={tempCourseName}
            hasText
            onChange={(e) => setTempCourseName(e.target.value)}
            onKeyPress={(e: any) => {
              if (e.key === "Enter") {
                if (e.target.value === "") return;
                if (
                  tempCourseNames &&
                  tempCourseNames.includes(e.target.value)
                ) {
                  return;
                }
                setTempCourseNames((prev) => [...prev, tempCourseName]);
                setTempCourseName("");
              }
            }}
          />
        </Box>
        <Box>
          {tempCourseNames &&
            tempCourseNames.map((item) => (
              <CategoryTag
                key={item}
                text={item}
                endIcon={<Icons variant="common" label="delete" />}
                onClick={() =>
                  setTempCourseNames((prev) => prev.filter((q) => q !== item))
                }
              />
            ))}
        </Box>
        <Box
          alignItems="center"
          classes="setting-button-list-footer"
          justifyContent="flex-end"
        >
          <Box>
            <Button
              text={t("common.button.cancel")}
              color="secondary"
              onClick={() => {
                setTempCourseName("");
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
            />
            <Button
              text={t("common.button.apply")}
              classes={"mg ml-8"}
              color="primary"
              onClick={() => {
                if (tempCourseName !== "") {
                  setTempCourseNames((prev) => [...prev, tempCourseName]);
                  setFilterData((prev) => ({
                    ...prev,
                    courseNames: [...tempCourseNames, tempCourseName],
                  }));
                } else {
                  setFilterData((prev) => ({
                    ...prev,
                    courseNames: tempCourseNames,
                  }));
                }
                setTempCourseName("");
                setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
              }}
            />
          </Box>
        </Box>
      </SettingButton>

      {/* end courseName  */}

      {/* period  */}
      <SettingButton
        buttonClick={buttonClick}
        button={
          <Button
            color={
              selectedPeriod.value !== "ALL" ? "tertiary-click" : "tertiary"
            }
            classes="mg mr-8"
          >
            {`${t("quiz.label.period1")} ·  ${
              selectedPeriod.value === "PERIOD_3MONTH"
                ? t("report.stats.reportRecent3Month")
                : selectedPeriod.value === "PERIOD_6MONTH"
                ? t("report.stats.reportRecent6Month")
                : selectedPeriod.value === "PERIOD_1YEAR"
                ? t("report.stats.reportRecent1Year")
                : selectedPeriod.value === "ALL"
                ? t("common.auth.all")
                : t("graph.phishingCustomScope")
            }`}
          </Button>
        }
        listPosition="right"
      >
        <DropdownItem
          key={0}
          onClick={() => {
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
            changeDateFilter("ALL");
          }}
          isChecked={selectedPeriod.value === "ALL"}
        >
          {selectedPeriod.value === "ALL" ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("common.auth.all")}
        </DropdownItem>
        <DropdownItem
          key={0}
          onClick={() => {
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
            changeDateFilter("PERIOD_3MONTH");
          }}
          isChecked={selectedPeriod.value === "PERIOD_3MONTH"}
        >
          {selectedPeriod.value === "PERIOD_3MONTH" ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("report.stats.reportRecent3Month")}
        </DropdownItem>
        <DropdownItem
          key={1}
          onClick={() => {
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
            changeDateFilter("PERIOD_6MONTH");
          }}
          isChecked={selectedPeriod.value === "PERIOD_6MONTH"}
        >
          {selectedPeriod.value === "PERIOD_6MONTH" ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("report.stats.reportRecent6Month")}
        </DropdownItem>
        <DropdownItem
          key={2}
          onClick={() => {
            changeDateFilter("PERIOD_1YEAR");
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
          }}
          isChecked={selectedPeriod.value === "PERIOD_1YEAR"}
        >
          {selectedPeriod.value === "PERIOD_1YEAR" ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("report.stats.reportRecent1Year")}
        </DropdownItem>
        <Divider direction="row" classes="mg mt-2 mb-2" />
        <DropdownItem
          key={3}
          onClick={() => {
            setButtonClick(buttonClick > 99999 ? 1 : buttonClick + 1);
            changeDateFilter("PERIOD_CUSTOM");
          }}
          isChecked={selectedPeriod.value === "PERIOD_CUSTOM"}
        >
          {selectedPeriod.value === "PERIOD_CUSTOM" ? (
            <Icons
              variant="mindsat"
              label="icon_check"
              stroke="#3182F6"
              classes="mg mr-4"
            />
          ) : (
            <Box width={20} classes="mg mr-4" />
          )}
          {t("graph.phishingCustomScope")}
        </DropdownItem>
      </SettingButton>

      {/* end period  */}
      <IconButton transparent onClick={resetFilter} classes="mg ml-8">
        <Icons variant="common" label="refresh" stroke="#B5BBC2" />
      </IconButton>
    </Box>
  );

  const dateModal = () => {
    return (
      <Modal
        open={openDateModal}
        onClose={() => {
          toggleDateModal(false);
          changeTempPeriod({
            start: dayjs()
              .subtract(1, "month")
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .valueOf(),
            end: dayjs()
              .set("hour", 23)
              .set("minute", 59)
              .set("second", 59)
              .valueOf(),
          });
        }}
        title={t("graph.phishingCustomScope")}
        isDivider={false}
        width={480}
        footer={
          <Box justifyContent="flex-end">
            <Button
              color={"secondary"}
              onClick={() => {
                toggleDateModal(false);
                changeTempPeriod({
                  start: dayjs()
                    .subtract(1, "month")
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs()
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              text={t("common.button.cancel")}
            />
            <Button
              onClick={() => {
                if (tempPeriod.start > tempPeriod.end) {
                  toast.toastMsg(
                    nanoid(),
                    t("program.msg.programEndBeforeStartMsg"),
                    "error"
                  );
                  return;
                }

                console.log(tempPeriod);

                if (
                  tempPeriod.end / 1000 - tempPeriod.start / 1000 >
                  31556926
                ) {
                  toast.toastMsg(
                    nanoid(),
                    "설정 날짜는 1년 이내여야만 합니다.",
                    "error"
                  );
                  return;
                }
                changeSelectedPeriod({
                  ...selectedPeriod,
                  value: "PERIOD_CUSTOM",
                  start: tempPeriod.start,
                  end: tempPeriod.end,
                });
                toggleDateModal(false);
              }}
              classes={"mg ml-8"}
              text={t("common.button.check")}
            />
          </Box>
        }
      >
        <Box alignItems="center" justifyContent="center">
          <Calendar
            onSubmit={(date) =>
              changeTempPeriod({
                ...tempPeriod,
                start: dayjs(date)
                  .set("hour", 0)
                  .set("minute", 0)
                  .set("second", 0)
                  .valueOf(),
              })
            }
            lang={i18n.language}
            placeholder={t("common.info.startDate")}
            selected={new Date(tempPeriod.start)}
            dateFormat="yyyy-MM-dd"
            maxDate={
              tempPeriod && tempPeriod.end
                ? new Date(tempPeriod.end)
                : undefined
            }
          />
          <Box
            style={{ color: "#717985" }}
            alignItems="center"
            classes="mg ml-4 mr-4"
          >
            ~
          </Box>
          <Calendar
            onSubmit={(date) =>
              changeTempPeriod({
                ...tempPeriod,
                end: dayjs(date)
                  .set("hour", 23)
                  .set("minute", 59)
                  .set("second", 59)
                  .valueOf(),
              })
            }
            lang={i18n.language}
            selected={new Date(tempPeriod.end)}
            placeholder={t("common.info.endDate")}
            dateFormat="yyyy-MM-dd"
            minDate={
              tempPeriod && tempPeriod.start
                ? new Date(tempPeriod.start)
                : undefined
            }
          />
        </Box>
      </Modal>
    );
  };

  return (
    <ScrollBox classes="main">
      {openDateModal && dateModal()}
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath path={[t("menu.quizTitle"), t("menu.quizCourse")]} />
          <Typography
            fontWeight={700}
            size={24}
            lineHeight="32px"
            classes="mg mt-8"
          >
            {t("menu.quizCourse")}
          </Typography>
        </Box>
        <Box>
          <Button
            startIcon={<Icons variant="common" label="plus" stroke="#252d38" />}
            color="secondary"
            onClick={() =>
              navigate(`${process.env.PUBLIC_URL}/quiz/course/write`)
            }
          >
            {t("common.button.create")}
          </Button>
        </Box>
      </Box>

      {/* main start */}

      <Box width="100%" height={"calc(100% - 84px)"}>
        <Box direction="column" width="100%">
          {header}
          {isFilterOpen && filter}
          <Box
            direction="column"
            height={isFilterOpen ? "calc(100% - 85px)" : "calc(100% - 40px)"}
          >
            <Table classes="default-table mg mt-12">
              <col style={{ width: "44px" }}></col>
              <col style={{ width: "64px" }}></col>
              <col style={{ width: "*" }}></col>
              <col style={{ width: "265px" }}></col>
              <col
                style={{
                  width: i18n.language === "ko" ? "72px" : "90px",
                }}
              ></col>
              <col style={{ width: "72px" }}></col>
              <col style={{ width: "72px" }}></col>
              <col style={{ width: "56px" }}></col>
              <col style={{ width: `calc(48px + ${scrollbarWidth}px)` }}></col>
              <thead>
                <tr>
                  <th>
                    {/* <Checkbox
                        checked={
                          checkboxGroup.selected.length === courseList.length
                        }
                        onChange={() =>
                          checkboxGroup.handleAllClick(
                            checkboxGroup.selected.length === courseList.length
                              ? []
                              : courseList.map((c) => c.courseId)
                          )
                        }
                      /> */}
                  </th>
                  <th>{t("quiz.table.session")}</th>
                  <th>{t("quiz.table.courseTitle")}</th>
                  <th>{t("quiz.table.period")}</th>
                  <th>{t("quiz.table.completions")}</th>
                  <th>{t("quiz.table.targets")}</th>
                  <th>{t("quiz.table.emails")}</th>
                  <th>{t("quiz.table.status")}</th>
                  <th />
                </tr>
              </thead>
            </Table>
            <Box classes="table-scroll">
              {courseList.length === 0 ? (
                JSON.stringify(filterData) !==
                  JSON.stringify({} as QuizCourseReqDTO) ||
                progressStatus !== 0 ||
                selectedPeriod.value !== "ALL" ? (
                  <Box
                    width={"100%"}
                    direction="column"
                    // justifyContent="center"
                    alignItems="center"
                    classes="mg mt-60"
                  >
                    <NoSearchResultImage width={200} />
                    <Typography classes="mg mt-36" size={18} fontWeight={700}>
                      {t("quiz.label.noSearch")}
                    </Typography>
                    <Typography classes="mg mt-8" size={16} color="secondary">
                      {t("quiz.msg.noFilterCourse")}
                    </Typography>
                    <Button
                      classes="mg mt-16"
                      startIcon={
                        <Icons
                          variant="common"
                          label="refresh"
                          stroke="white"
                        />
                      }
                      onClick={resetFilter}
                    >
                      {t("common.button.resetFilter")}
                    </Button>
                  </Box>
                ) : (
                  <Box
                    width={"100%"}
                    direction="column"
                    // justifyContent="center"
                    alignItems="center"
                    classes="mg mt-60"
                  >
                    <NoDashboardImage />
                    <Typography
                      classes="mg mt-24 mb-8 "
                      size={18}
                      fontWeight={700}
                    >
                      {t("quiz.msg.noItems")}
                    </Typography>
                    <Typography color="secondary" classes="mg mb-4 " size={14}>
                      {t("quiz.msg.noQuizCourse")}
                    </Typography>
                    <Typography color="secondary" classes="mg mb-24" size={14}>
                      {t("quiz.msg.addQuizCourse")}
                    </Typography>
                    <Button
                      startIcon={
                        <Icons variant="common" label="plus" stroke="#FFFFFF" />
                      }
                      color="primary"
                      onClick={() =>
                        navigate(`${process.env.PUBLIC_URL}/quiz/course/write`)
                      }
                    >
                      {t("common.button.create")}
                    </Button>
                  </Box>
                )
              ) : (
                <Table>
                  <colgroup>
                    <col style={{ width: "44px" }}></col>
                    <col style={{ width: "64px" }}></col>
                    <col style={{ width: "*" }}></col>
                    <col style={{ width: "265px" }}></col>
                    <col
                      style={{
                        width: i18n.language === "ko" ? "72px" : "90px",
                      }}
                    ></col>
                    <col style={{ width: "72px" }}></col>
                    <col style={{ width: "72px" }}></col>
                    <col style={{ width: "56px" }}></col>
                    <col style={{ width: `48px` }}></col>
                  </colgroup>
                  <tbody>
                    {courseList.map((c) => {
                      const startTimeNum =
                        typeof c.sendStartDate === "string"
                          ? parseInt(c.sendStartDate)
                          : c.sendStartDate;
                      const endTimeNum =
                        typeof c.sendEndDate === "string"
                          ? parseInt(c.sendEndDate)
                          : c.sendEndDate;
                      const isStart =
                        startTimeNum && startTimeNum * 1000 > dayjs().valueOf();

                      const isEnd =
                        endTimeNum && endTimeNum * 1000 < dayjs().valueOf();
                      return (
                        <tr
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            navigate(
                              `${process.env.PUBLIC_URL}/quiz/course/detail?course_id=${c.courseId}`
                            );
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <td>{/* checkbox */}</td>
                          <td>{c.courseId}</td>
                          <td>{c.courseName}</td>
                          <td>{`${utilsCommon.getFullDateStr(
                            c.sendStartDate
                          )} ~ ${utilsCommon.getFullDateStr(
                            c.sendEndDate
                          )}`}</td>
                          <td>
                            {c.sendCount === 0
                              ? c.startedFlag ||
                                (c.sendStartDate &&
                                  dayjs(c.sendStartDate).valueOf() * 1000 <
                                    dayjs().valueOf())
                                ? "0%"
                                : t("program.programSendWait")
                              : c.sendCount && c.userCount && c.sendMailCount
                              ? `${(
                                  (c.sendCount /
                                    (c.userCount * c.sendMailCount)) *
                                  100
                                ).toFixed(0)} %`
                              : t("program.programSendWait")}
                          </td>
                          <td>
                            {c.userCount}
                            {t("program.programPeopleUnit")}
                          </td>
                          <td>
                            {c.sendMailCount}
                            {t("program.programDataUnit")}
                          </td>
                          <td>
                            {/* <Switch
                              checked={c.activateFlag}
                              onChange={() => {
                                c.courseId && setSelectedCourses([c.courseId]);
                                c.activateFlag
                                  ? setIsDeactivateModalOpen(true)
                                  : setIsActivateModalOpen(true);
                              }}
                            /> */}
                            {(c?.userCount &&
                              c?.sendMailCount &&
                              c.sendCount ===
                                c?.userCount * c?.sendMailCount) ||
                            dayjs(c?.sendEndDate).valueOf() * 1000 <
                              dayjs().valueOf() ? (
                              <Tag
                                name={t("quiz.label.courseFinished")}
                                isBgFilled
                                size="sm"
                                style={{ fontSize: 12 }}
                                color="gray"
                                radius={6}
                              />
                            ) : (
                              <ToolTip
                                message={
                                  c.activateFlag
                                    ? t("program.programActivateHeader")
                                    : t("program.programDeactivateHeader")
                                }
                              >
                                <Switch
                                  checked={c.activateFlag}
                                  onChange={() => {
                                    c.courseId &&
                                      setSelectedCourses([c.courseId]);
                                    c.activateFlag
                                      ? setIsDeactivateModalOpen(true)
                                      : setIsActivateModalOpen(true);
                                  }}
                                />
                              </ToolTip>
                            )}
                          </td>
                          <td>
                            {(!(
                              (c?.userCount &&
                                c?.sendMailCount &&
                                c.sendCount ===
                                  c?.userCount * c?.sendMailCount) ||
                              isEnd
                            ) ||
                              isStart) &&
                            !c.startedFlag ? (
                              <SettingButton buttonClick={buttonClick}>
                                <SettingChildren
                                  detailEvent={() =>
                                    navigate(
                                      `${process.env.PUBLIC_URL}/quiz/course/detail?course_id=${c.courseId}`
                                    )
                                  }
                                  editEvent={
                                    !(
                                      (c?.userCount &&
                                        c?.sendMailCount &&
                                        c.sendCount ===
                                          c?.userCount * c?.sendMailCount) ||
                                      isEnd
                                    )
                                      ? () =>
                                          navigate(`${process.env.PUBLIC_URL}/quiz/course/write?course_id=${c.courseId}
`)
                                      : undefined
                                  }
                                  deleteEvent={() => {
                                    c.courseId &&
                                      setSelectedCourses([c.courseId]);
                                    setIsDeleteModalOpen(true);
                                  }}
                                />
                              </SettingButton>
                            ) : (
                              <SettingButton buttonClick={buttonClick}>
                                <SettingChildren
                                  detailEvent={() =>
                                    navigate(
                                      `${process.env.PUBLIC_URL}/quiz/course/detail?course_id=${c.courseId}`
                                    )
                                  }
                                  editEvent={
                                    !(
                                      (c?.userCount &&
                                        c?.sendMailCount &&
                                        c.sendCount ===
                                          c?.userCount * c?.sendMailCount) ||
                                      isEnd
                                    )
                                      ? () =>
                                          navigate(`${process.env.PUBLIC_URL}/quiz/course/write?course_id=${c.courseId}
`)
                                      : undefined
                                  }
                                  deleteEvent={() => {
                                    c.courseId &&
                                      setSelectedCourses([c.courseId]);
                                    setIsDeleteModalOpen(true);
                                  }}
                                />
                              </SettingButton>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* main end  */}
      {isActivateModalOpen && (
        <CommonModal
          open={isActivateModalOpen}
          onClose={() => setIsActivateModalOpen(false)}
          title={t("quiz.label.courseActivate")}
          afterSubmitButton={updateActivate}
          body={
            <Box direction="column" justifyContent="flex-start" width={"100%"}>
              <Typography color={"secondary"} size={14} width="200">
                {t("quiz.msg.courseActivateConfirm")}
              </Typography>
              {/* <Typography color={"secondary"} size={14} classes="mg mt-10">
                {t("program.msg.programDeactivateInfoMsg")}
              </Typography> */}
            </Box>
          }
          footer={
            <Box justifyContent="flex-end">
              <Button
                text={t("common.button.cancel")}
                color="secondary"
                onClick={() => setIsActivateModalOpen(false)}
              />
              <Button
                text={t("program.programActivate")}
                classes={"mg ml-8"}
                color="primary"
                onClick={updateActivate}
              />
            </Box>
          }
        />
      )}
      {isDeleteModalOpen && (
        <CommonModal
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          title={t("quiz.label.deleteCourse")}
          type="delete"
          afterSubmitButton={handleDeleteCourse}
          body={
            <Box direction="column" justifyContent="flex-start" width="100%">
              <Typography size={14} color={"secondary"} lineHeight="normal">
                {t("quiz.msg.deleteQuizCourseMsg")}
              </Typography>
            </Box>
          }
        />
      )}
      {isDeactivateModalOpen && (
        <CommonModal
          open={isDeactivateModalOpen}
          onClose={() => setIsDeactivateModalOpen(false)}
          title={t("quiz.label.courseDeactivate")}
          afterSubmitButton={updateDectivate}
          footer={
            <Box justifyContent="flex-end">
              <Button
                text={t("common.button.cancel")}
                color="secondary"
                onClick={() => setIsDeactivateModalOpen(false)}
              />
              <Button
                text={t("program.programDeactive")}
                classes={"mg ml-8"}
                color="primary"
                onClick={updateDectivate}
              />
            </Box>
          }
          body={
            <Box direction="column" justifyContent="flex-start" width="100%">
              <Typography color={"secondary"} size={14}>
                {t("quiz.msg.courseDeactivateConfirm")}
              </Typography>
              <Typography color={"secondary"} size={14} classes="mg mt-10">
                {t("quiz.msg.courseDeactivateConfirm2")}
              </Typography>
            </Box>
          }
        />
      )}
    </ScrollBox>
  );
};

export default QuizCourse;
