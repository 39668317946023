import {
  Box,
  Button,
  Calendar,
  Divider,
  DropdownItem,
  Icons,
  Modal,
  ScrollBox,
  Select,
  SettingButton,
  Table,
  Typography,
  useToast,
} from "fasoo-ui-component-next";
import TitlePath from "../../component/TitlePath";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { color } from "highcharts";
import useQuizStore from "../../redux/dispatcher/useQuizStore";
import dayjs from "dayjs";
import { QuizCourseDTO, QuizStatResDTO } from "../../types/Quiz";
import { nanoid } from "@reduxjs/toolkit";
import { SelectItem } from "fasoo-ui-component-next/src/components/atom/Select";
import NoQuizImage from "../../shared/image/NoQuizImage";
import QuizStatUser from "../../shared/image/icon/QuizStatUser";
import QuizStatCompleteUser from "../../shared/image/icon/QuizStatCompleteUser";
import QuizStatPer from "../../shared/image/icon/QuizStatPer";

const QuizStat: React.FC = () => {
  const navigate = useNavigate();
  const [buttonClick, setButtonClick] = useState<number>(0);
  const { t, i18n } = useTranslation();
  const toast = useToast();

  const [lineOptions, setLineOptions] = useState<any>([]);

  const [stat, setStat] = useState<QuizStatResDTO>();

  const [courseList, setCourseList] = useState<number[]>();

  const { getQuizStat, getQuizCourses, quizState } = useQuizStore();
  const [selectedPeriod, changeSelectedPeriod] = useState({
    value: "PERIOD_6MONTH",
    start: dayjs()
      .subtract(6, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });

  const [selectedCourseId, setSelectedCourseId] = useState<string>("0");
  const onSelectCourse = async (id: string) => {
    if (id !== "전체") {
      setSelectedCourseId(id);
      getStatInfo([parseInt(id)]);
    } else {
      getStatInfo();
    }
  };

  const getStatInfo = async (courseIds?: number[]) => {
    const resp: any = await getQuizStat({
      startDate: selectedPeriod.start / 1000,
      endDate: selectedPeriod.end / 1000,
      intervalTime: 9,
      courseIds: courseIds ?? undefined,
    });

    if (resp.payload) {
      setStat(resp.payload.data);
    }
  };

  useEffect(() => {
    getStatInfo();
  }, [selectedPeriod]);

  useEffect(() => {
    getQuizCourses({});
  }, []);

  useEffect(() => {
    if (quizState.quizCourseList.list.length > 0) {
      setCourseList(
        quizState.quizCourseList.list.map((i: QuizCourseDTO) => i.courseId)
      );
    }
  }, [quizState.quizCourseList]);
  useEffect(() => {
    setLineOptions({
      chart: {
        width: document.getElementById("quizGraph")?.clientWidth ?? 682,
        height: 292,
      },
      title: {
        text: "",
      },
      legend: {
        align: "left",
        verticalAlign: "top",
        margin: 30,
        itemStyle: {
          fontSize: "14px",
        },
      },
      xAxis: {
        categories:
          // quizStatInfo?.dateStat
          //   .map((item) => utilsCommon.getShortDate(item.date))
          //   .slice(0, 20)
          //   .reverse(),
          stat?.completedCourseStat.map((item) =>
            t("report.reportConfigId", { round: item.courseId })
          ),
        title: {
          // text: t("common.time.date"),
          align: "high",
          style: {
            fontSize: "14px",
          },
        },
        labels: {
          style: {
            fontSize: "14px",
          },
        },
      },
      yAxis: {
        title: {
          text: t("program.programPeopleUnit"),
          align: "high",
          rotation: 0,
          labels: {
            style: {
              fontSize: "14px",
            },
          },
        },
        style: {
          fontSize: "14px",
        },
        labels: {
          style: {
            fontSize: "14px",
          },
        },
        tickInterval: 5,
      },
      plotOptions: {
        series: {
          allowPointSelect: false,
          events: {},
          states: {
            hover: {
              enabled: false, // 호버 상태 비활성화
            },
            inactive: {
              enabled: false, // 다른 시리즈 흐리게 처리 비활성화
            },
          },
        },
      },
      credits: { enabled: false },
      series: [
        {
          name: t("quiz.label.userCnt"),
          type: "column",
          data: stat?.completedCourseStat.map((item) => item.userCount),
          color: "#E9ECEF",
        },
        {
          name: t("quiz.label.completeUserCnt"),
          type: "spline",
          data: stat?.completedCourseStat.map((item) => item.completeUserCount),
          color: "#05C072",
        },
      ],
      tooltip: {
        // useHTML: true,
        // formatter: function (
        //   this: Highcharts.TooltipFormatterContextObject
        // ): string {
        //   console.log(this.series);
        //   const color = this.series.color; // 시리즈 색상
        //   const seriesName = this.series.name;
        //   const legendIcon =
        //     "<svg width='20' height='20'>" +
        //     (this.series as any).legendItem.group.element.outerHTML +
        //     "</svg>";

        //   return `
        //   <div>
        //     ${legendIcon}
        //     <b>${seriesName}</b>: ${this.y}<br/>
        //     Month: ${this.key}
        //   </div>`;
        // },
        shared: true,
        backgroundColor: "#4B596B", // 배경색 변경
        borderColor: "#4B596B", // 테두리 색 변경
        borderRadius: 6, // 둥근 모서리
        style: {
          color: "#FFFFFF",
          fontSize: "12px",
          fontWeight: 400,
        },
      },
    });
  }, [i18n.language, stat]);

  const DATE_RESOURCE: { [key: string]: string } = {
    PERIOD_6MONTH: t("report.stats.reportRecent6Month"),
    PERIOD_1YEAR: t("report.stats.reportRecent1Year"),
    PERIOD_CUSTOM: t("graph.phishingCustomScope"),
  };
  const [tempPeriod, changeTempPeriod] = useState({
    start: dayjs()
      .subtract(1, "month")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .valueOf(),
    end: dayjs().set("hour", 23).set("minute", 59).set("second", 59).valueOf(),
  });
  const [openDateModal, toggleDateModal] = useState(false);
  const changeDateFilter = (type: string) => {
    if (type !== "PERIOD_CUSTOM") {
      changeSelectedPeriod({
        value: type,
        start: dayjs()
          .subtract(type === "PERIOD_6MONTH" ? 6 : 12, "month")
          .add(1, "day")
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .valueOf(),

        end: dayjs()
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .valueOf(),
      });
    } else {
      toggleDateModal(true);
    }
  };
  const dateModal = () => {
    return (
      <Modal
        open={openDateModal}
        onClose={() => {
          toggleDateModal(false);
          changeTempPeriod({
            start: dayjs()
              .subtract(1, "month")
              .set("hour", 0)
              .set("minute", 0)
              .set("second", 0)
              .valueOf(),
            end: dayjs()
              .set("hour", 23)
              .set("minute", 59)
              .set("second", 59)
              .valueOf(),
          });
        }}
        title={t("graph.phishingCustomScope")}
        isDivider={false}
        width={480}
        footer={
          <Box justifyContent="flex-end">
            <Button
              color={"secondary"}
              onClick={() => {
                toggleDateModal(false);
                changeTempPeriod({
                  start: dayjs()
                    .subtract(1, "month")
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .valueOf(),
                  end: dayjs()
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .valueOf(),
                });
              }}
              text={t("common.button.cancel")}
            />
            <Button
              onClick={() => {
                if (tempPeriod.start > tempPeriod.end) {
                  toast.toastMsg(
                    nanoid(),
                    t("program.msg.programEndBeforeStartMsg"),
                    "error"
                  );
                  return;
                }

                console.log(tempPeriod);

                if (
                  tempPeriod.end / 1000 - tempPeriod.start / 1000 >
                  31556926
                ) {
                  toast.toastMsg(
                    nanoid(),
                    "설정 날짜는 1년 이내여야만 합니다.",
                    "error"
                  );
                  return;
                }
                changeSelectedPeriod({
                  ...selectedPeriod,
                  value: "PERIOD_CUSTOM",
                  start: tempPeriod.start,
                  end: tempPeriod.end,
                });
                toggleDateModal(false);
              }}
              classes={"mg ml-8"}
              text={t("common.button.apply")}
            />
          </Box>
        }
      >
        <Box alignItems="center" justifyContent="center">
          <Calendar
            onSubmit={(date) =>
              changeTempPeriod({
                ...tempPeriod,
                start: dayjs(date)
                  .set("hour", 0)
                  .set("minute", 0)
                  .set("second", 0)
                  .valueOf(),
              })
            }
            lang={i18n.language}
            placeholder={t("common.info.startDate")}
            selected={new Date(tempPeriod.start)}
            dateFormat="yyyy-MM-dd"
            maxDate={
              tempPeriod && tempPeriod.end
                ? new Date(tempPeriod.end)
                : undefined
            }
          />
          <Box
            style={{ color: "#717985" }}
            alignItems="center"
            classes="mg ml-4 mr-4"
          >
            ~
          </Box>
          <Calendar
            onSubmit={(date) =>
              changeTempPeriod({
                ...tempPeriod,
                end: dayjs(date)
                  .set("hour", 23)
                  .set("minute", 59)
                  .set("second", 59)
                  .valueOf(),
              })
            }
            lang={i18n.language}
            selected={new Date(tempPeriod.end)}
            placeholder={t("common.info.endDate")}
            dateFormat="yyyy-MM-dd"
            minDate={
              tempPeriod && tempPeriod.start
                ? new Date(tempPeriod.start)
                : undefined
            }
          />
        </Box>
      </Modal>
    );
  };
  return (
    <ScrollBox classes="main">
      {openDateModal && dateModal()}
      <Box
        justifyContent="space-between"
        classes="mg mb-24"
        alignItems="center"
      >
        <Box direction="column">
          <TitlePath path={[t("menu.quizTitle"), t("menu.quizStat")]} />
          <Typography
            fontWeight={700}
            size={24}
            lineHeight="32px"
            classes="mg mt-8"
          >
            {t("menu.quizStat")}
          </Typography>
        </Box>
      </Box>

      {/* main start */}

      <Box direction="column">
        <Box>
          <SettingButton
            buttonClick={buttonClick}
            button={
              <Button
                // color="tertiary"
                color={"tertiary-click"}
                classes="mg mr-8"
              >
                {`${t("quiz.label.period1")} ·  ${
                  selectedPeriod.value === "PERIOD_3MONTH"
                    ? t("report.stats.reportRecent3Month")
                    : selectedPeriod.value === "PERIOD_6MONTH"
                    ? t("report.stats.reportRecent6Month")
                    : selectedPeriod.value === "PERIOD_1YEAR"
                    ? t("report.stats.reportRecent1Year")
                    : t("graph.phishingCustomScope")
                }`}
              </Button>
            }
            listPosition="right"
          >
            <DropdownItem
              key={0}
              onClick={() => changeDateFilter("PERIOD_3MONTH")}
              isChecked={selectedPeriod.value === "PERIOD_3MONTH"}
            >
              {selectedPeriod.value === "PERIOD_3MONTH" && (
                <Icons
                  variant="mindsat"
                  label="icon_check"
                  stroke="#3182F6"
                  classes="mg mr-4"
                />
              )}
              {t("report.stats.reportRecent3Month")}
            </DropdownItem>
            <DropdownItem
              key={1}
              onClick={() => changeDateFilter("PERIOD_6MONTH")}
              isChecked={selectedPeriod.value === "PERIOD_6MONTH"}
            >
              {selectedPeriod.value === "PERIOD_6MONTH" && (
                <Icons
                  variant="mindsat"
                  label="icon_check"
                  stroke="#3182F6"
                  classes="mg mr-4"
                />
              )}
              {t("report.stats.reportRecent6Month")}
            </DropdownItem>
            <DropdownItem
              key={2}
              onClick={() => changeDateFilter("PERIOD_1YEAR")}
              isChecked={selectedPeriod.value === "PERIOD_1YEAR"}
            >
              {selectedPeriod.value === "PERIOD_1YEAR" && (
                <Icons
                  variant="mindsat"
                  label="icon_check"
                  stroke="#3182F6"
                  classes="mg mr-4"
                />
              )}
              {t("report.stats.reportRecent1Year")}
            </DropdownItem>
            <Divider direction="row" classes="mg mt-2 mb-2" />
            <DropdownItem
              key={3}
              onClick={() => changeDateFilter("PERIOD_CUSTOM")}
              isChecked={selectedPeriod.value === "PERIOD_CUSTOM"}
            >
              {selectedPeriod.value === "PERIOD_CUSTOM" && (
                <Icons
                  variant="mindsat"
                  label="icon_check"
                  stroke="#3182F6"
                  classes="mg mr-4"
                />
              )}
              {t("graph.phishingCustomScope")}
            </DropdownItem>
          </SettingButton>
        </Box>

        <Box>
          {/* left start  */}
          <Box direction="column" width={"66%"}>
            {/* card start */}
            <Box
              justifyContent="space-between"
              width={"100%"}
              classes="dashboard__boxes"
            >
              <Box classes="dashboard__box blue__box">
                <Box direction="column">
                  <Typography size={32} fontWeight={900} color="white">
                    {stat?.totalUserCount ? stat?.totalUserCount : "0"}
                  </Typography>
                  <Typography size={12} classes="mg mb-4" color="white">
                    {t("quiz.label.avgUser")}
                  </Typography>
                </Box>
                <QuizStatUser />
              </Box>
              <Box classes="dashboard__box green__box">
                <Box direction="column">
                  <Typography size={32} fontWeight={900} color="white">
                    {stat?.totalCompletedUserCount
                      ? stat?.totalCompletedUserCount
                      : `0`}
                  </Typography>
                  <Typography size={12} classes="mg mb-4" color="white">
                    {t("quiz.label.avgComplet")}
                  </Typography>
                </Box>
                <QuizStatCompleteUser />
              </Box>
              <Box classes="dashboard__box purple__box">
                <Box direction="column">
                  <Typography size={32} fontWeight={900} color="white">
                    {stat?.completedPer ? `${stat?.completedPer}%` : `N/A`}
                  </Typography>
                  <Typography size={12} classes="mg mb-4" color="white">
                    {t("quiz.label.avgRate")}
                  </Typography>
                </Box>
                <QuizStatPer />
              </Box>
            </Box>
            {/* card end */}

            {/* graph start */}
            <Box classes="mg mt-40" width={"100%"} direction="column">
              <Box classes="mg mb-28">
                <Typography fontWeight={700}>
                  {t("quiz.label.quizRates")}
                </Typography>
              </Box>
              <Box id={"quizGraph"}>
                {!stat?.completedCourseStat ? (
                  <Box
                    width={"100%"}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <NoQuizImage />
                    <Typography color="secondary">
                      {t("quiz.msg.noItems")}
                    </Typography>
                  </Box>
                ) : (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={lineOptions}
                  />
                )}
              </Box>
            </Box>
            {/* graph end  */}
          </Box>
          {/* left end  */}
          {/* right start  */}
          <Box classes="mg ml-32 mt-24" width={"33%"} direction="column">
            <Box classes="mg mb-8" justifyContent="space-between">
              <Typography fontWeight={700}>
                {t("quiz.label.lowCompletTop10")}
              </Typography>
              {courseList && (
                <Select
                  transparent
                  width={i18n.language === "ko" ? 70 : 100}
                  onClick={onSelectCourse}
                >
                  <SelectItem eventKey={t("quiz.label.all")}>
                    {t("quiz.label.all")}
                  </SelectItem>
                  {courseList.map((c: number) => (
                    <SelectItem eventKey={c.toString()}>
                      {/* {selectedCourseId === c.toString() ? (
                        <Icons
                          variant="mindsat"
                          label="icon_check"
                          stroke="#3182F6"
                          classes="mg mr-4"
                        />
                      ) : (
                        <Box width={20} classes="mg mr-4" />
                      )} */}
                      {t("report.reportConfigId", { round: c })}
                    </SelectItem>
                  ))}
                </Select>
              )}
            </Box>
            <Box direction="column">
              <Table classes="default-table mg">
                <colgroup>
                  {/*  */}
                  <col style={{ width: "30px" }}></col>
                  {/* 구분 */}
                  <col style={{ width: "200px" }}></col>
                  {/* 회차 */}
                  <col style={{ width: "80px" }}></col>
                </colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      {t("quiz.label.userName")} ({t("quiz.label.email")})
                    </th>
                    <th>{t("quiz.label.rate")}</th>
                  </tr>
                </thead>
              </Table>
              <Table>
                {!stat?.lowCompletedUserStat ||
                (stat?.lowCompletedUserStat &&
                  stat?.lowCompletedUserStat.length === 0) ? (
                  <Box
                    width={"100%"}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    classes="mg mt-32"
                  >
                    <NoQuizImage />
                    <Typography color="secondary">
                      {t("quiz.msg.noItems")}
                    </Typography>
                  </Box>
                ) : (
                  <>
                    <colgroup>
                      {/*  */}
                      <col style={{ width: "30px" }}></col>
                      {/* 구분 */}
                      <col style={{ width: "200px" }}></col>
                      {/* 회차 */}
                      <col style={{ width: "80px" }}></col>
                    </colgroup>
                    <tbody>
                      {stat?.lowCompletedUserStat.map((u, index) => (
                        <tr>
                          <td style={{ color: "#B5BBC2" }}>{index + 1}</td>
                          <td>{`${u.userName} (${u.userEmail})`}</td>
                          <td>{`${Math.floor(
                            (u.completeCount / u.sendCount) * 100
                          )}%`}</td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                )}
              </Table>
            </Box>
          </Box>
          {/* right end  */}
        </Box>
      </Box>
      {/* main end */}
    </ScrollBox>
  );
};

export default QuizStat;
