import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "summernote/dist/lang/summernote-ko-KR";
import "summernote/dist/summernote.css";
import "./index.scss";
// import $ from "jquery";

import "summernote/src/styles/summernote/common.scss";
import "summernote/src/styles/summernote/elements.scss";
import "summernote/src/styles/summernote/font.scss";
import "summernote/src/styles/bs5/summernote-bs5.json";

import useEditor from "../../hooks/useEditor";
interface EditorProps {
  initBody?: string;
  onChange: (text: string) => void;
  isShowButtons?: boolean;
  isSelectedImage?: boolean;
  isSelectedLink?: boolean;
}
const WSEdit = ({
  initBody,
  onChange,
  isShowButtons = true,
  isSelectedImage = false,
  isSelectedLink = false,
}: EditorProps) => {
  const { t, i18n } = useTranslation();
  let { linkBtn, imageBtn, nameBtn, emailBtn, dateBtn, yearBtn } = useEditor();

  useEffect(() => {
    let note: any = document.getElementById("summernote");

    if (note) {
      $(document).ready(function () {
        $("#summernote").summernote({
          callbacks: {
            onChange: function (contents, $editable) {
              onChange(contents);
            },
          },
          lang:
            i18n.language === "ko"
              ? "ko-KR"
              : i18n.language === "en"
              ? "en-US"
              : "ko-KR",
          height: 385,
          // disableResizeEditor: true,
          fontSizes: [
            "8",
            "10",
            "12",
            "13",
            "14",
            "15",
            "16",
            "18",
            "20",
            "24",
            "28",
            "32",
            "36",
            "40",
            "48",
            "60",
            "72",
          ],
          dialogsInBody: true,
          disableDragAndDrop: true,
          fontNames: [
            "맑은 고딕",
            "돋움체 ",
            "굴림체",
            "바탕체",
            "Arial",
            "Arial Black",
            "Comic Sans MS",
            "Courier New",
            "Helvetica",
            "Impact",
            "Tahoma",
            "Times New Roman",
            "Verdana",
          ],
          buttons: {
            Link: linkBtn,
            Image: imageBtn,
            Name: nameBtn,
            eMail: emailBtn,
            Date: dateBtn,
            Year: yearBtn,
          },
          fontNamesIgnoreCheck: ["맑은 고딕", "돋움체 ", "굴림체", "바탕체"],
          toolbar: [
            ["style", ["bold", "italic", "underline", "clear"]],
            ["fontname", ["fontname"]],
            ["fontsize", ["fontsize"]],
            ["para", ["ul", "ol", "paragraph"]],
            ["table", ["table"]],
            ["color", ["color"]],
            [
              "mybutton",
              isShowButtons
                ? ["Name", "eMail", "Link", "Image", "Date", "Year"]
                : [],
            ],
            ["view", ["fullscreen", "codeview"]],
          ],
        } as Summernote.Options);
        $("#summernote").summernote("code", initBody ?? "<p></p>");
        $(".note-statusbar").hide();
        if (!isSelectedImage) {
          $(".note-btn-phishing-image").attr("disabled", "disabled");
        } else {
          $(".note-btn-phishing-image").removeAttr("disabled");
        }
        if (!isSelectedLink) {
          $(".note-btn-phishing-link").attr("disabled", "disabled");
        } else {
          $(".note-btn-phishing-link").removeAttr("disabled");
        }
      });
    }
  }, [
    initBody,
    isShowButtons,
    isSelectedImage,
    isSelectedLink,
    isSelectedLink,
  ]);

  return <div id={"summernote"}></div>;
};

export default WSEdit;
